import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/stripeCoupon'

const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const StripeCoupon = {
    Create                    : Handlers.Services(Create),
}

export default StripeCoupon
