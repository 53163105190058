import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    borderRadius: '8px',
    height: props=>props.full ? '100%' : '',
  },
  paper: {
    height: props=>props.full ? '100%':'',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #FFF',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    padding: '24px',
    // width: '571px',
    width: props => props.width,
    // maxWidth: props => props.maxWidth || 'fit-content',
    minWidth: props => props.minWidth,
    maxWidth: props => props.maxWidth,
    position: props => props.position,
    top: props => props.top,
    left: props => props.left,
    maxHeight: props=>props.full ? '':'80%',
    overflowY: 'auto',
    borderRadius: props=>props.full ? '':'8px',
  },
}));

export default function CustomsModal({open, width, component, onClose, minWidth, maxWidth, full, position, top, left}) {
  const classes = useStyles({minWidth, maxWidth, position, top, left, width, full});
  return (
    <div id="modal">
      <Modal
        aria-labelledby   = "transition-modal-title"
        aria-describedby  = "transition-modal-description"
        className         = {classes.modal}
        open              = {open}
        onClose           = {onClose}
        BackdropComponent = {Backdrop}
        BackdropProps     = {{timeout: 500}}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>{component}</div>
        </Fade>
      </Modal>
    </div>
  );
}
