const Plans = {
    SUBSCRIPTION_FEE : 'subscription fee',
    PAY_AS_YOU_GO    : 'pay as you go',
    BRAND_CONNECTION : 'brand connection',
}

const Agency = {
    name        : 'Walletly',
    logo        : 'url',
    cover       : 'url',
    loader      : 'url',
    favicon     : 'url',
    Plans       : Plans
}





export default Agency