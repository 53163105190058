import React, { useContext, useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import CustomsModal from '../../../components/CustomModal'
import CustomSwitch from '../../../components/CustomSwitch'
import CustomTextField from '../../../components/CustomTextField'
import { AgencyContext } from '../../../context/Agency.context'
import { SvgIcons } from '../../../icons'
import { PlanService, StripeCouponService } from '../../../services'
import PlanModal from './PlanModal'
import CustomSelect from '../../../components/CustomSelect'
import { CircularProgress } from '@material-ui/core'
import CopyText from '../../../components/CopyText'

function Plans({setTitleFunc, setBtn}) {
    setTitleFunc("Custom Plan")

    const agency = useContext(AgencyContext)
    const [open, setOpen] = useState({open: false, plan: 'Basic', coupon: false})
    const [state, setstate] = useState({
        plans : '',
        loader: false,
        apiError : '',
        selectedPlan : '',

        duration        : '',
        amount          : '',
        redemption      : '',
        duration_months : '',

        response  : '',
        btnLoader : false
    })

    // const handleSearch = (e) => {
    //     const result = data.filter(item =>
    //         item.plan.toLowerCase().includes(e.target.value)
    //     );
    //     setResult(result)
    // }  

    const onLoad = async () => {
        setstate({...state, loader: true})
        let query = {planBy : agency._id}
        const {response, error} = await PlanService.Get({query})
        if(error) return setstate({...state, apiError: error.message, loader: false})

        console.log(response);
        setstate({...state, loader: false, plans: response.data})
    }
    useEffect(onLoad, [])


    // const buttonFunc = () => {
    //     return(
    //         <CustomButton hover="#F6F9FE !important" height="36px" id="PlansProps" className={'bg-color-whiteColor pt-6 pr-12 pb-6 pl-12 color-brandingPrimaryColor button'} btntext={"Change Payment Service"} />
    //     )
    // }

    // useEffect(() => {
    //     setBtn(buttonFunc)
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="Plans">
            <div className="heading3 color-blackColor ">Your Plans</div>
            <div className="paragraph mb-16 color-textFieldPlaceHolderColor mt-4">These plans will be available for your brands using walletly dashboard.</div>
            <div className="d-flex">
                <div className="mr-16 w-50">
                    <CustomTextField
                        className   = "w-100"
                        placeholder = "Search Plan name"
                        // label       = "Add Domain"
                        icon={<SvgIcons.Search/>}
                        position={'start'}
                        // onChange={(e)=>handleSearch(e)}
                    />
                </div>
                <div className='middle mt-5'>
                    <CustomButton btntext="Create Coupon" height="36px" onClick={()=>setOpen({...open, coupon: true})} />
                </div>
            </div>

            {
                state.plans.length > 0 && state.plans.map((plan,index)=>(
                    <>
                        {index !== 0 && <div className="bar bar-full"/>}
                        <div className="cp pl-30 bar-full d-flex space-between pr-40 align-items-center pt-24 pb-24 hover-div" onClick={()=>{setOpen({open: true, selectedPlan: plan})}}>
                            <div>
                                <div className="buttonText color-blackColor text-capitalize">{plan.planName}</div>
                                <div className="paragraph color-textFieldPlaceHolderColor">Plan Price: ${plan.amount}</div>
                            </div>
                            <div>
                                {/* <CustomSwitch onClick={(e)=>e.stopPropagation()}/> */}
                            </div>
                        </div>
                    </>
                ))
            }

            
           
            <CustomsModal
                open={open.open}
                component={<PlanModal plan={open.selectedPlan} onClose={()=>setOpen({...open, open: false})}/>}
                onClose={()=>setOpen({...open, open: false})}
                width={'571px'}
                // minWidth={'571px'}
                // maxWidth={'571px'}
            />
            
            <CustomsModal
                open={open.coupon}
                component={<div>
                    <div className="mb-32 heading3 color-blackColor">Create Coupon</div>
                    <div>
                        <CustomSelect 
                            label     = "Duration"
                            value     = {state.duration}
                            onChange  = {(e)=>setstate({...state, duration: e.target.value})}
                            className = "w-70 mb-24"
                            options   = {<>
                                <option value="">None</option>
                                <option value="forever">Forever</option>
                                <option value="repeating">Repeating</option>
                                <option value="once">Once</option>
                            </>}
                        />
                    </div>
                    {state.duration==="repeating"&&<div>
                        <CustomTextField type="number" className="w-70 mb-24" label="Duration In Month" value={state.duration_months} onChange={(e)=>setstate({...state, duration_months: e.target.value})}/>
                    </div>}
                    <div>
                        <CustomTextField type="number" icon={"USD"} position="end" className="w-70 mb-24" label="Amount" value={state.amount} onChange={(e)=>setstate({...state, amount: e.target.value})}/>
                    </div>
                    <div>
                        <CustomTextField type="number" className="w-70 mb-24" label="Redemtion Limit" value={state.redemption} onChange={(e)=>setstate({...state, redemption: e.target.value})}/>
                    </div>

                    {state.response && <div className='customTextField mb-8'>
                        <div className = "border subtitle2 w-70 borderRadius-4 pl-8 pb-8 pt-8 pl-8 fw-5 opacity-10 color-textfieldColor d-flex">
                            {/* {state.response}  */}
                            <span className = "subtitle2 ml-5 color-c828282 opacity-10"><CopyText content={state.response} /></span> 
                        </div>
                    </div>}

                    <div className="text-align-right">
                        {/* <div>{state.response&&state.response}</div> */}
                        <CustomButton 
                            height   = "36px"
                            btntext  = "Create Coupon"
                            disabled = {state.btnLoader}
                            icon     = {state.btnLoader&&<CircularProgress size={20} color={'inherit'}/>}
                            onClick  = {async(e)=>{
                                setstate({...state, btnLoader: true})
                                e.preventDefault()
                                const payload = {
                                    duration        : state.duration.toString(),
                                    amount_off      : state.amount+"00",
                                    currency        : 'usd',
                                    email           : agency.email,
                                    agency          : agency,
                                    max_redemptions : state.redemption.toString(),
                                    couponType      : 'agencyCoupon',
                                }
                                if(state.duration === "repeating") payload.duration_in_months = state.duration_months.toString()
                                const {response, error} = await StripeCouponService.Create({payload, toaster: true})
                                console.log(response, error);
                                if(response) setstate({...state, response: response.data.couponCode,btnLoader: false})
                                else setstate({...state, btnLoader: false})
                        }} />
                    </div>

                </div>}
                onClose={()=>setOpen({...open, coupon: false})}
                width={'571px'}
                // minWidth={'571px'}
                // maxWidth={'571px'}
            />
        </div>
    )
}

export default Plans
