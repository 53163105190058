import React, { useContext, useEffect } from 'react';

import {Link, useHistory} from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import * as localForage from 'localforage'

import CustomButton from '../../components/CustomButton';
import VerificationCode from '../../components/VerificationCode';

import PngIcons from '../../icons/png.icon';
import { AuthService, UserService } from '../../services';
import { utils } from '../../utils';
import { UserContext } from '../../context/User.context';

export default function EmailVerify({updateUser}){

    const userContext = useContext(UserContext)

    const history = useHistory()
    const [state, setState] = React.useState({
        loader      : false,
        apiError    : '',
        apiResponse : '',
        code        : '',
        valid       : false
    })

    const [defaultCode, setDefaultCode] = React.useState();

    useEffect(async () => {
        let user = await localForage.getItem('user');
        setDefaultCode(user.verificationCode);
    }, [])

    const handleCode = (code) => setState({...state, code, valid: true})
    const Validate = (valid) => setState({...state, valid})

    const verifyAccount = async (e) => {
        e.preventDefault()
        setState({...state, apiError: '', apiResponse: '', loader: true})

        const payload = {verificationCode: state.code}
        const { error, response } = await AuthService.VerifyAccount({payload}) 
        console.log(error);
        if(error) return setState({...state, apiError: error.message})

        const user = await UserService.Get()
        user.response && localForage.setItem('user', user.response.data)
        setState({...state, loader: false})
        userContext.updateUser(user.response.data)
        history.push('/'+user.response.data.activeAgency)
    }

    const resendCode = async () => {
        setState({...state, apiError: '', apiResponse: ''})

        const user = await localForage.getItem('user')
        const payload = {email: user.email}
        const { error, response } = await AuthService.Resend({payload})
        if(error) return setState({...state, apiError: error.error})
        if(response) return setState({...state, apiResponse: response.message})
    }

    return(
        <div className="container" id="Email">
            <div className="card">

                <div className="row">
                
                <div className="headingH2 col-12 text-center">Let's Go!            
                </div>

                <div className="col-12 text-center mt-16 mb-35">
                    <img src={PngIcons.EmailVerify} alt="" />
                </div>

                <div className="headingH2 col-12">
                    Email Verification
                </div>
                <form onSubmit={verifyAccount}>

                    <div className="subtitle1 col-12 mt-32 mb-32">Please enter the code we emailed you.</div>
                    <div className="col-12">
                        <div className="inputFields">
                            <VerificationCode 
                                length     = {4}
                                onComplete = {handleCode}
                                Validate   = {Validate}
                                loader     = {state.loader}
                            />
                        </div>
                    </div>
                    
                    <CustomButton 
                        className = "fs-14 col-12 fw-5 mt-32"
                        textColor = "white"
                        type      = "submit"
                        btntext   = "Verify"
                        disabled  = {state.loader || !state.valid}
                        icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                    />
                    {/* <a className="middle fs-14 color-c000000 cp mt-4" onClick={()=>history.push('/onboard')}>Skip</a> */}
                    <a className="middle fs-14 color-c000000 cp mt-4" onClick={()=>history.push('/agency')}>Skip</a>
                    <span className='error'>{state.apiError}</span>
                    <span>{state.apiResponse}</span>

                </form>

                <div className="col-12 text-center mt-48">
                    <div className="resendLogout cp" onClick={resendCode}>Resend Code</div> or 
                    <div className="resendLogout cp" onClick={utils.Logout}> Logout</div>
                </div>
             

              </div>
            </div>
        </div>
        )
    }