import * as yup from 'yup';
import { enums } from '../enums';

const Update = yup.object({
    fullName         : yup.string().required('This field is required'),
    email            : yup.string().email().required(),
    password         : yup.string().min(8, 'Password must be at least 8 characters').required('This field is required'),
    newPassword      : yup.string().min(8, 'Password must be at least 8 characters').required('This field is required'),
    confirmPassword  : yup.string().min(8, 'Password must be at least 8 characters').required('This field is required').test('match', 'Password mismatch', function (value) { return value === this.parent.newPassword }),
    avatar           : yup.string(),
    profilePhotoFile : yup.mixed()
                        .test('imageSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                        .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('avatar',{
                            is   : value => !value,
                            then : yup.mixed().required('This field is required')
                        }),
})

const ProfileUpdate = yup.object({
    firstName     : yup.string().required('This field is required'),
    lastName      : yup.string().required('This field is required'),
    birthday      : yup.string().required('This field is required'),
    gender        : yup.string().required('This field is required'),
    logo          : yup.string(),                                      //avatarFile //avatar
    agencyLogoFile: yup.mixed()
                    .test('imageSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('avatar',{
                        is   : value => !value,
                        then : yup.mixed()
                    }),
})

export {
    Update,
    ProfileUpdate
}