import * as localForage from 'localforage'
import axios from 'axios';

import {CountryCode, Currencies} from '../enums';
import ct from 'countries-and-timezones'
import env from '../config'
import { UtilsService } from '../services';

import ReactDOM from 'react-dom';

import CustomToasters from '../components/CustomToasters';

const Logout = () => localForage.clear().then(res => window.location.assign('/'))

const getLocationUTC = (location) => {
    if(!location.countryCode) return
    const countryTimezone = ct.getCountry(location.countryCode)

    console.log('Country Timzones',countryTimezone);

    if(!location.country || !location.state) return
    const filteredTimezone = countryTimezone.timezones.filter(timezone => timezone === location.country+'/'+location.state) || []

    console.log('Filtered Timzones',filteredTimezone);

    if(countryTimezone && !filteredTimezone.length && countryTimezone.timezones.length)
    return ct.getTimezone(ct.getCountry(location.countryCode).timezones[0]).offset

    return ct.getTimezone(filteredTimezone[0]).offset
}

const getGoogleLocationTimezone = async (location) => {
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat},${location.lng}&timestamp=${Date.now()}&key=${env.GOOGLE_MAP_API}`
    const result = await axios.get(url)
    console.log(result.data);
}

const summarisedLocation = (place) => {
    let location = {
        lat         : place.geometry.location.lat(),
        lng         : place.geometry.location.lng(),
        address     : place.formatted_address,
        country     : '',
        city        : '',
        countryCode : '',
        state       : '',
        offset   : place.utc_offset_minutes || ''
    }

    const addressTypes = {
        'administrative_area_level_1' : (address) => location.state = address.short_name,
        'locality'                    : (address) => location.city = address.long_name,
        'country'                     : (address) => {
                                            location.country     = address.long_name
                                            location.countryCode = address.short_name
                                        },
    }

    place.address_components.map(address => addressTypes[address.types[0]] && addressTypes[address.types[0]](address))
    
    if(!location.offset)
    location.offset = getLocationUTC(location) || ''

    console.log(location);
    return location
}

const getCurrency = ({location, currencyCode}) => {
    if(currencyCode) return Currencies.filter(currency => currency.code === currencyCode)[0]

    if(location){
        const currency = CountryCode.filter(country => country.code === location.countryCode)[0]
        console.log('aaaaaa',currency);
        if(currency)
        return { 
            code   : currency.currencyCode,
            symbol : currency.currencySymbol
        }
    }
}

const getTimezone = ({location, timezoneName}) => {
    const timezones = Object.values(ct.getAllTimezones())

    if(timezoneName) return timezones.filter(timezone => timezone.name === timezoneName)[0]

    if(location){
        const selectedTimezone = timezones.filter(timezone => {
            if(timezone.country === location.countryCode && timezone.utcOffset === location.offset) return true
            else if(timezone.country === location.countryCode) return true
            else return false
        })
        if(selectedTimezone && selectedTimezone.length) return selectedTimezone[0].name
    }
  
}

const uploadImage = async ({file, desiredPath}) => {
    const form = new FormData()
    form.append('image', file, file.name)
    console.log('form', form)
    return await UtilsService.UploadImage({payload: form, desiredPath})
}

const getRandomNumber = (length = 8) => {
    return Math.floor(Math.pow(10, length-1) + Math.random()*Math.pow(10, length-1))
}

const invertColor = (hex, bw) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);

    function padZero(str, len) {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

const randomNum  = getRandomNumber(6) // used for avatar background color

const getAvatar = ({firstName, lastName, className, width, heigth, bgColor}) => {
    const colorCode  = `#${randomNum}`
    const invertCode = invertColor(bgColor || colorCode)

    return (
        <div 
            className={className + ' borderRadius-40'} 
            style={{
                width           : width ||'40px',
                height          : heigth ||'40px',
                backgroundColor : '#2f80ed',
                display         : 'flex',
                flexDirection   : 'column',
                justifyContent  : 'center',
                alignItems      : 'center',
                color           : 'white'
            }}
        >
            {   
                firstName ?
                lastName ? firstName[0]+lastName[0] : firstName[0]+firstName[1] :
                'AV'
            }
        </div>
    )
}

const getQueryString = (query) => {
    return Object.entries(query).map(entry => entry[0]+'='+entry[1]).join('&')
}

const compareJSON = (objA, objB) => {
    return JSON.stringify(objA) === JSON.stringify(objB)
}

const emptyJSON = (json) => {
    return JSON.stringify(json) === '{}'
}

const showToaster = ({position, title, message, severity}) => {
    const notifications = [{title, message, severity, progress: 0}]
    ReactDOM.render(<CustomToasters position={position} notifications={notifications} />, document.getElementById('Toaster'))
}

const getDate = (dateInMS, withTime=false) => {
    const timeOptions = {hour: '2-digit', minute: '2-digit'}
    return new Date(dateInMS).toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year:'numeric', ...(withTime && timeOptions)}).split('/').join('-')
}

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const isUpperCase = (string) => {
      const strings = /^[A-Z]*$/.test(string)
        var character=''
        var i = 0
        while (i <= string.length){
            character = string.charAt(i);
            if (!isNaN(character * 1)){

            }else{
                if (character === character.toUpperCase()) {
                    return true
                }
                if (character === character.toLowerCase()){
                    
                }
            }
            i++;
        }
        return false

  }
  
  const isLowerCase = (string) => {
      const strings = /^[A-Z]*$/.test(string)
        var character=''
        var i = 0
        while (i <= string.length){
            character = string.charAt(i);
            if (!isNaN(character * 1)){

            }else{
                if (character === character.toUpperCase()) {
                    
                }
                if (character === character.toLowerCase()){
                    return true
                    
                }
            }
            i++;
        }
        return false

  }
  
  const isNumeric = (string) => {
      const strings = /^[A-Z]*$/.test(string)
        var character=''
        var i = 0
        while (i <= string.length){
            character = string.charAt(i);
            if(/^-?\d+$/.test(character)) return true
            i++;
        }
        return false

  }

  const sort = ({array, key}) => {
    array.sort(function(a, b) {
        var keyA = a.key,
            keyB = b.key;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
    });

} 

export {
    Logout,
    isUpperCase,
    isNumeric,
    sort,
    isLowerCase,
    capitalize,
    summarisedLocation,
    getCurrency,
    getTimezone,
    uploadImage,
    getRandomNumber,
    invertColor,
    getAvatar,
    getQueryString,
    compareJSON,
    emptyJSON,
    showToaster,
    getDate,
}