import React from 'react';

import {Line} from 'react-chartjs-2';
import {Bar} from 'react-chartjs-2';

export default function Graph(props){
    var lineChartOptions = {
        scales: {
            xAxes: [{
                gridLines: {
                    display:false,
                },
            }],
            yAxes: [{
                gridLines: {
                    display:false,
                },
                ticks: {
                    beginAtZero: true
                }   
            }]
        },
        legend: {
            display: false
        }
      };

      var barChartOptions = {
        scales: {
            xAxes: [{
                gridLines: {
                    display: false,
                    zeroLineColor: 'transparent',
                },
            }],
            yAxes: [{
                gridLines: {
                    display:true,
                },
                ticks: {
                    beginAtZero: true
                },  
            }],

        },
      };
      
    return(
        <div id="graph">
            {
                props.type=="line" ? 
                    <Line data={props.data} options={lineChartOptions}/> :
                    <Bar data={props.data} options={barChartOptions}/>
            }
        </div>

    )
}