import React, {useState, useEffect} from 'react';
import Fade from 'react-reveal/Fade';

import {SvgIcons} from '../../icons';

import CustomTextField from '../CustomTextField';
import CustomButton from '../CustomButton';
import ClickOutside from '../../utils/ClickOutside'

import {ChromePicker} from 'react-color';
import { Brand, ColorScheme } from '../../enums';
import CustomsModal from '../CustomModal';
import ImageModal from '../ImageModal';
import { ColorSchemeCode } from '../../enums/ColorScheme';

export default function AgencyLogoScreen({formik, setActivePage, index}){

    const [pageStatus, setPageStatus]     = useState('');
    const [colorPallete, setColorPallete] = useState(false);
    const [show, setShow]                 = useState(false);
    const [imageModal, setImageModal] = useState({
        logo  : {open: false, imageEvent: '', return: ''},
        cover : {open: false, imageEvent: '', return: ''}
    })

    useEffect (()=> setShow(true),[])


    const colors = [
        {color: '#F2994A'}, 
        {color: '#EB5757'},
        {color: '#219653'},
        {color: '#F2C94C'},
        {color: '#56CCF2'},
        {color: '#2F80ED'},
        {color: '#9B51E0'},
        {color: '#FF6CD6'},
        {color: '#333333'},
        {color: '#828282'}
    ]

    const dragOver  = (e) => e.preventDefault();
    const dragEnter = (e) => e.preventDefault();
    const dragLeave = (e) => e.preventDefault();
    
    const fileChange = (e) => {
        e.preventDefault();
        const file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        formik.setValues({...formik.values, agencyLogoFile: file[0]})
    }

    const nextScreen = () =>{
        setShow(false);
        setPageStatus('next');
        setTimeout(()=>{setActivePage(index,'next')},500)
    }

    const prevScreen = () =>{
        setShow(false);
        setPageStatus('prev');
        setTimeout(()=>{setActivePage(index,'prev')},500)
    }

    

    return(
        <div id="brandLogoScreen" className='mobileCol'>

            <Fade  bottom={ (pageStatus=== 'next') ? true : false} top={(pageStatus=== 'prev') ? true : false} duration={800} opposite when={show}>
                <div className='row'>
                    <div className='col-md-5 col-12 offset-md-1'>
                        <div className="question mt-108">
                            {/* <span onClick={prevScreen} style={{cursor: 'pointer'}}><SvgIcons.BackIcon/> </span>  */}
                            What’s your agency logo & Color?
                        </div>

                        <div className='row text-center'>
                            <label  htmlFor="imageUpload" className="cp contents col-12 mt-32 text-center" >
                                <div className={(formik.values.agencyLogo || formik.values.agencyLogoFile) && !formik.errors.agencyLogoFile ? "subheading dropImageCircle image text-center" : "subheading dropImageCircle text-center"}>
                                    {(formik.values.agencyLogo || formik.values.agencyLogoFile) && !formik.errors.agencyLogoFile && 
                                    <img 
                                        src          = {formik.values.agencyLogoFile ? URL.createObjectURL(formik.values.agencyLogoFile) : formik.values.agencyLogo}
                                        className    = "insideImage"
                                        alt          = "new"
                                    />}
                                    <span 
                                        className    = {(formik.values.agencyLogo || formik.values.agencyLogoFile) && !formik.errors.agencyLogoFile ?
                                                            "imageUpload fs-14 color-Secondary remove" : "fs-14 color-Secondary imageUpload"}
                                        onDragOver   = {dragOver}
                                        onDragEnter  = {dragEnter}
                                        onDragLeave  = {dragLeave}
                                        // onDrop       = {fileChange}
                                        onDrop      = {(e)=> {setImageModal({...imageModal, logo: {...imageModal.logo, open: true, imageEvent: e}}) }}
                                    > Drop an Image or
                                        <div className="mt-2">
                                            <div className="mb-0">
                                                <span className="color-Secondary fs-14">browse</span>
                                            </div>
                                            <input
                                                id       = "imageUpload"
                                                style    = {{display:'none'}}
                                                type     = "file"
                                                // onChange = {fileChange}
                                                onChange = {(e)=> {setImageModal({...imageModal, logo: {...imageModal.logo, open: true, imageEvent: e}}) }}
                                            />
                                        </div>
                                    </span>
                                </div>

                                <CustomsModal
                                    open      = { imageModal.logo.open }
                                    // onClose   = { ()=> setImageModal({...imageModal, logo: {...imageModal.logo, open: false}}) }
                                    maxWidth  = {400}
                                    component = { 
                                        <ImageModal 
                                            imageModal    = { imageModal }
                                            setImageModal = { setImageModal }
                                            image         = { 'logo' }
                                            formik        = { formik }
                                        />
                                    }
                                />

                            </label>
                            {formik.values.agencyLogoFile && formik.errors.agencyLogoFile && <div className="mx-auto error">{formik.errors.agencyLogoFile}</div>}
                           
                            <div className='col-12 mt-40'>
                                <div className="row">
                                    {colors.map((color,index)=>(
                                        <div className='circleRow col-1 offset-1' key={index}>
                                            <div 
                                                className={formik.values.agencyColor === color.color ? "colorCircle selectedColor" : "colorCircle"} 
                                                onClick={()=> formik.setValues({...formik.values, agencyColor: color.color})} 
                                                style={{backgroundColor: color.color}}
                                            >
                                                {color.color === formik.values.agencyColor ? <SvgIcons.ColorselectIcon/> : <></>}
                                            </div> 
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    <div className="mx-auto">
                                        <ClickOutside onClick={() => setColorPallete(false)}>
                                            <CustomTextField 
                                                className  = "colorInput"
                                                onClick    = {()=> setColorPallete(!colorPallete)}
                                                name       = "agencyColor"
                                                onChange   = {formik.handleChange}
                                                value      = {formik.values.agencyColor}
                                                inputProps = {{ onFocus: formik.handleBlur }}
                                                error      = {formik.touched.agencyColor && formik.errors.agencyColor}
                                                helperText = {formik.touched.agencyColor && formik.errors.agencyColor ? formik.errors.agencyColor : ''}
                                            /> 
                                            {colorPallete &&
                                                <ChromePicker 
                                                    className = "pallette position-absolute"
                                                    color     = {formik.values.agencyColor}
                                                    onChange  = {changedColor => formik.setValues({...formik.values, agencyColor: changedColor.hex})}
                                                />
                                            }                 
                                        </ClickOutside>
                                    </div> 
                                </div>

                                {/* <CustomButton 
                                    borderRadius    = {12}
                                    height          = {45}
                                    onClick         = {nextScreen}
                                    disabled        = {
                                                        (!formik.values.agencyLogoFile && !formik.values.agencyLogo) || 
                                                        !formik.values.agencyColor ||
                                                        (formik.errors.agencyLogoFile && !formik.values.agencyLogo) ||
                                                        formik.errors.agencyColor
                                                      }
                                    className       = 'headingH4 mt-24 btn-next'
                                    color           = {ColorScheme.ColorSchemeCode.cffffff}
                                    backgroundColor = {ColorScheme.ColorSchemeCode.themeColor}
                                    btntext         = "Next"
                                /> */}



                                <div className="d-flex space-between mt-24">
                                    <span className="buttonBox mr-16">
                                        <CustomButton 
                                            className       = "fs-14 fw-5"
                                            height          = '36px'
                                            btntext         = "Back"
                                            onClick         = {prevScreen}
                                            backgroundColor = {ColorSchemeCode.cE0E0E0}
                                            hover           = {ColorSchemeCode.cE0E0E0}
                                            color           = {ColorSchemeCode.c000000}
                                        />
                                    </span>
                                    <CustomButton 
                                        borderRadius    = {4}
                                        height          = {36}
                                        className       = 'headingH4'
                                        btntext         = "Next"
                                        color           = {ColorScheme.ColorSchemeCode.cffffff}
                                        backgroundColor = {ColorScheme.ColorSchemeCode.themeColor}
                                        onClick         = {nextScreen}
                                        disabled        = {
                                                            (!formik.values.agencyLogoFile && !formik.values.agencyLogo) || 
                                                            !formik.values.agencyColor ||
                                                            (formik.errors.agencyLogoFile && !formik.values.agencyLogo) ||
                                                            formik.errors.agencyColor
                                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </Fade>
                                                                  
        </div>

    )


}