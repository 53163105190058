import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/agency'



const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const Get = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const CheckDomain = async ({query}) => await axios.get(env.API_URL+service+'/checkdomain?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GenerateSSL = async ({query}) => await axios.get(env.API_URL+service+'/generateSSL?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const ConnectBrand = async ({payload}) => await axios.post(env.API_URL+service+'/connect', payload, {headers: await MainService.getTokenHeader()})

const CreateSubscription = async ({payload}) => await axios.post(env.API_URL+service+'/subscription', payload, {headers: await MainService.getTokenHeader()})

const WhiteLabelBrandConnection = async ({payload}) => await axios.post(env.API_URL+service+'/connectSubscription', payload, {headers: await MainService.getTokenHeader()})

const UpdateSubscription = async ({payload}) => await axios.put(env.API_URL+service+'/subscription', payload, {headers: await MainService.getTokenHeader()})

const RenewSubscription = async ({payload}) => await axios.post(env.API_URL+service+'/subscription/renew', payload, {headers: await MainService.getTokenHeader()})

const IntegrateStripeAccount = async ({payload}) => await axios.put(env.API_URL+service+'/integrate/stripe', payload, {headers: await MainService.getTokenHeader()})

const GetAgencyPublic = async ({query}) => await axios.get(env.API_URL+service+'/public?'+utils.getQueryString(query))

const GetInvoice = async ({query}) => await axios.get(env.API_URL+service+'/getinvoice?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const StripeCoupon = async ({query}) => await axios.get(env.API_URL+service+'/stripeCoupon?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const StripeCouponCreate = async ({payload}) => await axios.post(env.API_URL+'/v4/stripeCoupon?', payload, {headers: await MainService.getTokenHeader()})

const CancelSubscription = async ({payload}) => await axios.put(env.API_URL+service+'/subscription/cancel', payload, {headers: await MainService.getTokenHeader()})

const VerifySES = async ({payload}) => await axios.post(env.API_URL+'/v4/services/emailService'+'/verifySES',payload, {headers: await MainService.getTokenHeader()})


const AgencyService = {
    Create                   : Handlers.Services(Create),
    Get                      : Handlers.Services(Get),
    GetInvoice               : Handlers.Services(GetInvoice),
    CancelSubscription       : Handlers.Services(CancelSubscription),
    Update                   : Handlers.Services(Update),
    StripeCoupon             : Handlers.Services(StripeCoupon),
    CreateSubscription       : Handlers.Services(CreateSubscription),
    WhiteLabelBrandConnection: Handlers.Services(WhiteLabelBrandConnection),
    UpdateSubscription       : Handlers.Services(UpdateSubscription),
    RenewSubscription        : Handlers.Services(RenewSubscription),
    IntegrateStripeAccount   : Handlers.Services(IntegrateStripeAccount),
    CheckDomain              : Handlers.Services(CheckDomain),
    GenerateSSL              : Handlers.Services(GenerateSSL),
    ConnectBrand             : Handlers.Services(ConnectBrand),
    VerifySES                : Handlers.Services(VerifySES),
    GetAgencyPublic          : Handlers.Services(GetAgencyPublic),
    StripeCouponCreate       : Handlers.Services(StripeCouponCreate)
}

export default AgencyService
