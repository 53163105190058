import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { InputAdornment } from '@material-ui/core';
import { NativeSelect } from '@material-ui/core';

import { ColorSchemeCode } from '../enums/ColorScheme';
import SvgIcons from '../icons/svg.icon';

const NewBootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: '4px',
    backgroundColor: props => props.backgroundColor ||  ColorSchemeCode.cffffff,
    fontSize: 14,
    padding: props => props.padding || '10px 26px 10px 12px',
    height: props => props.height || '18px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'Poppins',
    '&:hover': {
      backgroundColor: ColorSchemeCode.cffffff,
      borderRadius: '4px',
    },
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: ColorSchemeCode.cffffff,
    },
  },
}))(InputBase);

const useStyleInputLabelElement = makeStyles(() => ({
  root: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    marginBottom: '4px !important',
    marginTop: '5px',
    fontSize: 12,
    color : ColorSchemeCode.textFieldLabelColor,
    whiteSpace: 'nowrap'
  },
  icon:{
    display: 'none',
  }
}))

const useStyles = makeStyles((theme) => ({
  select:{
    border: '1px solid #DFE1E6',
    borderRadius: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    height: '40px',
  }
}));

export default function CustomSelect({options, helperText, error, label, value, onChange, padding, ...props}) {
    const classes           = useStyles();
    const inputLabel        = useStyleInputLabelElement()

  return (
    <div id="CustomSelect">
        <InputLabel  classes={inputLabel} shrink htmlFor="bootstrap-input" >{label}</InputLabel>
        <NativeSelect
          {...props}
          value          = {value}
          onChange       = {onChange}
          inputProps     = {props.inputProps}
          input          = {<NewBootstrapInput padding={padding} props={props}/>}
          className      = {props.className ? props.className+" "+classes.select : 'w-20 '+classes.select}
          IconComponent  = {()=><SvgIcons.Dropdown className="MuiNativeSelect-icon"/>}
          startAdornment = {
            <InputAdornment position="start" style={{marginRight: '0px'}}>
              {props.icon}
            </InputAdornment>
          }
        >
          {options}
        </NativeSelect>
    </div>
  );
}
