import * as yup from 'yup';
import { Campaign, enums } from '../enums'

const REQ_ERROR = 'This field is required'

const communicationChild = yup.object({
    active      : yup.boolean(),
    referenceId : yup.string().when('active', {is: value => value === true, then: yup.string().required(REQ_ERROR)})
})

const communication = yup.object({
    notification : communicationChild,
    sms          : communicationChild,
    email        : communicationChild,
    chatFlow     : communicationChild
})

const Create = yup.object({
    redeemingName      : yup.string().required(REQ_ERROR),
    activity           : yup.string().required().oneOf(Object.values(Campaign.Activities.REDEEMING)),
    delete             : yup.boolean().default(false),
    active             : yup.boolean(),
    redeemingType      : yup.string().required(REQ_ERROR).oneOf(Object.values(Campaign.RedeemingTypes)),
    cost               : yup.number().required(REQ_ERROR),
    redeemingValue     : yup.number().required(REQ_ERROR),
    applyTo            : yup.string().oneOf(['entire', 'specific']).required(REQ_ERROR),
    specificOrder      : yup.string().when('applyTo', {
                            is   : value => value === Campaign.RedeemActivityApplies.SPECIFIC,
                            then : yup.string().required(REQ_ERROR)
                        }),
    minRequirement     : yup.boolean(),
    minPurchaseAmount  : yup.number().when('minRequirement', {
                            is   : value => value === true,
                            then : yup.number().required(REQ_ERROR)
                        }),
    addDiscountCode    : yup.boolean(),
    discountCodePrefix : yup.string().when('addDiscountCode', {
                            is   : value => value === true,
                            then : yup.string().required(REQ_ERROR)
                        }),
    communication      : communication,
    icon               : yup.string(),
    iconFile           : yup.mixed()
                        .test('imageSize', 'File too large, it can be maximum of 2mb each.', value => value ? value.size <= enums.Images.ICON_FILE_SIZE : true)
                        .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('icon',{
                            is   : value => !value,
                            then : yup.mixed().required(REQ_ERROR)
                        })
})

export {
    Create
}