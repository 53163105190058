import React,{useEffect, useState} from 'react';
import {Route, Switch, Redirect} from "react-router-dom";
import SettingHeader from '../../components/setting/SettingHeader';
import AudiencMain from './subpages/AudiencMain';
import localforage, * as localForage from 'localforage'

export default function Audience(props) {

    const [state, setstate] = useState('')
    const [btn, setBtn] = useState()

    const setTitleFunc = (title) => {
        setstate(title)
    }

    const [user, setuser] = useState()
    
    useEffect(async() => {
        const data = await localforage.getItem('user')
        setuser(data)
    }, [])

    props.setShow(false);
    return (
        <div id="audience" >
           <div className="row ml-0">
                <div className="col-12 pl-0">
                    <SettingHeader show={props.show} setShow={props.setShow} heading={state} btn={btn} className="w-100" />
                </div>
                <div className="component col-12 pb-0 pl-0" style={{marginTop: user && (!user.verified) && '47px'}}>
                    <Switch>    
                        <Route exact path={props.match.url + '/'} render={()=><AudiencMain setBtn={setBtn} setTitleFunc={setTitleFunc} />}/>

                        <Redirect path={props.match.url+ '/*'} to={props.match.url+ '/general'} />
                        <Redirect path={props.match.url} to={props.match.url+ '/general'} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}


