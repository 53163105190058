import React, { useContext, useEffect, useState } from 'react'

import * as localForage from 'localforage';

import CustomButton from '../../../components/CustomButton';
import CustomTextField from '../../../components/CustomTextField';
import CustomModal from '../../../components/CustomModal';
import ImageModal from '../../../components/ImageModal';
import ClickOutside from '../../../utils/ClickOutside';

import { PngIcons, SvgIcons } from '../../../icons';
import { utils } from '../../../utils';

import { AgencyService } from '../../../services';
import { AgencyContext } from '../../../context/Agency.context';

import { CircularProgress } from '@material-ui/core';
import {ChromePicker} from 'react-color'


function LookAndFeel({setTitleFunc, setBtn, btn}) {
    const logoRef = React.createRef();
    const loaderRef = React.createRef();
    const faviconRef = React.createRef();

    const [agencyData, setAgencyData] = useState({
        color: '#C4C4C4',
        logo: '',
        loader: '',
        favicon: '',
        state: {
            loader   : false,
            apiError : ''
        }
    })

    const [imageModal, setImageModal]    = useState(
        {
            logo              : {open: false, imageEvent: '', return: ''},
            cover             : {open: false, imageEvent: '', return: ''},
            dealOfferImageFile: {open: false, imageEvent: '', return: ''}
        })

    const [picker, setPicker] = useState(false)
    
    setTitleFunc("Look And Feel")

    const agency = useContext(AgencyContext)

    useEffect(() => {
        setAgencyData({...agencyData, 
            favicon : agency.favicon ? agency.favicon : agency.logo,
            logo    : agency.logo,
            loader  : agency.loader !== true && agency.loader !== false ? agency.loader: '',
            color   : agency.agencyColor
        })
    }, [agency])

    useEffect(() => {
        setBtn(buttonFunc)
    }, [agencyData]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async() => {
        setAgencyData({...agencyData, state:{loader: true, apiError: ''}})

        const user = await localForage.getItem('user')
        const desiredPath = `${user._id}/${user.activeAgency}`
        
        let logo,loader,favicon

        if(typeof agencyData.logo === 'object')
        {
            logo = await utils.uploadImage({file: agencyData.logo, desiredPath})
            if(logo.error) return setAgencyData({...agencyData, state:{loader: false, apiError: logo.error.message}})
        }
        
        if(typeof agencyData.loader === 'object')
        {
            loader = await utils.uploadImage({file: agencyData.loader, desiredPath})
            if(loader.error) return setAgencyData({...agencyData, state:{loader: false, apiError: loader.error.message}})
        }
        
        if(typeof agencyData.favicon === 'object')
        {
            favicon = await utils.uploadImage({file: agencyData.favicon, desiredPath})
            if(favicon.error) return setAgencyData({...agencyData, state:{loader: false, apiError: favicon.error.message}})
        }

        const payload = {
            _id        : user.activeAgency,
            logo       : logo && logo.response.data,
            favicon    : favicon && favicon.response.data,
            loader     : loader && loader.response.data,
            agencyColor: agencyData.color,
        
        }

        const { error, response } = await AgencyService.Update({toaster: true, payload})
        if(error){
            setAgencyData({...agencyData, state:{loader: false, apiError: error.error}})
        }
        if (response){
            setAgencyData({...agencyData, state:{loader: false, apiError: ''}})
        }

        agency.updateAgency(response.data)
        return setAgencyData({...agencyData, state:{loader: false, apiError: ''}})
    }
    
    const buttonFunc = () => {
        return(
            <CustomButton
                btntext  = {agencyData.state.loader === false && "Update Changes"}
                // btntext  = {"Update Changes"}
                height    = "36px"
                width     = 'fit-content'
                onClick   = {handleSubmit}
                disabled  = {agencyData.state.loader}
                iconClass = {'d-flex justify-content-center'}
                icon      = {agencyData.state.loader && <CircularProgress size={20} color={'inherit'}/>}
            />
        )
    }

    const handleColor = (e) => setAgencyData({...agencyData, color: e.hex})

    const handleImage = (event, key) => setAgencyData({...agencyData, [key]: event.target.files[0]})

    return (
        <div id="LookAndFeel" className="mb-50">
            <div className="fw-6 fs-18 color-blackColor">Colors</div>
            <div className="color-textFieldPlaceHolderColor fw-4 fs-14 mt-4 mb-16">To enable custom plans, you must first connect to any payment service to accept payments.</div>
            <div className="fw-4 fs-14 color-blackColor mb-8">Brand Color:</div>
            <div className="d-flex">
                <div className="boxBorder mr-35">
                    <div className="innerBoxBorder " style={{backgroundColor: agencyData.color}}></div>
                </div>
                <ClickOutside onClick={() => setPicker(false)}>
                    <CustomTextField
                        icon={
                            <div className="cp" onClick={()=>setPicker(!picker)} style={{width: '18px', height: '18px', backgroundColor: agencyData.color, borderRadius: '50%'}}></div>
                        }
                        position={'start'}
                        value={agencyData.color}
                        onChange={(e)=> setAgencyData({...agencyData, color: e.hex})}
                    />
                    {picker && <ChromePicker 
                        className = "pallette position-absolute z-index-1 "
                        color     = {agencyData.color}
                        onChange  = {(e)=>handleColor(e)}
                    />}
                </ClickOutside>
            </div>

            <div className="fw-6 fs-18 color-blackColor mt-96">Icons</div>
            <div className="color-textFieldPlaceHolderColor fw-4 fs-14 mt-4 mb-16">To enable custom plans, you must first connect to any payment service to accept payments.</div>

            <UploadImage 
                title       = "Logo:"
                source      = {agencyData.logo}
                reference   = {logoRef}
                detail      = {'JPG, GIF or PNG. Max size of 5MB'}
                imageKey    = "logo"
                imageClass  = "innerBoxBorder"
                // handleImage = {handleImage}
                handleImage = {(e)=> {setImageModal({...imageModal, logo: {...imageModal.logo, open: true, imageEvent: e}}) }}
            />

            <CustomModal
                open      = { imageModal.logo.open }
                onClose   = { ()=> setImageModal({...imageModal, logo: {...imageModal.logo, open: false}}) }
                maxWidth  = {400}
                component = { 
                    <ImageModal 
                        imageModal    = { imageModal }
                        setImageModal = { setImageModal }
                        image         = { 'looknfeelLogo' }
                        formik        = { {agencyData, setAgencyData} }
                    />
                }
            />

            <UploadImage 
                title       = "Loader:"
                source      = {agencyData.loader}
                reference   = {loaderRef}
                detail      = {'Upload GIF'}
                imageKey    = "loader"
                imageClass  = "innerBoxBorderLoader"
                handleImage = {handleImage}
            />

            <UploadImage 
                title       = "Favicon:"
                source      = {agencyData.favicon}
                reference   = {faviconRef}
                detail      = {'Upload a 32 x 32 pixel ICO, PNG, GIF, or JPG to display in browser tabs.'}
                imageKey    = "favicon"
                imageClass  = "innerBoxBorderFavicon"
                handleImage = {handleImage}
            />
        </div>
    )
}

const UploadImage = ({title, reference, source, imageKey, handleImage, imageClass, detail}) => {
    const initColor = '#C4C4C4'
    return (
        <>
        <div className="fw-4 fs-14 color-blackColor mb-8 mt-32">{title}</div>
        <div className="d-flex">
            <div className="boxBorder mr-35">
                {
                    typeof source === 'object' ?
                        source !== '' ? 
                            <img className={imageClass} src={URL.createObjectURL(source)} alt="img"/> 
                            : 
                            <img className={imageClass} src={PngIcons.DefaultLoader} alt="img"/> 
                            // <div className={imageClass} style={{backgroundColor: initColor}}></div>
                            :
                            source !== '' ? 
                            <img className={imageClass} src={source} alt="img"/> 
                            : 
                            <img className={imageClass} src={PngIcons.DefaultLoader} alt="img"/> 
                            // <div className={imageClass} style={{backgroundColor: initColor}}></div>
                }
            </div>
            <div>
                <input
                    ref={reference}
                    onChange={(event)=> handleImage(event, imageKey)}
                    type="file"
                    style={{ display: "none" }}
                    multiple={false}
                />
                <CustomButton onClick={() => {reference.current.click()}} height="36px" btntext="Upload" icon={<SvgIcons.Upload />}/>
                <div className="mt-8 w-50 color-textFieldPlaceHolderColor fw-4 fs-14 w-80">{detail ? detail : 'Upload a 256 x 256 pixel logo image. This icon shows up when your website link is saved to an iPhone home screen.'}</div>
            </div>
        </div>
        </>
    )
}

export default LookAndFeel
