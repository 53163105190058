import * as yup from 'yup';
import { User } from '../enums'

const LoginVld = yup.object({
    email    : yup.string().email().required(),
    password : yup.string().min(8).required()
})

const ForgetPassword = yup.object({
    email    : yup.string().email().required(),
})

const ResetPassword = yup.object({
    password : yup.string().min(8).required()
})

const SignupVld = yup.object({
    fullName : yup.string().required('This field is required'),
    email    : yup.string().email().required(),
    password : yup.string().min(8).required()
    // password : yup.string().min(8).required() .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    //   ),
})

const OnBoard = yup.object({
    // dialingCode        : yup.string().required('This field is required').matches(/^\+[1-9]\d{0,4}$/),
    dialingCode        : yup.string().required('This field is required').matches(/^(\+?\d{1,3}|\d{1,4})$/),
    phone              : yup.string().nullable(),
    // phone              : yup.string().strict().required('This field is required').matches(/^\d{1,10}$/),
    jobDescription     : yup.string().required('This field is required'),
    companyStrength    : yup.string().required('This field is required'),
    experience         : yup.string().required(),
    walletlyReferences : yup.array().required('This field is required').min(1)
})

export {
    LoginVld,
    SignupVld,
    ForgetPassword,
    ResetPassword,
    OnBoard
}