import React, { useState } from 'react';
import {Route, Switch, Redirect} from "react-router-dom";
import General from './subpages/General';
import SettingHeader from '../../components/setting/SettingHeader';
import Domain from './subpages/Domain';
import PaymentServices from './subpages/PaymentServices';
import Languages from './subpages/Languages';
import LookAndFeel from './subpages/LookAndFeel';
import Plans from './subpages/Plans';
import Demo from './subpages/Demo';
import { useEffect } from 'react';
import Invite from './subpages/Invite';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import env from '../../config';
import Billing from './subpages/Billing';
import Integration from './subpages/Integration';
import localforage, * as localForage from 'localforage'

const stripePromise = loadStripe(env.STRIPE_PUBLISH_KEY)

export default function Setting(props) {
    const [state, setstate] = useState('')
    const [btn, setBtn] = useState()

    const setTitleFunc = (title) => {
        setstate(title)
    }

    const [user, setuser] = useState()

    useEffect(async()=>{
        props.setShow(true);
        const data = await localforage.getItem('user')
        setuser(data)
    },[])

    return (
        <div id="setting">
            <div className="row ml-0">
                <div className="col-12 pl-0">
                    <SettingHeader show={props.show} setShow={props.setShow} heading={state} btn={btn}  />
                </div>
                <div className="component col-12 pb-0" style={{marginTop: user && (!user.verified) && '47px'}}>
                    {/* Create Context Named As HeaderLayout */}
                    <Switch>    
                        <Route exact path = {props.match.url + '/general'}      render = {()=><General      setBtn={setBtn} setTitleFunc={setTitleFunc} />}/>
                        <Route exact path = {props.match.url + '/lookandfeel'}  render = {()=><LookAndFeel  setBtn={setBtn} setTitleFunc={setTitleFunc}/>}/>
                        <Route exact path = {props.match.url + '/languages'}    render = {()=><Languages    setBtn={setBtn} setTitleFunc={setTitleFunc} path={props.match.url} {...props}/>}/>
                        <Route exact path = {props.match.url + '/plans'}        render = {()=><Plans        setBtn={setBtn} setTitleFunc={setTitleFunc}/>}/>
                        <Route exact path = {props.match.url + '/services'}     render = {()=><PaymentServices   setBtn={setBtn} setTitleFunc={setTitleFunc} path={props.match.url} {...props}/>}/>
                        <Route exact path = {props.match.url + '/domain'}       render = {()=><Domain       setBtn={setBtn} setTitleFunc={setTitleFunc}/>}/>
                        <Route exact path = {props.match.url + '/invite'}       render = {()=><Invite       setBtn={setBtn} setTitleFunc={setTitleFunc}/>}/>
                        <Route path       = {props.match.url + '/integration'}  render = {()=><Integration       setBtn={setBtn} setTitleFunc={setTitleFunc}/>}/>
                        <Route exact path = {props.match.url + '/billing'} render      = {()=> 
                            <Elements stripe={stripePromise}>
                                <Billing setBtn={setBtn} setTitleFunc={setTitleFunc} {...props}/>
                            </Elements>}
                            />   

                        <Route exact path = {props.match.url + '/demo'}         render = {()=><Demo         setBtn={setBtn} setTitleFunc={setTitleFunc}/>}/>

                        <Redirect path = {props.match.url+ '/*'} to = {props.match.url+ '/general'} />
                        <Redirect path = {props.match.url}       to = {props.match.url+ '/general'} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}


