import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import CustomsModal from '../../../components/CustomModal'
import CustomTextField from '../../../components/CustomTextField'
import { SvgIcons } from '../../../icons'
import LanguageModal from './LanguageModal'

function Languages({setTitleFunc, setBtn}) {
    // setBtnTextFunc('+ Add Languages')
    setTitleFunc("Languages")
    const [open, setOpen] = useState(false)

    const buttonFunc = () => {
        return(
            <CustomButton
                btntext = {"+ Add Languages"}
                height  = "36px"
                width   = 'fit-content'
                onClick = {()=>setOpen(true)}
            />
        )
    }

    useEffect(() => {
        setBtn(buttonFunc)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    const data = [
        {
            language: 'English',
            code    : 'en-US'
        },
        {
            language: 'Spanish',
            code    : 'es-MX'
        },
        {
            language: 'Spanish',
            code    : 'ru-RU'
        },
        {
            language: 'Hindi',
            code    : 'hi-IN'
        },
        {
            language: 'Urdu',
            code    : 'ur-PK'
        },
        {
            language: 'Arabic',
            code    : 'ar-AE'
        },
        {
            language: 'Tamil',
            code    : 'ta-IN'
        },
    ]

    const [result, setResult] = useState(data)
    
    const handleSearch = (e) => {
        const result = data.filter(item =>
            item.language.toLowerCase().includes(e.target.value)
        );
        setResult(result)
    }  

    return (
        <div id="Languages">
            <div className="heading3 color-blackColor " >Your Languages</div>
            <div className="paragraph mb-16 color-textFieldPlaceHolderColor mt-4">These languages will be avaialble for your brands using walletly dashboard.</div>
            <div>
                <CustomTextField
                    className   = "w-50"
                    placeholder = "English, Spanish, Russian..."
                    // label       = "Add Domain"
                    icon={<SvgIcons.Search/>}
                    position={'start'}
                    onChange={(e)=>handleSearch(e)}
                />
            </div>
            <div className="list-scroll bar-full">
                {
                    result.map((value,index)=>(
                        <>
                            <div className="pl-30 d-flex space-between pr-40 align-items-center pt-24 pb-24 hover-div">
                                <div>
                                    <div className="buttonText color-blackColor">{value.language}</div>
                                    <div className="paragraph color-textFieldPlaceHolderColor">Language code: {value.code}</div>
                                </div>
                                <div className="more-hover cp">
                                    <SvgIcons.More/>
                                </div>
                            </div>
                            <div className="bar"/>
                        </>
                    ))
                }
            </div>
            <CustomsModal
                open={open}
                component={<LanguageModal />}
                onClose={()=>setOpen(false)}
                width={'412px'}
                // minWidth={'571px'}
                // maxWidth={'571px'}
            />
        </div>
    )
}

export default Languages
