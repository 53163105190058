import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/campaign'

const UpdateCampaign = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const GetQueriedCampaign = async ({query}) => await axios.get(env.API_URL+service+'/query?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const CreatePreBuiltCampaign = async({payload}) => await axios.post(env.API_URL+service+'/prebuilt', payload, {headers: await MainService.getTokenHeader()})

const CampaignService = {
    UpdateCampaign         : Handlers.Services(UpdateCampaign),
    GetQueriedCampaign     : Handlers.Services(GetQueriedCampaign),
    CreatePreBuiltCampaign : Handlers.Services(CreatePreBuiltCampaign),
}

export default CampaignService
