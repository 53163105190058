import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import { ColorSchemeCode } from '../enums/ColorScheme';
import { SvgIcons } from '../icons';

const useStylesElement = makeStyles((props) => ({
  root: {
    overflow: 'hidden',
    border: props => props.border ? props.border : props.showSearch === "false" ? '' :  '1px solid ' + ColorSchemeCode.textFieldBorderDefaultColor,
    height: props => props.height || '40px',
    color: ColorSchemeCode.textFieldTextActiveColor,
    borderRadius: props => props.borderRadius || 4,
    fontWeight: 400,
    borderColor: props=> props.borderColor || ColorSchemeCode.textFieldBorderDefaultColor,
    backgroundColor: props=> props.backgroundColor || ColorSchemeCode.cffffff,
    fontSize: '14px',
    fontFamily: 'Poppins',
    '&:hover': {
      backgroundColor: ColorSchemeCode.cffffff,
      border: props=> props.removerHover  ? '0px !important' : props.showSearch === "false" ? ''  : '1px solid ' + ColorSchemeCode.textFieldBorderHoverColor + '!important',
    },
    '&$focused': {
      backgroundColor: ColorSchemeCode.cffffff,
      border: props=>props.border === '0px' ? ' 0px ' : props.showSearch === "false" ? '' : '1px solid ' + '1px solid ' + ColorSchemeCode.brandingPrimaryColor + ' !important',
      color: ColorSchemeCode.textFieldTextActiveColor,
      boxShadow: '0px 0px 0px 4px rgba(47, 128, 237, 0.1)'
    },
  },
  'input': {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: ColorSchemeCode.textFieldPlaceHolderColor,
      opacity: 1,
      fontSize: 14,
      lineHeight: 21,  
    }
  },
  error: {
    border: '1px solid #f44336 !important',
  },
  focused: {},
}));

const useStylesInputElement = makeStyles((props) => ({
  root: {
    padding: props => props.position==="start" || props.dualIcon ? "10px 8px 10px 0px" : "10px 8px",
    paddingLeft : props => props.paddingLeft + ' !important' || '16px'
  }
}));

const useStylesLabelElement = makeStyles(() => ({
  root: {
    // paddingLeft: '8px',
  }
}));

const useStyleHelperTextElement = makeStyles(() => ({
  root: {
    fontSize: '12px',
    marginLeft: '0px !important'
  },
  contained: {
    marginLeft: '20px'
  }
}))

const useStyleInputLabelElement = makeStyles(() => ({
  root: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    // marginBottom: '2px !important',
    fontSize: 12,
    color : ColorSchemeCode.textFieldLabelColor,
    marginBottom: '4px'
  }
}))

const InputAdorment = (props) => {
  return (
    <InputAdornment position={props.position}>
      {props.icon}
    </InputAdornment>
  )
}

export default function CustomTextField({label, ...props}) {
  const classes      = useStylesElement(props);
  const inputClasses = useStylesInputElement(props);
  const labelClasses = useStylesLabelElement();
  const helperText   = useStyleHelperTextElement()
  const inputLabel   = useStyleInputLabelElement()

  const InputProps = {
    classes,
    disableUnderline : true,
    startAdornment   : props.icon && props.position === 'start' ? <InputAdorment position={props.position} icon={props.icon}/>                : '',
    endAdornment     : props.icon && props.position === 'end' ? <InputAdorment position={props.position} icon={props.icon} className="copy"/> : ''
  }
  
  const InputPropsDual = {
    classes,
    disableUnderline : true,
    startAdornment   : props.iconStart && <InputAdorment position={'start'} icon={props.iconStart}/>             ,
    endAdornment     : props.iconEnd && <InputAdorment position={'end'} icon={props.iconEnd} className="copy"/>
  }

  const [state, setstate] = useState(false)

  return <>
    <InputLabel classes = {inputLabel} shrink htmlFor = "bootstrap-input">{label}</InputLabel>
    <TextField
      {...props}
      variant             = "filled"
      autoComplete        = "off"
      type                = {props.type}
      defaultValue        = {props.value}
      placeholder         = {props.placeholder}
      onClick             = {props.onClick}
      onChange            = {props.onChange}
      inputProps          = {{ className: inputClasses.root, ...props.inputProps }}
      InputLabelProps     = {{ className: labelClasses.root }}
      InputProps          = {props.dualIcon ? InputPropsDual : InputProps}
      FormHelperTextProps = {{classes: helperText}}
      // className="mt-10"

      onBlur              = {() => setstate(true)}
      onFocus             = {() => setstate(false)}
      error               = {state && props.error}
      helperText          = {state && props.helperText &&
                              <div className="d-flex">
                                <SvgIcons.HelperTextIcon/>
                                <div className="ml-8">
                                  {props.helperText}
                                </div>
                              </div>}

    />
  </>;
}



