import React, { useContext, useEffect, useState } from 'react'
import CustomSelect from '../../../components/CustomSelect'
import CustomTextField from '../../../components/CustomTextField'
import { SvgIcons } from '../../../icons'
import { Avatar } from '@material-ui/core'
import { useFormik } from 'formik'
import { UserContext } from '../../../context/User.context'
import { CircularProgress } from '@material-ui/core';
import CustomButton from '../../../components/CustomButton';
import { UserService } from '../../../services'
import { UserVld } from '../../../validation'
import { Options, utils } from '../../../utils'
import ImageModal from '../../../components/ImageModal';
import localforage, * as localForage from 'localforage'
import CustomModal from '../../../components/CustomModal';


function ProfileSetting({setTitleFunc, setBtn}) {
    
    setTitleFunc('Profile')
    
    const user = useContext(UserContext)

    const [state, setState] = useState({
        loader   : false,
        apiError : '',
        file     : null,
        url      : ''
    })

    const [imageModal, setImageModal]    = useState({
            logo              : {open: false, imageEvent: '', return: ''},
        })


    const handleChange = function loadFile(event) {
        if (event.target.files.length > 0) {
            const file = URL.createObjectURL(event.target.files[0]);
            // setState({file: file});
            setState({...state, file: event.target.files[0], url: file});
        }
    };

    const handleAvatar = (e) => {
        e.preventDefault();
        const file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        formik.setValues({...formik.values, avatarFile: file[0]})
    }

    let date = new Date(user.birthday)
    console.log('user.birthday',user.birthday);
    
    
    const initState = {
        firstName     : user.firstName,
        lastName      : user.lastName,
        birthday      : date.getMonth() < 10 ? date.getFullYear()+"-0"+date.getMonth()+"-"+date.getDate(): date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate(),
        gender        : user.gender,
        logo          : user.avatar,
        agencyLogoFile: ''
        // avatar     : user.avatar,
        // avatarFile : '',
    }

    

    const buttonFunc = () => {

        return(
            <CustomButton
                btntext  = {!state.loader && "Update Changes"}
                height   = "36px"
                width    = '180px'
                onClick  = {(e)=>handleSubmit(e)}
                type     = "submit"
                disabled = {state.loader}
                iconClass={'d-flex justify-content-center'}
                icon     = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
            />
        )
    }

    console.log('user',user);
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({...state, loader: true, apiError: ''})

        const desiredPath = `${user._id}/${formik.values.firstName}`

        let avatar
        if(formik.values.agencyLogoFile && !formik.errors.agencyLogoFile)
        {
            avatar = await utils.uploadImage({file: formik.values.agencyLogoFile, desiredPath})
            if(avatar.error) return setState({...state, loader: false, apiError: avatar.error.message})
        }

        const payload = {
            _id            : user._id,
            firstName      : formik.values.firstName,
            lastName       : formik.values.lastName,
            birthday       : new Date(formik.values.birthday).valueOf,
            gender         : formik.values.gender,
            avatar         : avatar ? avatar.response.data : user.avatar
        }

        console.log('payload',payload);

        const { error, response } = await UserService.Update({toaster: true, payload})
        if(error) return setState({...state, loader: false, apiError: error.error})

        console.log('response.data',response.data);
        
        // localForage.setItem('user', response.data)
        user.updateUser(response.data)
        setState({...state, loader: false, apiError: ''})

    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : UserVld.ProfileUpdate,
        isInitialValid   : false
    })

    const onGenderChange = (e) => {
        const gender = e.target.value
        formik.setValues({...formik.values, gender})
    }

    const onChangeBirthday = (e) => {
        const birthday = new Date(e.target.value).valueOf()
        formik.setValues({...formik.values, birthday})
    }


    useEffect(() => {
        setBtn(buttonFunc)
    }, [state, formik.values]) 

    useEffect(async() => {
        const userLocal = await localforage.getItem('user')
        let date = new Date(userLocal.birthday)
        formik.setValues({...formik.values, logo: userLocal.avatar, birthday: date.getMonth() < 10 ? date.getFullYear()+"-0"+date.getMonth()+"-"+date.getDate() : date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate(), gender: userLocal.gender})
    }, [])


    return (
        <div id="ProfileSub" className="w-50">
            <div className="heading3 color-blackColor mb-4" >Basic Information</div>
            <div className="paragraph color-textFieldPlaceHolderColor">Your basic information which you’ll use to access walletly business.</div>
            
            <div className="buttonText color-blackColor mt-32">Photo</div>
            <div className="d-flex w-70 space-between align-items-center">
               <div className="tableFiled color-textFieldPlaceHolderColor">A photo helps us personlise your account</div>
                <div>
                    <input type="file" 
                        // onChange={(e)=>handleAvatar(e)} 
                        id="upload" 
                        accept="image/*" 
                        style={{display: "none"}}
                        onChange = {(e)=> {setImageModal({...imageModal, logo: {...imageModal.logo, open: true, imageEvent: e}}) }}
                    />
                    <label htmlFor="upload">
                        <div className="position-relative bottom-parent" >
                            <Avatar className="cp " id="avatar" 
                            // src={state.url || formik.values.avatar}
                                src   = {formik.values.agencyLogoFile ? URL.createObjectURL(formik.values.agencyLogoFile) : formik.values.logo}
                                style = {{
                                    width: "60px",
                                    height: "60px",
                                }}
                            />
                            {console.log('formik is ', formik)}
                            {/* <div className="bottom" >
                                <SvgIcons.Camera/>
                            </div> */}

                        </div>
                    </label>
                    <label htmlFor="avatar"/>
                </div>
                <CustomModal
                    open      = { imageModal.logo.open }
                    onClose   = { ()=> setImageModal({...imageModal, logo: {...imageModal.logo, open: false}}) }
                    maxWidth  = {400}
                    component = { 
                        <ImageModal 
                            imageModal    = { imageModal }
                            setImageModal = { setImageModal }
                            image         = { 'logo' }
                            formik        = {formik}
                        />
                    }
            />
            </div>
            
            <div className="mt-24">
                <CustomTextField
                    className   = "w-70"
                    placeholder = "First Name"
                    label       = "First Name"
                    name        = "firstName"
                    value       = {formik.values.firstName}
                    onChange    = {formik.handleChange}
                    inputProps  = {{ onFocus: formik.handleBlur }}
                    error       = {formik.touched.firstName && formik.errors.firstName}
                    helperText  = {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
                />
            </div>
            <div className="mt-24">
                <CustomTextField
                    className   = "w-70"
                    placeholder = "Last Name"
                    label       = "Last Name"
                    name        = "lastName"
                    value       = {formik.values.lastName}
                    onChange    = {formik.handleChange}
                    inputProps  = {{ onFocus: formik.handleBlur }}
                    error       = {formik.touched.lastName && formik.errors.lastName}
                    helperText  = {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
                />
            </div>
            
            <div className="mt-24">
                
                    <CustomTextField
                        className   = "w-70"
                        type        = "date"
                        placeholder = "birthday"
                        label       = "Birthday"
                        name        = "birthday"
                        value       = {typeof formik.values.birthday === 'number'? new Date(formik.values.birthday).toDateString():formik.values.birthday}
                        onChange    = {formik.handleChange}
                        inputProps  = {{ onFocus: formik.handleBlur }}
                        error       = {formik.touched.birthday && formik.errors.birthday}
                        helperText  = {formik.touched.birthday && formik.errors.birthday ? formik.errors.birthday : ''}
                    />
                
            </div>
            <div className="mt-24">
                    <CustomSelect 
                        className  = "w-70"
                        options    = {Options.getGender()}
                        label      = "Gender"
                        name       = 'gender'
                        value      = {formik.values.gender}
                        onChange   = {onGenderChange}
                        inputProps = {{ onFocus: formik.handleBlur }}
                        error       = {formik.touched.gender && formik.errors.gender}
                        helperText  = {formik.touched.gender && formik.errors.gender ? formik.errors.gender : ''}
                    />
            </div>
        </div>
    )
}

export default ProfileSetting
