import React from 'react';

export default function SidebarSub({items, MainItem, setMobileCheck}) {

    if(!items) return ('')

    // const handleClick = () => {
    //     setMobileCheck(false);
    // }   

    return (
            <div className="ml-4 mr-4">
                {items}
            </div>
        )
}
