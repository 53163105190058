import React, { useContext, useState } from 'react';
import { Link, useHistory } from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';
import { useFormik } from 'formik'
import * as localForage from 'localforage'

import { AuthVld } from '../../validation'
import { AuthService, UserService } from '../../services'
import { User } from '../../enums'

import CustomTextField from '../../components/CustomTextField';
import CustomButton from '../../components/CustomButton';
import { UserContext } from '../../context/User.context';
import { utils } from '../../utils';
import { green } from '@material-ui/core/colors';
import { SvgIcons } from '../../icons';

function Signup() {

  const history     = useHistory()
  const userContext = useContext(UserContext)

  const [state, setState] = useState({
    loader   : false,
    apiError : ''
  });

  const initState = {
    fullName : '',
    email    : '',
    password : ''
  }

  const formik = useFormik({
    // validateOnChange : false,
    // validateOnBlur   : false,
    initialValues    : { ...initState },
    onSubmit         : handleSubmit,
    validationSchema : AuthVld.SignupVld,
    isInitialValid   : false
  })

  async function handleSubmit(){
    setState({...state, loader: true, apiError: ''})


    let names     = formik.values.fullName.split(' ')
    let firstName = names.shift()
    let lastName  = names.join(' ')
    let payload = {
      firstName : firstName,
      email     : formik.values.email,
      password  : formik.values.password,
      role      : User.Roles.MASTER_ADMIN
    }

    if(lastName) payload.lastName = lastName

    const { error, response } = await AuthService.Signup({payload})
    
    if(error) return setState({...state, apiError: error.error})

    await localForage.setItem('token', response.token)
    const user = await UserService.Get()
    console.log('user', user);
    setState({...state, loader: false})
    
    if(user.error) return setState({...state, apiError: user.error.error})
   
    const userData = user.response.data
    payload  = {_id: userData._id, roles: ['admin', 'master_admin']}
    console.log(payload)
    UserService.Update({payload})
    
    // await localForage.setItem('user', user.response.data)


    userContext.updateUser(user.response.data)

    history.push('/verify')
  }

  var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  const [type, settype] = useState('password')

  const eyeClick = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      settype('password')
    } else {
      x.type = "password";
      settype('text')
    }
  }

  return (
    <>
      <div className="headingH2">{'Lets Get Started'}</div>  
      <div className="subtitle2 mt-12">
          One account for everything Intuit, including Mint
          <div><Link to=" " className="learnMoreLink">Learn More</Link></div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='input col-12'> 
          
          <div className="mt-32 text-left col-12">
            <CustomTextField 
              label       = "Full Name"
              className   = "inputTextField"
              type        = "text"
              placeholder = "Full Name"
              name        = "fullName"
              value       = {formik.values.fullName}
              onChange    = {formik.handleChange}
              inputProps  = {{ onFocus: formik.handleBlur }}
              error       = {formik.touched.fullName && formik.errors.fullName}
              helperText  = {formik.touched.fullName && formik.errors.fullName ? formik.errors.fullName : ''}
            />
          </div>
        
          <div className="mt-40 text-left col-12">
            <CustomTextField 
              label       = "Email address"
              className   = "inputTextField col-12"
              type        = "text"
              placeholder = "Email"
              name        = "email"
              value       = {formik.values.email}
              onChange    = {formik.handleChange}
              inputProps  = {{ onFocus: formik.handleBlur }}
              error       = {formik.touched.email && formik.errors.email}
              helperText  = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            />
          </div>
        
          <div className="mt-40 text-left col-12">
            <CustomTextField 
              label       = "Password"
              className   = "inputTextField col-12"
              type        = "password"
              placeholder = "Password"
              name        = "password"
              id          = "password"
              value       = {formik.values.password}
              onChange    = {formik.handleChange}
              inputProps  = {{ onFocus: formik.handleBlur }}
              error       = {formik.touched.password && formik.errors.password}
              icon        = {<div className={"cp"} onClick={eyeClick}>{type !== 'password' ? <SvgIcons.EyeIcon/> : <SvgIcons.CrossEyeIcon/>}</div>}
              position    = "end"
              helperText  = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
            />
          </div>
      
          {/* <div className='bullets'>
            <div className='row textsmall text-left'>
              <div className='col-lg-6 col-6'>
                <li style={{color: formik.values.password && utils.isLowerCase(formik.values.password) && 'green'}}>One lowercase character</li>
              </div>
              <div className='col-lg-6 col-6'>
                <li style={{color: formik.values.password && formik.values.password.length>7 && 'green'}}>8 characters minimum</li>
              </div>
            </div>

            <div className='row textsmall text-left'>
              <div className='col-lg-6 col-6'>
                <li style={{color: formik.values.password && utils.isUpperCase(formik.values.password) && 'green'}}>One uppercase character</li>
              </div>
              <div className='col-lg-6 col-6'>
                <li style={{color: formik.values.password && format.test(formik.values.password) && 'green'}}>One special character</li>
              </div>
            </div>

            <div className='row textsmall text-left'>
              <div className='col-lg-6 col-6'>
                <li style={{color: formik.values.password && utils.isNumeric(formik.values.password) && 'green'}}>One Number</li>
              </div>
            </div>
          </div> */}

          <div className="col-12">
            <CustomButton 
              className = "fs-14 fw-5 mt-32 col-12"
              btntext   = "Create Account"
              type      = "submit"
              disabled  = {state.loader || !formik.isValid}
              icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
            />
          </div>
          <div className='caption mt-20'>Already have an account yet? <Link to="/login" className="learnMoreLink"> Login here.</Link></div>
          {state.apiError && <div className={'error'}>{state.apiError}</div>}
          <div><img className="signupGif" src="/images/signup.gif" alt=""/></div>
        </div>  
      </form>
    </>
  );
}


export default Signup;
