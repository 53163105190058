const ColorSchemeCode = {
    'cAFAEAE'                   : '#AFAEAE',
    'c333333'                   : '#333333',
    'cEB763C'                   : '#EB763C',
    'cffffff'                   : '#ffffff',
    'c000000'                   : '#000000',
    'c828282'                   : '#828282',
    'c4F4F4F'                   : '#4F4F4F',
    'cE0E0E0'                   : '#E0E0E0',
    'c459B95'                   : '#459B95',
    'cE8F3F2'                   : '#E8F3F2',
    'cF2F2F2'                   : '#F2F2F2',
    'cF7F6F4'                   : '#F7F6F4',
    'cF7F7F7'                   : '#F7F7F7',
    'c5DB7B0'                   : '#5DB7B0',
    'c80C6C2'                   : '#80C6C2',
    'cE4E4E4'                   : '#E4E4E4',
    'c1AC4D1'                   : '#1AC4D1',
    'c808080'                   : '#808080',
    'c38DAE6'                   : '#38DAE6',
    'c65E2EC'                   : '#65E2EC',
    'c717171'                   : '#717171',
    'c23BFCB'                   : '#23BFCB',
    'cB8285C'                   : '#B8285C',
    'c1D3B56'                   : '#1D3B56',
    'cF5CB39'                   : '#F5CB39',
    'cFF824C'                   : '#FF824C',
    'c2AB570'                   : '#2AB570',
    'c9C54AA'                   : '#9C54AA',
    'c4950BA'                   : '#4950BA',
    'c4DCC6C'                   : '#4DCC6C',
    'cE7404A'                   : '#E7404A',
    'cB62759'                   : '#B62759',
    'cFA7C49'                   : '#FA7C49',
    'cFF005C'                   : '#FF005C',
    'cFFCCDE'                   : '#FFCCDE',
    'c3D0A65'                   : '#3D0A65',
    'c1367D8'                   : '#1367D8',
    'c2F80ED'                   : '#2F80ED',
    'cF0F0F0'                   : '#F0F0F0',
    'cBDBDBD'                   : '#BDBDBD',
    'c2D9CDB'                   : '#2D9CDB',
    'cE6E6E6'                   : '#E6E6E6',
    'cEFF0F6'                   : '#EFF0F6',
    'cE3E2E2'                   : '#E3E2E2',
    'c5ED17A'                   : '#5ED17A',
    'c6E7191'                   : '#6E7191',
    'cEBFFF3'                   : '#EBFFF3',
    'c47B005'                   : '#47B005',
    'c191919'                   : '#191919',
    'c062A32'                   : '#062A32',
    'cFEA2F1'                   : '#FEA2F1',
    'cFCFAF7'                   : '#FCFAF7',
    'cFBA2F1'                   : '#FBA2F1',
    'c04623E'                   : '#04623E',
    'cF8FAFB'                   : '#F8FAFB',
    'cF1F1F1'                   : '#F1F1F1',
    
    
    'placeholder'               : '#486381',
    'textfieldhoverborder'      : '#23BFCB',
    'textfieldColor'            : '#14142B',
    'subtitleColor'             : '#BDBDBD',
    'buttonhovercolor'          : '#1367D8',
    'buttontextcolor'           : '#FFFFFF',
    'themeColor'                : '#2F80ED',
    'themeColorHover'           : '#1367D8',
    'tableBackgroundColor'      : '#F6F6F9',
    'tableActionDropdownColor'  : '#A0A3BD',
    'tableSearchBoxColor'       : '#EAEBF0',
    'unlimitedPlanColor'        : '#7A7FFE',
    'unlimitedPlanColorHover'   : '#676DFE',
    'deleteButtonColor'         : '#EB5757',
    'textFieldBorderColor'      : '#E3E2E2',
    'actionDropdownTringleColor': '#1367D8',
    'caption2Color'             : '#A0A3BD',
    'headingColor'              : '#4F4F4F',
    'paragraphInputColor'       : '#999999',
    'selectBackgroundColor'     : '#F2F2F2',
    'activeColor'               : '#7ED98C',
    'selectGreenBackgroundColor': '#6FCF97',
    'shortDetailColor'          : '#A0A3BD',
    'accordionBorderColor'      : '#E0E0E0',
    'returnToProHover'          : '#4DCC6C',
    'returnToPro'               : '#5ED17A',
    'accordiansidebarValue'     : '#EBFFF3',
    'redColorButton'            : '#EB5757',

    //new Business Colors 

    'blackColor'                 : '#000000',
    'whiteColor'                 : '#ffffff',
    'navigationUnSelected'       : '#B2B2CC',
    'navgiationTextSelected'     : '#47476B',
    'navigationSelectedBg'       : '#ECF4FD',
    'brandingPrimaryColor'       : '#2F80ED',
    'textFieldLabelColor'        : '#6B778C',
    'textFieldPlaceHolderColor'  : '#7A869A',
    'textFieldTextActiveColor'   : '#091E42',
    'textFieldBorderDefaultColor': '#DFE1E6',
    'textFieldBorderHoverColor'  : '#8C94A6',
    'iconPrimaryColor'           : '#42526E',
    'buttonTertiaryColor'        : '#F5F6F8',
    'buttonHoverColor'           : '#5F9EF1',
    'hoverColor'                 : '#F6F9FE',
    'dividerColor'               : '#F0F0F0',
    'disableButtonColor'         : '#73A9F2',
    
    'green2'                     : '#27AE60'
}

export {
    ColorSchemeCode,
}