import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ColorSchemeCode } from '../enums/ColorScheme'
import Button from '@material-ui/core/Button';

const getHoverColor = {
  '#5F9EF1' : '#5F9EF1'
}

const textColor = {
  '#2F80ED' : '#FFFFFF',
  '#5ED17A' : '#FFFFFF'
}

const getFocusColor = {
  '#2F80ED' : '#1367D8'
}


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.backgroundColor,
    borderRadius: props => props.borderRadius || '4px',
    border: props => props.border,
    boxShadow: 'none',
    height: props => props.height,
    color: props => props.color,
    textTransform: 'capitalize',
    padding: props => props.padding || '15px 12px',
    fontFamily: 'Poppins',
    width: props => props.width,
    fontSize: props => props.fontSize || '14px',
    '&:hover': {
      backgroundColor: props => props.hover ? props.hover : ColorSchemeCode.buttonHoverColor,
      boxShadow: 'none',
      color: props => props.hovertextColor
    },
    '&:focus': {
      backgroundColor: props => props.focus || ColorSchemeCode.buttonHoverColor,
      color: props => props.hovertextColor,
      boxShadow: 'none',
      outline: 'none'
    },
  },
}));

export default function CustomButton({backgroundColor, position, color, className, onClick, disabled, btntext, icon, ...props}) {
  const propsObj = {
    className       : className,
    backgroundColor : backgroundColor || ColorSchemeCode.brandingPrimaryColor,
    hover           : getHoverColor[backgroundColor || ColorSchemeCode.buttonHoverColor],
    focus           : getFocusColor[backgroundColor || ColorSchemeCode.themeColor],
    color           : color ? color : textColor[backgroundColor || ColorSchemeCode.themeColor],
    ...props
  }
  const classes = useStyles(propsObj);
  const customClass = propsObj.className

  delete propsObj.className

  return (
    <Button 
      {...propsObj} 
      onClick   = {onClick}
      variant   = "contained"
      color     = {color}
      disabled  = {disabled}
      className = {classes.root + ' ' + customClass}
    >
    {
      icon ? <div className={`d-flex ${position === 'end' && 'row-reverse' }`}><div className={props.iconClass}>{icon}</div>&nbsp;<div>{btntext}</div></div>
      : <div> {btntext} </div>
    }
    </Button>
  )
}
