import React, { useContext } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";


import CircularProgress from '@material-ui/core/CircularProgress';
import { useFormik } from 'formik'

import * as localForage from 'localforage'

import { AuthVld } from '../../validation'
import { AuthService, UserService } from '../../services'
import { PermissionService } from '../../services'

import CustomTextField from '../../components/CustomTextField';
import CustomButton from '../../components/CustomButton';
import { BusinessType } from '../../enums/enums';
import { UserContext } from '../../context/User.context';
import { Brand, User } from '../../enums';



function ResetPassword() {

  const history     = useHistory()
  // const userContext = useContext(UserContext)

  const [ state, setState ] = React.useState({
    apiError : '',
    loader    : false
  })

  const initState = {
    password    : '',
  }

  const formik = useFormik({
    // validateOnChange : false,
    // validateOnBlur   : false,
    initialValues    : { ...initState },
    onSubmit         : handleSubmit,
    validationSchema : AuthVld.ResetPassword,
    isInitialValid   : false
  })

  async function handleSubmit(){
    setState({...state, loader: true})

    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf('/') + 1);
    const payload = { 
      _id      : id,
      password : formik.values.password
    }
    const {response, error} = await AuthService.UpdatePassword({ toaster: true ,payload })
    
    setState({...state, loader: false})
    if(response.success) history.push('/')
  }
  

  return (
    <>
      <div className="headingH2">{'New Password'}</div>  

     
      <form onSubmit={formik.handleSubmit}>
        <div className='input col-12'> 
        
         
        
          <div className="mt-40 text-left col-12">
            <CustomTextField 
              label       = "Password"
              className   = "inputTextField col-12"
              type        = "password"
              placeholder = "Password"
              name        = "password"
              value       = {formik.values.password}
              onChange    = {formik.handleChange}
              inputProps  = {{ onFocus: formik.handleBlur }}
              error       = {formik.touched.password && formik.errors.password}
              helperText  = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
            />
          </div>

          <div className="col-12">
            <CustomButton 
              className = "fs-14 mt-32 col-12"
              btntext   = "Submit"
              type      = "submit"
              disabled  = {state.loader || !formik.isValid}
              icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
            />
          </div>
          {state.apiError && <div className={'error'}>{state.apiError}</div>}

        </div>  
      </form>
    </>
  );
}


export default ResetPassword;
