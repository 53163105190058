const PngIcons = {
    walletlyLoader     : '/images/walletly.loader.gif',
    WalletlyBusinessIcon : '/images/logo.png',
    WalletlyBusinessLogo : '/images/logo.webp',
    // WalletlyBusinessIcon : window.location.origin +'/images/logo.png',
    // WalletlyBusinessLogo : window.location.origin +'/images/logo.webp',
    DefaultLoader        : '/images/defaultLoader.gif',

    SearchIcon      : '/images/growthTool/Search.png',

    Toaster:{
        error   : '/images/toaster/Error.png',
        warning : '/images/toaster/Warning.png',
        info    : '/images/toaster/Info.png',
        success : '/images/toaster/Success.png'
    },

    AudienceIcons:{
        ManyChat    : '/images/audience/Manychat.png',
        Landingpage : '/images/audience/Landingpage.png',
        Email       : '/images/audience/Email.png',
        Notification: '/images/audience/Notification.png',
        Sms         : '/images/audience/Sms.png',
    },

    PaymentCard:{
        mastercard : '/images/paymentCard/master.png',
        visa       : '/images/paymentCard/visa.png',
        default    : '/images/paymentCard/default.png'
    }

}





export default PngIcons