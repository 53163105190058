import React, { Component } from "react";
import Loader from "../components/Loader";
import Notfound from "../components/Notfound";
import { enums } from "../enums";
import PngIcons from "../icons/png.icon";

import { BrandService } from "../services";

const BrandContext = React.createContext();

class BrandContextComponent extends Component {
  state = {loader: true}

  componentDidMount = () => {
      const state = this.props.location.state || {}
      if(!state.activeBrand) return this.getBrand()
      this.setState({loader: false, ...this.props.location.state})
  }

  getBrand = async () =>{
      const query = this.props.match.params
      const { error, response } = await BrandService.Get({query})
      if(error && !error.error.includes('jwt expired')) 
      return this.setState({loader: false, error: error.error})

      if(response)
      return this.setState({loader: false, ...response.data[0]})
  }

  updateBrand = (updates) => this.setState({...this.state, ...updates})

  render() {
    return (
      <>
      {this.state.loader && <Loader width={300} height={300} />}
      {this.state.error && <div className="middle"><Notfound /></div>}
      {
        !this.state.loader && !this.state.error &&
        <BrandContext.Provider value={{...this.state, updateBrand: this.updateBrand}}>
         {this.props.children}
        </BrandContext.Provider>
      }
      </>
    )
  }
}

export { BrandContext, BrandContextComponent }