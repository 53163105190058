import React,{useContext, useEffect, useState} from 'react';
import localForage from 'localforage';
import Fade from 'react-reveal/Fade';

import CircularProgress from '@material-ui/core/CircularProgress';

import NormalDropDown from '../../../components/NormalDropDown';
import CustomTab from '../../../components/CustomTab';
import Analytics from './Analytics';
import CustomButton from '../../../components/CustomButton';
import CustomTextField from '../../../components/CustomTextField';
import CustomAction from '../../../components/CustomAction';
import CustomsModal from '../../../components/CustomModal';
import DeleteModal from '../../../components/DeleteModal';

import { SvgIcons } from '../../../icons'
import { ClickOutside, utils } from '../../../utils';
import {ColorSchemeCode} from '../../../enums/ColorScheme'

import { AgencyContext } from '../../../context/Agency.context';
import { BrandContext } from '../../../context/Brand.context';
import { AgencyService, BrandService } from '../../../services';
import Loader from '../../../components/Loader';
import { Agency } from '../../../enums';



export default function AudiencMain({setTitleFunc}) {
    
    setTitleFunc('Audience')

    const [state, setState] = useState({
        brands: {
            myBrands    : [],
            otherBrands : []
        },
        showDropDown  : false,
        selectedBrand : '',
        openModal     : false,
        progress      : false,
        showSearch    : false,
        coupon        : '',
        paymentModal  : false
    });

    const [show, setShow] = useState({
        paymentModal : false,
    })


    const agency = useContext(AgencyContext)


    const GetBrands = async(refresh) => {
        setState({...state, progress: true})
        let user = await localForage.getItem('user')
        let myBrands = await localForage.getItem('myBrands');
        let otherBrands = await localForage.getItem('otherBrands');

        let myBrandsApi, otherBrandsApi
        if((!myBrands) || refresh)  myBrandsApi    = await BrandService.GetUserBrands({query: {agencyId: agency._id}})
        if((!otherBrands) || refresh)  otherBrandsApi = await BrandService.GetOtherBrands({query: {agencyId: agency._id}})
        
        console.log(myBrands, otherBrands)


        if(otherBrandsApi && otherBrandsApi.error) { otherBrands = [];  localForage.setItem('otherBrands', [])}
        if(myBrandsApi && myBrandsApi.error) { myBrands = []; localForage.setItem('myBrands', [])}

        if((!myBrands && myBrandsApi.response) || (refresh && myBrandsApi.response))
        {
            myBrands = myBrandsApi.response.data.filter(item=>
                item.brandType !== 'subBrand'
            )     
            localForage.setItem('myBrands', myBrands)       
        }

        if((!otherBrands && otherBrandsApi.response && otherBrandsApi.response.data) || (refresh && otherBrandsApi.response && otherBrandsApi.response.data)) {
           otherBrands = otherBrandsApi.response.data
           localForage.setItem('otherBrands', otherBrands)     
        }        

        setState({...state, progress: false, selectedBrand : myBrands && myBrands[0] , brands: {...state.brands, myBrands: myBrands,  otherBrands: otherBrands}})

    }

    const onLoad = async () => {
        await GetBrands()
    }

    useEffect(onLoad, [])
    return (
        <div className='d-flex flex-row '>
            {console.log('asdasdasd',state.selectedBrand)}
            <div className='w-25 brandlisting'>
                <BrandListing GetBrands={GetBrands} show={show} setShow={setShow} state={state} setState={setState}/>
            </div>
            <div className="w-75">
                {!state.progress ? state.selectedBrand ? <BrandDetails GetBrands={GetBrands} state={state} setState={setState} selectedBrand = {state.selectedBrand} show={show} setShow={setShow}/> : <div className="color-c000000 fw-6 fs-30 h-100 middle subtitle1">No Brand's Found</div> : <Loader width={128} height={128} />}
            </div>

        </div>
    )
}


const BrandListing = ({state, setState, show, setShow, GetBrands}) => {
    const agency = useContext(AgencyContext)
    
    const [expanded, setExpanded] = useState({
        myBrand    : true,
        otherBrand : true
    })

    const [processing, setProcessing] = useState({
        id : '',
        show : false,
    })

  
    const ascendingOrder = () =>{
        let ascendingOrderBrands = state.brands.myBrands.sort((a, b) => (a.brandName > b.brandName ? 1 : -1));
        setState({...state,  brands : {...state.brands , myBrands : ascendingOrderBrands }})
        showDropDown()
    }
    
    const descendingOrder = () => {
        let descendingOrderBrands = state.brands.myBrands.sort((a, b) => (a.brandName > b.brandName ? -1 : 1));
        setState({...state,  brands : {...state.brands , myBrands : descendingOrderBrands }})
        showDropDown()
        
    }

    const showDropDown = () => {
        setState({ ...state,
            showDropDown: !state.showDropDown
        })
    }

    const options = [
        {
            name: 'Sort A -> Z',
            function : ascendingOrder
        },
        {
            name: 'Sort Z -> A',
            function : descendingOrder
        }
    ]

    // const handleConnect = async (e, brand, myBrand) => {
    const handleConnect = async (brand, myBrand) => {
        // e.stopPropagation()
        
        setProcessing({id : brand._id, show : true})

        const payload = {
            // agencyId : agency._id,
            // brandId  : brand._id,
            // myBrand  : myBrand
            _id      : agency._id,
            planName : Agency.Plans.BRAND_CONNECTION,
            email    : brand.email,
            version  : 1,
            couponId : state.coupon
        }


        // const {error, response} = await AgencyService.ConnectBrand({toaster: true, payload})
        const {error, response} = await AgencyService.CreateSubscription({toaster: true, payload})

        if(response){
            brand.agency = {
                id           : agency.id,
                subscribed   : true,
                subscribedAt : Date.now()
            }
            
            const myBrands = await localForage.getItem('myBrands')
            myBrands.map((element, idx)=>{
                if(element._id === brand._id) element.agency = brand.agency
            })
            localForage.setItem('myBrands', myBrands)
            
            const otherBrands = await localForage.getItem('otherBrands')
            otherBrands.map((element, idx)=>{
                if(element._id === brand._id) element.agency = brand.agency
            })
            localForage.setItem('otherBrands', otherBrands)
            
            setState({...state})
        }
        
        setProcessing({id : '', show : false})
    }

    const handleDisconnect = async (e, brand) => {
        console.log('brand', brand.subscription, brand._id)
        if((brand.subscription && brand.subscription.canceledAtPeriodEnd) || (!brand.subscription)){

            // console.log('======',brand);
            setProcessing({id : brand._id, show : true})
            const payload = {
                _id      : brand._id,
                agencyId : agency._id,
                cancel   : true
            }
            
            const {error, response} = await AgencyService.CancelSubscription({toaster: true, payload})
            console.log(response, error);
            await GetBrands(true)
            setProcessing({id : brand._id, show : false})
            // response && brand.updateBrand(response.data)
        }
        else{
            utils.showToaster({position: 'top-right', title: 'Cancel Subscription', message: 'First Cancel Brand Subscription', severity: 'error'})
        }
    }
        
    const brand = useContext(BrandContext)

    const [filteredBrands, setFilteredBrands] = useState({
        myBrands   : [],
        otherBrands: [],
    });

    useEffect(()=>{
        setFilteredBrands({...filteredBrands, myBrands : state.brands.myBrands , otherBrands : state.brands.otherBrands})
    },[state.brands])

    const handleBrandSearchFunc = (e) => {

        const filterSearchBrands = (brands) => brands.filter(brand =>
            brand.brandName.toLowerCase().includes(e.target.value.toLowerCase()) 
        );
        setFilteredBrands({...filteredBrands, myBrands : filterSearchBrands(state.brands.myBrands) , otherBrands : filterSearchBrands(state.brands.otherBrands)})
    }


      

    return (
        <div id='BrandListing'>
            <div className='head pt-16 d-flex'>
               <div className="d-flex pl-32 w-100 justify-content-flex-end">
                    <div className={`d-flex flex-column w-100 ${state.showSearch && 'display-none'}`}>
                        <div className='subtitle2'>
                         Your Brands
                        </div>
                        <div className='textsmall color-textFieldPlaceHolderColor'>
                        {state.brands.myBrands.length} brands
                        </div>
                    </div>
                    <div className="d-flex w-100">
                        <ClickOutside className='w-100' onClick={()=>setState({...state, showSearch : false})}>
                            <div className=" text-right searchBox w-100" >
                                <CustomTextField 
                                    icon         = {<SvgIcons.Search onClick={()=>setState({...state, showSearch : !state.showSearch})}/>}
                                    position     = {!state.showSearch ? 'start' : 'end'}
                                    onChange     = {handleBrandSearchFunc}
                                    showSearch   = {state.showSearch ? 'true' : 'false'}
                                    className    = {!state.showSearch ?  'border-none w-33' : 'w-100'}
                                    placeholder  = {state.showSearch && "Search Brands"}
                                />
                            </div>
                        </ClickOutside>
                        {!state.showSearch && <div className='pl-8 pt-10 cp' onClick={showDropDown}>
                            <SvgIcons.SortIcon/>
                        </div>}
                    </div>
                    <div id='customRefresh' style={{marginTop: '10px', marginLeft: '10px', marginRight: '10px'}} onClick={()=>GetBrands(true)}>
                        <SvgIcons.RefreshIcon color={'#42526E'} className='imageButton cp' height='20px' width='20px' />
                        {/* <img className='imageButton cp' height='20px' width='20px' src='/images/Refresh.png'/> */}
                    </div>
               </div>
               <ClickOutside onClick={()=>setState({...state, showDropDown : false})}>
                    {state.showDropDown && <NormalDropDown options={options}/>}
                </ClickOutside>
            </div>
            <div className='listing'>
                <div className={`w-100 brand cp position-relative z-index-100 ${expanded.myBrand && 'bg-color-hoverColor'}` } onClick={()=> setExpanded({...expanded, myBrand: !expanded.myBrand})}>
                    <div className='d-flex flex-row '>
                        <div className='pl-32 pt-16 d-flex flex-column'>
                            <div className='subtitle2'>
                                My Brands
                            </div>
                            <div className='textsmall color-textFieldPlaceHolderColor'>
                                {state.progress ? <CircularProgress size={10} color={'inherit'}/> : state.brands.myBrands.length  + ' Brands'}
                                {/* {state.brands.myBrand.filter((element)=>{if(element.active) return element}).length+' Brands'} */}
                            </div>
                        </div>
                        <div className = 'pl-8 pt-16 pr-32 position-absolute r-0'>
                        <div className = {expanded.myBrand ? '' : 'rotate'}><SvgIcons.Dropdown /></div>
                        </div>
                    </div>
                </div>
                {
                expanded.myBrand &&
                    filteredBrands.myBrands.map((brand, idx)=>(
                     <Fade top>
                        <div className={`w-100 brand cp ${brand._id === state.selectedBrand._id && 'activeBrand'}`} onClick={()=> setState({...state , selectedBrand: {...brand, myBrand: true}})}>
                            <div className='d-flex flex-row justify-content-space-between pl-32 pt-16 pb-8  pr-32'>
                                <div className='d-flex flex-row'>
                                        <div className=''>
                                            {   brand.brandLogo ?
                                                <img src={brand.brandLogo} width='40px' className='brandimg' height='40px' alt=''/> :
                                                utils.getAvatar({firstName: brand.brandName, width: '40px', heigth: '40px'})
                                            }
                                        </div>
                                        <div className='d-flex flex-column ml-8'>
                                            <div className='subtitle2'>
                                                {brand.brandName.substring(0,9) + '...'}
                                            </div>
                                            <div className='textsmall color-textFieldPlaceHolderColor'>
                                                {brand.location.address.substring(0,13) + '...'}
                                            </div>
                                        </div>
                                </div>
                                {/* <div className="middle text-align-center">
                                    <div 
                                        onClick={e=>brand.agency && brand.agency.subscribed ? handleDisconnect(e, brand) : setShow({...show, paymentModal: true})}
                                        // console.log();
                                        // onClick={e=>brand.agency && brand.agency.subscribed ? handleDisconnect(e, brand) : handleConnect(e, brand, true)}
                                        style={{height: "24px", width: '80px'}} // remove inline style
                                        className={`borderRadius-2 pr-14 pl-14 pt-4 pb-4 fs-12 color-whiteColor d-flex justify-content-center ${(processing.id === brand._id ) && processing.show && 'disabled'} ${brand.agency && brand.agency.subscribed ? " bg-color-textFieldBorderDefaultColor" : "bg-color-blackColor"}`}>
                                            {(processing.id === brand._id ) && processing.show && <div className="mr-4"><CircularProgress size={10} color={'inherit'}/></div>}
                                            {brand.agency && brand.agency.subscribed ? 'Disconnect' : 'Connect'}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                </Fade>
                ))
                }
                {/* <div className={`w-100 brand cp position-relative z-index-100 ${expanded.otherBrand && 'bg-color-hoverColor'}` } onClick={()=> setExpanded({...expanded, otherBrand: !expanded.otherBrand})}>
                    <div className='d-flex flex-row'>
                        <div className='pl-32 pt-16 d-flex flex-column'>
                            <div className='subtitle2'>
                                {'Other Brands'}
                            </div>
                            <div className='textsmall color-textFieldPlaceHolderColor'> */}
                                {/* {state.progress ? <CircularProgress size={10} color={'inherit'}/> : state.brands.otherBrands.length > 0 && state.brands.otherBrands.length + ' Brands' } */}
                                {/* {state.progress ? <CircularProgress size={10} color={'inherit'}/> :  state.brands.otherBrands.length || 0  +' Brands'} */}
                            {/* </div>
                        </div>
                        <div className='pl-8 pt-16 pr-32 position-absolute r-0'>
                            <div className={expanded.otherBrand ? '' : 'rotate'}><SvgIcons.Dropdown /></div>
                        </div>
                    </div>
                </div> */}
                 {
                //     expanded.otherBrand &&
                //     filteredBrands.otherBrands.map((brand, idx)=>(
                //         <Fade top>
                //         <div className={`w-100 brand cp ${brand._id === state.selectedBrand._id && 'activeBrand'}`} onClick={()=> setState({...state , selectedBrand: {...brand, myBrand: false} })}>
                //             <div className='d-flex flex-row justify-content-space-between'>
                //                 <div className='d-flex flex-row'>
                //                     <div className='pl-32 pt-16'>
                //                         <img src={brand.brandLogo} width='40px' className='brandimg' height='40px' alt=''/>
                //                     </div>
                //                         <div className='d-flex flex-column pl-8 pt-16'>
                //                             <div className='subtitle2'>
                //                                 {brand.brandName.substring(0,9) + '...'}
                //                             </div>
                //                             <div className='textsmall color-textFieldPlaceHolderColor'>
                //                                 {brand.location.address.substring(0,13) + '...'}
                //                             </div>
                //                         </div>
                //                 </div>
                //                 <div className="middle mr-24" onClick={(e)=>e.stopPropagation()}>
                //                     {/* <SvgIcons.More/> */}
                //                     <CustomAction options={[{name: 'Delete', icon: <SvgIcons.DeleteIcon color="black"/>, function: ()=>setState({...state, openModal: true})}]}/>

                //                     <CustomsModal
                //                         open={state.openModal}
                //                         component={
                //                                 <DeleteModal 
                //                                     head                = "Are you sure ?"
                //                                     description         = {<div>Do you really want to delelte this brand? <br/>This process cannot be undone.</div>}
                //                                     ActionButtonText    = "Delete"
                //                                     CancelButtonText    = "Cancel"
                //                                     ActionButtonOnClick = {()=>setState({...state, openModal: false})}
                //                                     CancelButtonOnClick = {()=>setState({...state, openModal: false})}
                //                                 />
                //                         }
                //                         onClose={()=>setState({...state, openModal: false})}
                //                     />
                //                 </div>
                //             </div>
                //         </div>
                // </Fade>
                //     ))
                // 
                }
            </div>
        </div>
    )
}


const BrandDetails = ({selectedBrand, state, setState, show, setShow, GetBrands}) => {

    const labels = ['Brand Detail','Payment Detail','Customer Detail','Analytics']

    const components = [
        <BrandDetail    selectedBrand = {selectedBrand}/>,
        <PaymentDetail  selectedBrand = {selectedBrand}/>,
        <CustomerDetail selectedBrand = {selectedBrand}/>,
        <Analytics      selectedBrand = {selectedBrand}/>,
    ]

    return (
        <div id='BrandDetails' >
            <div className="d-flex ml-32 mt-16 align-items-center mb-16">
                {   selectedBrand.brandLogo ?
                    <img src={selectedBrand.brandLogo} width='40px' className='mr-12 logo' height='40px' alt=''/> :
                    utils.getAvatar({firstName: selectedBrand.brandName, width: '40px', heigth: '40px'})
                }
                <div className="color-blackColor fs-16 mr-8 fw-6">{selectedBrand.brandName}</div>
                {/* <div className="bg-color-buttonTertiaryColor p-4 fw-5 fs-12 color-blackColor active">{selectedBrand.agency && selectedBrand.agency.subscribed ? 'Active' : 'Deactive'}</div> */}
            </div>

            {/* <CustomTab
                className  = "ml-32"
                labels     = {labels}
                components = {components}  
            /> */}
            <BrandDetail    selectedBrand = {selectedBrand}/>

            <CustomsModal 
                component = {<PaymentModal GetBrands={GetBrands} state={state} setState={setState} show={show} setShow={setShow} selectedBrand={selectedBrand}/>}
                open      = {show.paymentModal}
                onClose   = {()=>setShow({...show, paymentModal : false})}
                minWidth  = "560px"
            />

        </div>
    )
}

const BrandDetail = ({selectedBrand}) => {

    return (
        <div className="m-24">
            <div className="color-textFieldLabelColor fw-5 fs-12">Subscribed Date</div>
            <div className="color-textFieldTextActiveColor fw-4 fs-14 mt-4 mb-24">{selectedBrand.agency && utils.getDate(selectedBrand.agency.subscribedAt)}</div>
            
            <div className="color-textFieldLabelColor fw-5 fs-12">Email Address</div>
            <div className="color-textFieldTextActiveColor fw-4 fs-14 mt-4 mb-24">{selectedBrand.email}</div>
            
            <div className="color-textFieldLabelColor fw-5 fs-12">Contact Number</div>
            <div className="color-linkContact fw-4 fs-14 mt-4 mb-24 cp">{selectedBrand.dialingCode + selectedBrand.phone}</div>
            
            <div className="color-textFieldLabelColor fw-5 fs-12">Location</div>
            <div className="color-linkContact fw-4 fs-14 mt-4 cp">{selectedBrand.location.address}</div>
        </div>
    )
}

const PaymentDetail = () => {

    const [paymentDetails , setPaymentDetails ] = useState();

    return (
        <div id="PaymentDetail" className="mt-24 position-relative middle">
            {paymentDetails ? <>
                <div className="mr-33 mb-16 d-flex justify-content-flex-end">
                    <CustomButton hover="#F6F9FE !important" height="36px" id="PlansProps" className={'bg-color-whiteColor pt-6 pr-12 pb-6 pl-12 color-brandingPrimaryColor button'} btntext={"Export CSV"} />
                </div>
                <div className="mt-24">
                    <div className="row mb-12">
                        <div className="col fw-5 fs-12 color-textFieldLabelColor">Date</div>
                        <div className="col fw-5 fs-12 color-textFieldLabelColor">Amount</div>
                        <div className="col fw-5 fs-12 color-textFieldLabelColor">Status</div>
                        <div className="col fw-5 fs-12 color-textFieldLabelColor">PDF</div>
                    </div>
                    <div className="bar "/>
                    <div className="scroll">
                        {
                            [1,2,3,4,5,6,7,8,9,1,1,1,1,1,1,1,1,1,1,1].map((value,index)=>(
                                <>
                                {index !== 0 && <div className="bar " />}
                                <div className="row mt-12 mb-12">
                                    <div className="col fw-4 fs-12 color-textFieldTextActiveColor">1 Feb, 2020</div>
                                    <div className="col fw-4 fs-12 color-textFieldTextActiveColor">$ 99</div>
                                    <div className="col fw-5 fs-12 color-green2">PAID</div>
                                    <div className="col fw-4 fs-12 underline color-themeColor">Invoice</div>
                                </div>
                                </>
                            ))
                        }
                    </div>
                </div>
            </>
            :
            <div className="color-c000000 fw-5 fs-20 subtitle1">
                No payment details found
            </div>
        
            }

        </div>
    )
}

const CustomerDetail = () => {

    const [customerDetail, setCustomerDetail] = useState();

    const data = [
        {
            name: 'Esther Howard',
            email: 'esther0023@gmail.com',
            loyalty: '100',
            redemption: '100',
            status: 'Deactive'
        },
        {
            name: 'Devon Lane',
            email: 'devonLane@gmail.com',
            loyalty: '200',
            redemption: '200',
            status: 'Active'
        },
        {
            name: 'Cody Fisher',
            email: 'codyfisher@gmail.com',
            loyalty: '100',
            redemption: '100',
            status: 'Active'
        },
        {
            name: 'Esther Howard',
            email: 'esther0023@gmail.com',
            loyalty: '100',
            redemption: '100',
            status: 'Deactive'
        },
        {
            name: 'Devon Lane',
            email: 'devonLane@gmail.com',
            loyalty: '200',
            redemption: '200',
            status: 'Active'
        },
        {
            name: 'Cody Fisher',
            email: 'codyfisher@gmail.com',
            loyalty: '100',
            redemption: '100',
            status: 'Active'
        },
        {
            name: 'Esther Howard',
            email: 'esther0023@gmail.com',
            loyalty: '100',
            redemption: '100',
            status: 'Deactive'
        },
        {
            name: 'Devon Lane',
            email: 'devonLane@gmail.com',
            loyalty: '200',
            redemption: '200',
            status: 'Active'
        },
        {
            name: 'Cody Fisher',
            email: 'codyfisher@gmail.com',
            loyalty: '100',
            redemption: '100',
            status: 'Active'
        },
        {
            name: 'Esther Howard',
            email: 'esther0023@gmail.com',
            loyalty: '100',
            redemption: '100',
            status: 'Deactive'
        },
        {
            name: 'Devon Lane',
            email: 'devonLane@gmail.com',
            loyalty: '200',
            redemption: '200',
            status: 'Active'
        },
        {
            name: 'Cody Fisher',
            email: 'codyfisher@gmail.com',
            loyalty: '100',
            redemption: '100',
            status: 'Active'
        },
    ]

    const [result, setResult] = useState(data)
    
    const handleSearch = (e) => {
        const result = data.filter(item =>
            item.name.toLowerCase() === e.target.value
        );
        setResult(result)
    }  

    return (
        <div id="CustomerDetail" className="mt-24 middle">
            {customerDetail ? <><div className="d-flex mr-33 mb-16 align-items-center justify-content-space-between">
                <div className="search">
                    <CustomTextField
                        className   = "w-100"
                        placeholder = "Search by full name"
                        icon={<SvgIcons.Search/>}
                        position={'start'}
                        onChange={(e)=>handleSearch(e)}
                    />
                </div>
                <div >
                    <CustomButton hover="#F6F9FE !important" height="36px" id="PlansProps" className={'bg-color-whiteColor pt-6 pr-12 pb-6 pl-12 color-brandingPrimaryColor button mt-4'} btntext={"Export CSV"} />
                </div>
            </div>
            <div className="mt-24">
                <div className="row mb-12 mr-0 ml-0">
                    <div className="col fw-5 fs-12 color-textFieldLabelColor">Full Name</div>
                    <div className="col fw-5 fs-12 color-textFieldLabelColor">Email Address</div>
                    <div className="col fw-5 fs-12 color-textFieldLabelColor text-align-right">Loyalty Points</div>
                    <div className="col fw-5 fs-12 color-textFieldLabelColor text-align-right">Redemption Points</div>
                    <div className="col fw-5 fs-12 color-textFieldLabelColor text-align-center">Status</div>
                </div>
                <div className="bar "/>
                <div className="scroll">
                    {
                        result.map((value,index)=>(
                            <>
                            {index !== 0 && <div className="bar " />}
                            <div className="row mt-12 mb-12 mr-0 ml-0">
                                <div className="col fw-4 fs-12 color-textFieldTextActiveColor">{value.name}</div>
                                <div className="col fw-4 fs-12 color-textFieldTextActiveColor">{value.email}</div>
                                <div className="col fw-4 fs-12 color-textFieldTextActiveColor text-align-right">{value.loyalty} points</div>
                                <div className="col fw-4 fs-12 color-textFieldTextActiveColor text-align-right">{value.redemption} points</div>
                                <div className="col fw-5 fs-12 d-flex justify-content-center "><div className={value.status === 'Active' ? " color-green2 p-4" : "bg-color-buttonTertiaryColor color-textFieldPlaceHolderColor p-4 width-fit-content"}>{value.status}</div></div>
                            </div>
                            </>
                        ))
                    }
                </div>
            </div>
            </>
            
            :

            <div className="color-c000000 fw-5 fs-20 subtitle1">
               Customer Details not found
            </div>

            }
        </div>
    )
}

const PaymentModal = ({selectedBrand, setShow, show, state, setState, GetBrands}) => {
    const agency = useContext(AgencyContext)
    const [discounted, setDiscounted] = useState()
    const [loader, setLoader] = useState(false)
    const handleCouponCode = async(e, coupon) => {
        setState({...state, coupon : e.target.value})
        const {response, error} = await AgencyService.StripeCoupon({query: {couponCode: coupon ? coupon : e.target.value}})
        response && response.data && response.data.length>0 && console.log('blablabla',parseInt((response.data[0].couponData.amount_off.toString()).slice(0, -2)));
        response && response.data && response.data.length>0 && setDiscounted(parseInt((response.data[0].couponData.amount_off.toString()).slice(0, -2)))
        error && setDiscounted()
    }

    return(
        <div>
            {console.log(state.coupon)}
            <div className="fw-5 fs-22 color-c000000">
                Your Payment
            </div>
            <div className="mt-24 d-flex justify-content-center">
                <div className="fs-14 fw-5 color-c000000 mr-16 middle">
                    Connect Brand:
                </div>
                <div className="d-flex">
                    <img src={selectedBrand.brandLogo} className="borderRadius-50 border" height="40px" width="40px" alt="" />
                    <div className="fs-14 color-c000000 ml-8 fw-5 middle">
                         {selectedBrand.brandName}
                    </div>
                </div>
            </div>
            <div className="mt-24 pl-16 pr-16 pt-16 pb-16 bg-color-buttonTertiaryColor w-100 borderRadius-6">
                <div className="d-flex space-between">
                    <div className="fs-16 color-c000000 fw-5">
                        Ulitmate Agency
                    </div>
                    <div className="d-flex">
                        {discounted && <div className="fs-16 middle color-c000000 fw-5">
                            {"$"+(99-discounted)}
                        </div>}
                        <div className={`ml-8 fs-14 middle color-paragraphInputColor ${discounted && 'text-decoration-line'}`}>
                            $99
                        </div>
                        <div className="color-c000000 fs-12 middle ml-8">
                            /per brand
                        </div>
                    </div>
                </div>
                <div className="bar mt-24 mb-24"></div>
                <div className="d-flex space-between">
                    <div className="fs-16 color-c000000 fw-5">
                        Start Billing Date
                    </div>
                    <div className="fs-16 color-c000000 fw-5">
                        Jan 4, 2022
                    </div>
                </div>
            </div>
            <div className="d-flex space-between mt-24">
                <div className="color-themeColor fs-14 fw-5 middle">
                    Add Discount Code
                </div>
                <div>
                    <CustomTextField 
                        value    = {state.coupon}
                        onChange = {handleCouponCode}
                    />
                </div>
            </div>
            <div className="mt-32 d-flex justify-flex-end">
                <div className="mr-16">
                    <CustomButton 
                        btntext         = "Cancel"
                        onClick         = {()=>setShow({...show, paymentModal : false})}
                        height          = {36}
                        color           = {ColorSchemeCode.c000000}
                        backgroundColor = {ColorSchemeCode.cF1F1F1}
                        hover           = {ColorSchemeCode.cF1F1F1}
                    />
                </div>
                <div>
                    <CustomButton 
                        btntext  = "Confirm Payment"
                        icon     = {loader && <CircularProgress size={10} color={'inherit'}/>}
                        position = "start"
                        disabled = {loader}
                        onClick  = {async()=>{
                            setLoader(true)
                            // setProcessing({id : brand._id, show : true})
                            const payload = {
                                // agencyId : agency._id,
                                myBrand  : selectedBrand.myBrand,
                                _id      : agency._id,
                                brandId  : selectedBrand._id,
                                planName : Agency.Plans.BRAND_CONNECTION,
                                email    : agency.email,
                                version  : 1,
                                couponId : state.coupon
                            }

                            // const {error, response} = await AgencyService.ConnectBrand({toaster: true, payload})
                            const {error, response} = await AgencyService.WhiteLabelBrandConnection({toaster: true, payload})

                            if(response){
                                selectedBrand.agency = {
                                    id           : agency.id,
                                    subscribed   : true,
                                    subscribedAt : Date.now()
                                }
                                
                                const myBrands = await localForage.getItem('myBrands')
                                myBrands.map((element, idx)=>{
                                    if(element._id === selectedBrand._id) element.agency = selectedBrand.agency
                                })
                                localForage.setItem('myBrands', myBrands)
                                
                                const otherBrands = await localForage.getItem('otherBrands')
                                otherBrands.map((element, idx)=>{
                                    if(element._id === selectedBrand._id) element.agency = selectedBrand.agency
                                })
                                localForage.setItem('otherBrands', otherBrands)
                                
                                setState({...state})
                            }
                            await GetBrands(true)
                            setLoader(false)
                            setShow({...show, paymentModal : false})
                            
                            // setProcessing({id : '', show : false})
                        }}
                            // setState({...state, paymentModal : false})}
                        height          = {36}
                    />
                </div>
            </div>
        </div>

    )
}