import React from 'react'

export default function NormalDropDown({options}) {
    return (
        <div className='normalDropdown z-index-1000'>
            {
                options.map((option, idx)=>(
                    <div className='pl-16 pt-5 pb-5 option' onClick={option.function}>
                        {option.name}
                    </div>
                ))
            }
        </div>
    )
}
