import { Environment } from '../enums/enums'

const WALLETLY_DEV_DOMAIN           = 'v2dev.walletly.ai'
const WALLETLY_PROD_DOMAIN          = 'api.walletly.ai'
const WALLETLY_DEV_BUSINESS_DOMAIN  = 'v2devbusiness.walletly.ai'
const WALLETLY_LIVE_BUSINESS_DOMAIN = 'business.walletly.ai'


const env = {
    API_URL            : 'https://'+WALLETLY_DEV_DOMAIN+'/api',
    GOOGLE_MAP_API     : 'AIzaSyBIUUEUoLYKBnVKGvVjLchBzdMR-CUa5A4',
    // STRIPE_PUBLISH_KEY : 'pk_test_51HSLKJBu7JlObFpah5BFCSrz5ifCtntMQ8kRKeqpcqyk3rkCrmWTaeGeEGXslBVAOFuLir72424Y7dAQL3E3uIpR005JbCAkow',
    // STRIPE_CLIENT_ID   : 'ca_K7IufPk01DRkJ0Eo9WrcKmiJmvufCH7a',                                                                           // Practice
    STRIPE_PUBLISH_KEY : 'pk_test_51KEu4TAaQTKpqDfVSOxQL2yZmCys5JpWN7pL1wY4hyCWbHE6WyFf22WuzYG48SNfNYO7t63Je7ak95dNmLBk7yhm00jHxqPEmw',
    STRIPE_CLIENT_ID   : 'ca_Kujv2apXjqX7VnXjbGEm9pyhuXSpq7np',                                                                           // Tesing Development
    ENVIRONMENT        : process.env.REACT_APP_ENV,
    WALLETLY_DOMAIN    : '',
    STRIPE_REDIRECT_URI: '',
    WALLETLY_API_DOMAIN: '',
    WALLETLY_IP_ADDRESS: '',
    DEFAULT_DOMAIN     : ''
}

if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){
    env.API_URL             = 'http://localhost:8080/api'
    env.GOOGLE_MAP_API      = 'AIzaSyBIUUEUoLYKBnVKGvVjLchBzdMR-CUa5A4'
    env.STRIPE_PUBLISH_KEY  = 'pk_test_51KEu4TAaQTKpqDfVSOxQL2yZmCys5JpWN7pL1wY4hyCWbHE6WyFf22WuzYG48SNfNYO7t63Je7ak95dNmLBk7yhm00jHxqPEmw'
    env.WALLETLY_DOMAIN     = 'v2dev.walletly.ai'
    env.DEFAULT_DOMAIN      = 'devagency.walletly.ai'
    env.STRIPE_REDIRECT_URI = 'http://localhost:3000/stripe/callback'
}

if(process.env.REACT_APP_ENV === Environment.STAGING){
    env.WALLETLY_DOMAIN     = 'v2dev.walletly.ai'
    env.STRIPE_REDIRECT_URI = 'https://v2devbusiness.walletly.ai/stripe/callback'

    if(window.location.hostname !== WALLETLY_DEV_BUSINESS_DOMAIN && window.location.hostname !== 'localhost')
    env.API_URL             = 'https://' + window.location.hostname + '/api'
    // env.WALLETLY_IP_ADDRESS = '54.79.97.218'
    env.WALLETLY_IP_ADDRESS = '54.79.31.229'
    env.DEFAULT_DOMAIN      = 'devagency.walletly.ai'
    env.WALLETLY_API_DOMAIN = 'v2dev.walletly.ai'
}

if(process.env.REACT_APP_ENV === Environment.PRODUCTION){
    // env.API_URL             = 'https://'+WALLETLY_PROD_DOMAIN+'/api'
    //  env.API_URL             = 'https://jumperswithnumbers.com/api'
    //env.WALLETLY_DOMAIN     = 'api.walletly.ai'
    // env.DEFAULT_DOMAIN      = 'business.jumperswithnumbers.com'
    // env.GOOGLE_MAP_API      = 'AIzaSyBIUUEUoLYKBnVKGvVjLchBzdMR-CUa5A4'
    // env.STRIPE_PUBLISH_KEY  = 'pk_live_51JT5FFJMg7n62qtJeHEfRaJyWhTCvXoBsPWqm7rO982yio0h5Ezo4XVfO9262zYl5O6imSRA5MeO3upxEWdrKYVc00ZLCGGAlr'
    // env.STRIPE_REDIRECT_URI = 'https://business.jumperswithnumbers.com/stripe/callback'
    // env.STRIPE_CLIENT_ID    = 'ca_KK1guMzStfikLj4SZriFxtiFN0BzAxx8'                                                                          // live V2 walletly
    // if(window.location.hostname !== WALLETLY_LIVE_BUSINESS_DOMAIN && window.location.hostname !== 'localhost') 
    // env.API_URL             = 'https://' + window.location.hostname + '/api'
    // env.WALLETLY_IP_ADDRESS = '54.206.31.204'
    // env.WALLETLY_API_DOMAIN = 'business.jumperswithnumbers.com'
    // env.WALLETLY_IP_ADDRESS = '54.252.237.52'


        env.API_URL             = 'https://'+WALLETLY_PROD_DOMAIN+'/api'
        // env.API_URL             = 'https://jumperswithnumbers.com/api'
        // env.API_URL             = 'https://businessconnector.app/api'
        env.WALLETLY_DOMAIN     = 'api.walletly.ai'
        env.DEFAULT_DOMAIN      = 'agency.walletly.ai'
        env.GOOGLE_MAP_API      = 'AIzaSyBIUUEUoLYKBnVKGvVjLchBzdMR-CUa5A4'
        env.STRIPE_PUBLISH_KEY  = 'pk_live_51JT5FFJMg7n62qtJeHEfRaJyWhTCvXoBsPWqm7rO982yio0h5Ezo4XVfO9262zYl5O6imSRA5MeO3upxEWdrKYVc00ZLCGGAlr'
        env.STRIPE_REDIRECT_URI = 'https://business.walletly.ai/stripe/callback'
        env.STRIPE_CLIENT_ID    = 'ca_KK1guMzStfikLj4SZriFxtiFN0BzAxx8'                                                                          // live V2 walletly
        if(window.location.hostname !== WALLETLY_LIVE_BUSINESS_DOMAIN && window.location.hostname !== 'localhost') 
        env.API_URL             = 'https://' + window.location.hostname + '/api'
        env.WALLETLY_IP_ADDRESS = '54.66.160.20'
        // env.WALLETLY_IP_ADDRESS = '54.206.31.204'
        env.WALLETLY_API_DOMAIN = 'api.walletly.ai'
        // env.WALLETLY_IP_ADDRESS = '54.79.31.229'
    
        
    // }
    
}

export default env
