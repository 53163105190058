import React,{useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom';

import PartialSideBar from './PartialSideBar'
import SidebarSub from './SidebarSub';
import DashboardChild from './sidebarSubChilds/DashboardChild';
import SettingChild from './sidebarSubChilds/SettingSubChild';
import ProfileSettingChild from './sidebarSubChilds/ProfileSettingChild';

import SvgIcons from '../../icons/svg.icon';

export default function SidebarNav({show, setShow, mobileCheck, setMobileCheck}) {

    const history = useHistory();

    const [subMenu, setSubMenu] = useState();
    const [itemsSub, setItemsSub] = useState([]);
    const [MainItem, setMainItem] = useState();

    const items = [
        {
            link        :   '/dashboard',
            logo        :   <SvgIcons.NavDashBoardIcon/>,
            className   :   'items mt-16 middle',
            name        :   'Dashboard',
            subMenu     :   true,
            children    :   ''
        },
        {
            link        :   '/audience',
            logo        :   <SvgIcons.NavAudienceIcon/>,
            className   :   'items  mt-16 middle',
            subMenu     :   true,
            name        :   'Audience',
            children    :   ''
        },
        {
            link        :   '/setting',
            logo        :   <SvgIcons.NavSettingIcon/>,
            className   :   'items  mt-16 middle',
            subMenu     :   true,
            name        :   'Settings',
            children    :   <SettingChild mobileCheck= {mobileCheck} setMobileCheck={setMobileCheck}/>
        },
    ]

    const onLoad = () => {
        items.map((element,idx)=>
        ((history.location.pathname.includes(element.link)) && setItemsSub(element.children))
        )
    }

    useEffect(onLoad, [])

    const handleClick = () => {
        setMobileCheck(!mobileCheck);
        setShow(false);
    }

    return (
        <div className={"d-flex flex-row " + (!show ? 'handleSiderBarShadow' : '')} id="sidebar" >
                <div className={(mobileCheck) ? 'partailSideNav' : 'hidePartial partailSideNav z-index-2'}>
                    <PartialSideBar 
                        setSubMenu            = {setSubMenu}
                        show                  = {show}
                        setShow               = {setShow}
                        setItemsSub           = {setItemsSub}
                        items                 = {items}
                        setMainItem           = {setMainItem}
                    />
                </div>
            <div className="tooglecircle" onClick={handleClick}>

            </div>
            {  
                (window.screen.availWidth < 767) ?
                    <div className={(mobileCheck) ? 'sideBarMain' : 'hideSubNav'}> 
                        <div className='bar'></div>
                        <SidebarSub 
                            items          = {(history.location.pathname.includes('profile')) ? <ProfileSettingChild/> : itemsSub}
                            mobileCheck    = {mobileCheck}
                            setMobileCheck = {setMobileCheck}
                            MainItem       = {MainItem}
                        />
                    </div>
                :
                    <div className={(show) ? 'sideBarMain' : 'hideSubNav'}> 
                        <div className='bar'></div>
                        <SidebarSub 
                            items          = {(history.location.pathname.includes('profile')) ? <ProfileSettingChild/> : itemsSub}
                            mobileCheck    = {mobileCheck}
                            setMobileCheck = {setMobileCheck}
                            MainItem       = {MainItem}
                        />
                    </div>
            }
        </div>
    )
}
