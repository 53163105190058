import React, {useEffect, useState} from 'react';
import Fade from 'react-reveal/Fade';

import {SvgIcons} from '../../icons';

import CustomTextField from '../CustomTextField';
import CustomButton from '../CustomButton';
import { ColorScheme } from '../../enums';
import Autocomplete from '../googleMap/Autocomplete';
import CustomSelect from '../CustomSelect';
import { Options, utils } from '../../utils';
import { CircularProgress } from '@material-ui/core';
import { ColorSchemeCode } from '../../enums/ColorScheme';


export default function LocationScreen({formik, setActivePage, index, loader, apiError,}){

    const [show,setShow]=useState(false)
    const [pageStatus, setPageStatus] = useState('')
    const [location, setLocation] = useState({
        lat         : '',
        lng         : '',
        address     : '',
        country     : '',
        city        : '',
        countryCode : '',
        state       : '',
        offset      : ''
    })

    useEffect(()=> setShow(true), [])

    const locationUpdate = () => setLocation(formik.values.location)
    useEffect(locationUpdate, [formik.values.location])

    const nextScreen = () =>{  
        formik.setValues({...formik.values, location: location}) 
        setShow(false);
        setPageStatus('next');
        setTimeout(()=>{setActivePage(index,'next')},500)
    }

    const prevScreen = () =>{
        setShow(false);
        setPageStatus('prev');
        setTimeout(()=>{setActivePage(index,'prev')},500)
    }

    const onAddressChange = (event) => {
        setLocation({...location, address: event.target.value})
        formik.setValues({...formik.values, location: {...formik.values.location, address: event.target.value}})
    }
    const onCityChange = (event) => {
        setLocation({...location, city: event.target.value})
        formik.setValues({...formik.values, location: {...formik.values.location, city: event.target.value}})
    }
    const onCountryChange = (event) => {
        setLocation({...location, country: event.target.value})
        formik.setValues({...formik.values, location: {...formik.values.location, country: event.target.value}})
    }
   
    const locationSummary = (location) => {
        const currency = utils.getCurrency({location: location})
        console.log('currency',currency);
        const selectedTimezone = utils.getTimezone({location: location})
        console.log('selectedTimezone',selectedTimezone);
        setTimezone(selectedTimezone)
        location.offset = selectedTimezone.utcOffset
        // formik.setValues({...formik.values, currency})
        formik.setValues({...formik.values, location, currency})
        setLocation(location)
    }

    const [timezone, setTimezone] = useState('')

    const onCurrencyChange = (e) => {
        const currency = utils.getCurrency({currencyCode: e.target.value})
        console.log('currency1',e.target.value);
        console.log('currency2',currency);
        formik.setValues({...formik.values, currency})
    }

    const onTimezoneChange = (e) => {
        const selectedTimezone = utils.getTimezone({timezoneName: e.target.value})
        setTimezone(selectedTimezone.name)
        const location = formik.values.location
        location.offset = selectedTimezone.utcOffset
        formik.setValues({...formik.values, location: location})
    }

    return(
        <div id="locationScreen" className='mobileCol'>
            {console.log(formik)}
            <Fade  bottom={ (pageStatus=== 'next') ? true : false} top={(pageStatus=== 'prev') ? true : false} duration={500} opposite when={show}>
                <div className='row'>
                    <div className='col-md-5 col-12 offset-md-1'>
                        <div className="row">
                            <div className="question mt-108">
                                {/* <span onClick={prevScreen} style={{cursor: 'pointer'}}>
                                    <SvgIcons.BackIcon/>
                                </span>  */}
                                What’s your agency location?
                            </div>

                            <div className="col-12 mt-40">
                                <Autocomplete 
                                    className       = "customInputField"
                                    variant         = "standard"
                                    fontSize        = "25px"
                                    label           = "Address"
                                    value           = {location.address}
                                    onChange        = {onAddressChange}
                                    InputProps      = {{ disableUnderline: false, style: { fontSize: 16,color: '#1D3B56'} }}
                                    locationSummary = {locationSummary}
                                />
                                <div className="error fs-12">{
                                    location.address && (location.lat==='' || location.lng==='' || !formik.values.location.lat || !formik.values.location.lng) && "Please type your address and select from dropdown"
                                }</div>
                            </div>

                            <div className="col-6 mt-20">
                                <CustomTextField 
                                    className  = "customInputField"
                                    label      = "City"
                                    value      = {location.city}
                                    onChange   = {onCityChange}
                                    variant    = "standard"
                                    InputProps = {{ disableUnderline: false, style: {  fontSize: 16,color: '#1D3B56'} }}
                                />
                            </div>

                            <div className="col-6 mt-20">
                                <CustomTextField 
                                    className  = "customInputField"
                                    label      = "Country"
                                    value      = {location.country}
                                    onChange   = {onCountryChange}
                                    variant    = "standard"
                                    InputProps = {{ disableUnderline: false, style: {  fontSize: 16,color: '#1D3B56'} }}
                                />
                            </div>

                            <div className="col-12 mt-40">
                                <CustomSelect 
                                    className  = "w-100"
                                    options    = {Options.getCurrencyCodes()}
                                    label      = "Currency"
                                    value      = {formik.values.currency ? formik.values.currency.code : ''}
                                    onChange   = {onCurrencyChange}
                                />
                            </div>

                            <div className="col-12 mt-40">
                                <CustomSelect 
                                    className  = "w-100"
                                    options    = {Options.getTimezones()}
                                    label      = "Timezone Offset"
                                    value      = {timezone}
                                    onChange   = {onTimezoneChange}
                                />
                            </div>
                        </div>

                    <div className="d-flex space-between mt-24">
                        <span className="buttonBox mr-16">
                            <CustomButton 
                                className       = "fs-14 fw-5"
                                height          = '36px'
                                btntext         = "Back"
                                onClick         = {prevScreen}
                                backgroundColor = {ColorSchemeCode.cE0E0E0}
                                hover           = {ColorSchemeCode.cE0E0E0}
                                color           = {ColorSchemeCode.c000000}
                            />
                        </span>
                        <CustomButton 
                            borderRadius    = {4}
                            height          = {36}
                            // onClick         = {nextScreen}
                            icon            = {loader && <CircularProgress size={20} color={'inherit'}/>}
                            btntext         = "Finish"
                            type            = "submit"
                            className       = 'headingH4'
                            // btntext         = "Next"
                            color           = {ColorScheme.ColorSchemeCode.cffffff}
                            backgroundColor = {ColorScheme.ColorSchemeCode.themeColor}
                            disabled        = {
                                                loader || !formik.isValid ||
                                                !location.address ||
                                                !location.city ||
                                                !location.country
                                              }
                        />
                    </div>
                        <div className="error">{apiError}</div>

                    </div>
                </div>
            </Fade>
        </div>
    )


}