import React, { useContext } from 'react'
import { useHistory } from 'react-router';

import { useFormik } from 'formik'
import * as localForage from 'localforage'

import { AuthVld } from '../../validation';
import { UserService } from '../../services'

import SignupHeader from '../../components/SignupHeader'
import StepTwoSignup from '../../components/auth/signup/StepTwoSignup'
import { UserContext } from '../../context/User.context';

export default function UserOnboarding({updateUser}) {

    const userContext = useContext(UserContext)

    const history = useHistory()
    const [state, setState] = React.useState({
        page     : 1,
        loader   : false,
        apiError : ''
    })

    const initState = {
        dialingCode        : '',
        phone              : '',
        jobDescription     : '',
        companyStrength    : '',
        experience         : '',
        walletlyReferences : []
    }

    const handleSubmit = async () => {
        setState({...state, loader: true})
        
        const localUser = await localForage.getItem('user')
        const { error, response } = await UserService.Update({payload: {...formik.values, _id: localUser._id}})
        
        setState({...state, loader: false})
        
        if(error) return setState({...state, apiError: error.error})
        const user = await UserService.Get()
        if(user.response) await localForage.setItem('user', user.response.data)
        userContext.updateUser(user.response.data)
        
        history.push('/agency')
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : AuthVld.OnBoard,
        isInitialValid   : false
    })

    return (
        <div>
            <SignupHeader/>
            <div id="UserOnboard">
                <div className="text-center"> 
                    <div className="container mb-100">
                        <div className='row' id="cardEnclosed">
                            <div className="col-12 card">
                                <form onSubmit={formik.handleSubmit}>
                                    
                                        <StepTwoSignup 
                                            state    = {state}
                                            setState = {setState}
                                            formik   = {formik}
                                        />
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
