import React,{useState} from 'react'

import {Link} from 'react-router-dom';


// import SvgIcons from '../../../icons/svg.icon'

export default function SettingSubChild({mobileCheck, setMobileCheck}) {

    const [selected, setSelected] = useState('');
 
    const items  =   [
            {
                icon            :   '',  
                text            :   'General Information',
                link            :   'general',
            },
            // {
            //     icon            :   '',   
            //     text            :   'Look & Feel',
            //     link            :   'lookandfeel',
            // },
            // {
            //     icon            :   '',   
            //     text            :   'Languages',
            //     link            :   'languages',
            // },
            // {
            //     icon            :   '',   
            //     text            :   'Custom Plans',
            //     link            :   'plans',
            // },
            // {
            //     icon            :   '',   
            //     text            :   'Payment Services',
            //     link            :   'services',
            // },
            {
                icon            :   '',   
                text            :   'Domain',
                link            :   'domain',
            },
            // {
            //     icon            :   '',   
            //     text            :   'Integration',
            //     link            :   'integration',
            // },
            // {
            //     icon            :   '',   
            //     text            :   'Invite',
            //     link            :   'invite',
            // },
            // {
            //     text            :   'Billing',
            //     link            :   'billing',
            // },
        ]   

    const handleClick = (element) => {
        setMobileCheck(false);
        setSelected(element.text);
    }   

    React.useEffect(() => {
        if(window.location.pathname.includes('/setting/domain')) {
            setSelected('Domain')
        }
        else{
            setSelected('General Information')
        }
    }, [])

    return (
        <div>
            <div class="headingH3 sidebarHeading">Settings</div>
                <div className="items mt-16">
                    {
                        items.map((element, idx)=>(
                            <div> 
                                <Link to={element.link} onClick={()=>handleClick(element)}>
                                    <div className={(element.text === selected)? 'active  item': 'color-navigationUnSelected item'}>
                                        {element.icon}
                                        <span className="navLinks">{element.text}</span>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            
        </div>
    )
}
