import React from 'react'
import { ColorSchemeCode } from '../enums/ColorScheme'
import CustomButton from './CustomButton'

export default function EmptyScreen({title, btnTitle, btnClick, otherTitle, otherCaption}) {
    return (
        <div id="EmptyScreen" className="w-100 mt-100">
            <div className="middle">
                <div className="col-7 text-center">
                    <div className="headingH3 color-c000000">
                        {title && `No ${title} Found`} 
                        {otherTitle && otherTitle} 
                    </div>
                    <div className="mt-12 subtitle2 color-c828282">
                        {otherCaption ? otherCaption : `You don’t have any ${title}. Click 'Add ${title}' to manually add ${title}.`}
                    </div>
                    <div className="mt-32">
                        <CustomButton 
                            btntext      = {btnTitle}
                            border       = {'1px solid ' + ColorSchemeCode.widgetEditorBorder}
                            borderRadius = {'4px'}
                            onClick      = {btnClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
