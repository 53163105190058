
import React,{useState, useEffect} from 'react'

import SignupHeader from '../../components/SignupHeader';
import { useFormik } from 'formik'
import PlanSettingScreen from '../../components/auth/signup/PlanSettingScreen'
import { AgencyService } from '../../services'
import * as localForage from 'localforage'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";

import env from '../../config/index'

const stripePromise = loadStripe(env.STRIPE_PUBLISH_KEY)

export default function Plans(props) {

    props.setTitleFunc && props.setTitleFunc("Plans")

    return (
        <>
            {props.location && props.location.pathname.includes('selectplan') && <SignupHeader/>}
            <div id='Auth'>
                <div className="text-center"> 
                    <div className="container ">
                        <Elements stripe={stripePromise}>   
                            <PlanSettingScreen {...props}/>
                        </Elements>
                    </div>
                </div>
             </div>
        </>
    )
}
