import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/utils'

const UploadImage = async ({payload, desiredPath}) => await axios.post(env.API_URL+service+'/upload/image?desiredPath='+desiredPath, payload, {headers: await MainService.getTokenHeader()})

const GetInvoices = async({query}) => await axios.get(env.API_URL+service+'/subscription/invoices?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const BrandService = {
    UploadImage    : Handlers.Services(UploadImage),
    GetInvoices    : Handlers.Services(GetInvoices),
}

export default BrandService
