import React, { useContext, useEffect, useState } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress';

import PngIcons from '../../../icons/png.icon';
import CustomButton from '../../../components/CustomButton';
import CustomField from '../../../components/CustomTextField';
import CustomTab from '../../../components/CustomTab';
import PageTitle from '../../../components/setting/SettingPageTitles';
import CustomTextField from '../../../components/CustomTextField';
import { SvgIcons } from '../../../icons';

export default function Integration({setTitleFunc, setBtn}) {

    setTitleFunc('Integration');
   
    const buttonFunc = () => {
        return(
            <></>
        )
    }

    useEffect(() => {
        setBtn(buttonFunc)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    

    const labels = [
        'EmailServices',
    ]

    const components = [
        <EmailServices/>
    ]

    return (
        <div className='w-60' id="integration">
            <div className="heading3 color-blackColor ">Integrations</div>
                <div className="col-12">
                        <div className="tabs mt-24">
                                <CustomTab labels={labels} components={components}/>
                        </div>

                </div>
        </div>
    )
}




const EmailServices = () => {

    const Email = [
        {
            title : 'Email',
            icon  : PngIcons.AudienceIcons.Email,
            input : [
                     {
                         placeholder : 'Enter you email',
                         label      : 'Verified Email',
                     }
                ],
        }
     ]
 
    
    return(
        <div className="Commerce">
                        <div className="col-md-7 col-12">
                            <SingleIntegrationBox name={Email}/>
                        </div>
        </div>
    )

}



const SingleIntegrationBox = (props) => {
    return(
       <div className="singleIntegration">
        {props.name.map((integration, index) => 
            <div className="row mt-20" key={index}>
                    <div className="col-12 col-md-12 d-flex pl-0">
                            <div className="fs-24 text-left headingH2 color-c4F4F4F pl-0">{integration.title}</div>
                    </div>
                    {
                        integration.input.map((app)=> 
                        <div className="col-12 col-md-12 mt-10 pl-0 pr-0">
                            <CustomTextField
                                className   = "w-100"
                                label       = {app.label}
                                placeholder = {app.placeholder}
                                value       = {app.value}
                                icon        = {<SvgIcons.Email/>}
                                position    = "start"
                                name        = {app.name}
                                onChange    = {app.onChange}
                                inputProps  = {{ onFocus: app.onFocus }}
                                error       = {app.error}
                                helperText  = {app.helperText}
                            />
                        </div>      
                        )
                    }
                    <div className="col-12 col-md-12 text-right mt-10 pl-0 pr-0 pt-0 pd-0">
                        <CustomButton  
                            btntext      = "Connect"
                            type         = "submit"
                            disabled     = {integration.disabled}
                            icon         = {integration.loader && <CircularProgress size={20} color={'inherit'}/>}
                        />
                    </div>

                </div>)}
        </div>
    )

}