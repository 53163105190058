import React, {useEffect, useState} from 'react';
import Fade from 'react-reveal/Fade';

import { Options, utils } from '../../utils'
import { Currencies } from '../../enums'
import {SvgIcons} from '../../icons';

import CustomInput from '../CustomTextField';
import CustomButton from '../CustomButton';
import { ColorScheme } from '../../enums';
import CustomSelect from '../CustomSelect';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function CurrencyScreen({setActivePage, formik, index, loader, apiError, ...props}){

    const [show,setShow]=useState(false);
    const [pageStatus, setPageStatus] = useState('')
    const [timezone, setTimezone] = useState('')

    const onLoad = () => {
        setShow(true)
        console.log('formik.values.location', formik.values.location);
        const currency = utils.getCurrency({location: formik.values.location})
        console.log('currency',currency);
        const selectedTimezone = utils.getTimezone({location: formik.values.location})
        setTimezone(selectedTimezone)
        formik.setValues({...formik.values, currency})

    }

    useEffect(onLoad, [])


    const prevScreen = () =>{
        setShow(false);
        setTimeout(()=>{setActivePage(index,'prev')},500)
    }

    const onCurrencyChange = (e) => {
        const currency = utils.getCurrency({currencyCode: e.target.value})
        formik.setValues({...formik.values, currency})
    }

    const onTimezoneChange = (e) => {
        const selectedTimezone = utils.getTimezone({timezoneName: e.target.value})
        setTimezone(selectedTimezone.name)
        const location = formik.values.location
        location.offset = selectedTimezone.utcOffset
        formik.setValues({...formik.values, location: location})
    }

    return(

         <div id="locationScreen" className='mobileCol'>
             {console.log(formik)}
            <Fade  bottom={ (pageStatus=== 'next') ? true : false} top={(pageStatus=== 'prev') ? true : false} duration={500} opposite when={show}>
                <div className='row'>
                    <div className='col-md-5 col-12 offset-md-1'>

                    <div className="row">

                        <div className="question mt-108">
                            <span onClick={prevScreen} style={{cursor: 'pointer'}}><SvgIcons.BackIcon/></span>
                            What’s your agency preferance?
                        </div>

                        <div className="col-12 mt-40">
                            <CustomSelect 
                                className  = "w-100"
                                options    = {Options.getCurrencyCodes()}
                                label      = "Currency"
                                value      = {formik.values.currency ? formik.values.currency.code : ''}
                                onChange   = {onCurrencyChange}
                            />
                        </div>

                        <div className="col-12 mt-40">
                            <CustomSelect 
                                className  = "w-100"
                                options    = {Options.getTimezones()}
                                label      = "Timezone Offset"
                                value      = {timezone}
                                onChange   = {onTimezoneChange}
                            />
                        </div>
                            
                    </div>
                        <CustomButton 
                            borderRadius    = {12}
                            height          = {45}
                            className       = 'headingH4 mt-24 btn-next'
                            disabled        = {loader || !formik.isValid}
                            icon            = {loader && <CircularProgress size={20} color={'inherit'}/>}
                            btntext         = "Finish"
                            type            = "submit"
                            color           = {ColorScheme.ColorSchemeCode.cffffff}
                            backgroundColor = {ColorScheme.ColorSchemeCode.themeColor}
                        />
                        <div className="error">{apiError}</div>
                    </div>
                </div>
            </Fade>
        
        </div>


    )


}