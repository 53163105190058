import * as yup from 'yup';
import { Campaign, enums, PassDesign } from '../enums'

const REQ_ERROR = 'This field is required'

const PreBuiltPassDesign = yup.object({
    designName : yup.string().required(REQ_ERROR),
    passType   : yup.string().required(REQ_ERROR)
})

const applePassFields = yup.object({
    key           : yup.string(),
    label         : yup.string(),
    value         : yup.string(),
    changeMessage : yup.string(),
    textAlignment : yup.string(),
    numberStyle   : yup.string(),
    timeStyle     : yup.string(),
    dateStyle     : yup.string(),
    currencyCode  : yup.string()
})

const appleRelevanceLocation = yup.object({
    latitude     : yup.number(),
    longitude    : yup.number(),
    relevantText : yup.string()
})

const appleRelevanceBeacons = yup.object({
    proximityUUID : yup.string(),
    relevantText  : yup.string()
})

const apple = yup.object({
    passModel       : yup.string().required().oneOf(Object.values(PassDesign.ApplePassModels)),
    logoText        : yup.string().required(),
    headerFields    : yup.array().of(applePassFields),
    primaryFields   : yup.array().of(applePassFields),
    secondaryFields : yup.array().of(applePassFields),
    auxiliaryFields : yup.array().of(applePassFields),
    backFields      : yup.array().of(applePassFields),
    locations       : yup.array().of(appleRelevanceLocation),
    beacons         : yup.array().of(appleRelevanceBeacons)
})

const updateDesign = yup.object({
    designName      : yup.string().required(REQ_ERROR),
    backgroundColor : yup.string().required(),
    fontColor       : yup.string().required(),
    qrcode          : yup.boolean().required(),
    apple           : apple.required(),
    logo            : yup.string(),
    logoFile   : yup.mixed()
                    .test('imageSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('logo',{
                        is   : value => !value,
                        then : yup.mixed().required(REQ_ERROR)
                    }),
    cover           : yup.string().required(),
    coverFile       : yup.mixed()
                    .test('imageSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('cover',{
                        is   : value => !value,
                        then : yup.mixed().required(REQ_ERROR)
                    }),
})

export {
    PreBuiltPassDesign,
    updateDesign
}