import React, { useState } from 'react'

import ReactTooltip from 'react-tooltip';


import { SvgIcons } from '../icons'

function CopyText({content, tooltip}) {

    const [tooltipText, setTooltipText] = useState('copy')
    
    const handleCopy = () => {
        const element = document.getElementById('content')
        const r = document.createRange()
        r.selectNode(element)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(r)
        document.execCommand('copy')
        window.getSelection().removeAllRanges()
        ReactTooltip.show(document.getElementById('tooltip'))
        setTooltipText('copied')
    }

    return (
        <div id="CopyText">
            <div className="text-wraper">
                <div id="content" className="text">{content || ''}</div>
                <div onClick={handleCopy} id="tooltip" className="cp ml-10" data-for={'tooltipCopy'} data-tip={tooltipText} ><SvgIcons.CopyIcon height={15} width={15}/></div>
            </div>
            {<ReactTooltip id="tooltipCopy" getContent={()=>tooltipText} />}
        </div>
    )
}

export default CopyText
