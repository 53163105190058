import { useFormik } from 'formik'
import React from 'react'
import CustomButton from '../../../components/CustomButton'
import CustomTextField from '../../../components/CustomTextField'
import { SvgIcons } from '../../../icons'
import { PlanService } from '../../../services'
import CustomSwitch from '../../../components/CustomSwitch'

function PlanModal({plan, onClose}) {

    const initState = {
        amount   : plan.amount,
        planName : plan.planName,
        active   : plan.active
    }

    const handleSubmit = async () => {
        const payload = {
            _id      : plan._id,
            active   : formik.values.active,
            agencyId : plan.planBy
        }
        console.log('payload',   payload);
        const {error, response} = await PlanService.Update({toaster: true, payload})
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues : {...initState},
        onSubmit      : handleSubmit
    })
   
    return (
        <div id="PlanModal">
            <form onSubmit={formik.handleSubmit}>
                <div className="pb-24 heading3 color-blackColor text-capitalize">
                    {plan.planName}
                    <div className="float-right">
                        <CustomSwitch 
                            name     = "active"
                            onChange = {formik.handleChange}
                            checked  = {formik.values.active}
                        />
                    </div>
                </div>
                <div className="bar"/>
                <div className="row">
                    <div className="pr-24 col">
                        <div className=" mt-24 mb-16 buttonText color-blackColor">Edit Plan</div>
                        <div>
                            <CustomTextField
                                className   = "w-100"
                                placeholder = "Plan Name"
                                name        = "planName"
                                value       = {formik.values.planName}
                                label       = "Plan Name"
                                inputProps  = {{readOnly: true}}
                            />
                        </div>
                        <div className="mt-24">
                            <CustomTextField
                                className   = "w-100"
                                placeholder = "Plan Price"
                                label       = "Plan Price"
                                name        = "amount"
                                value       = {formik.values.amount}
                                icon        = {<SvgIcons.Dollar/>}
                                position    = "start"
                                inputProps  = {{readOnly: true}}
                            />
                        </div>
                    </div>
                    <div className="pl-16 col">
                        <div className="mt-24 mb-8 buttonText color-blackColor">Plan Detail:</div>
                        <div className="paragraph color-textFieldPlaceHolderColor">
                            <ul className="pl-16">
                                <li className="mb-8">500 Subscribers</li>
                                <li className="mb-8">Unlimited Integrations</li>
                                <li className="mb-8">Unlimited Card Templates</li>
                                <li className="mb-8">Unlimited Push Notification</li>
                                <li className="mb-8">Unlimited Geo Notification</li>
                                <li className="mb-8">Unlimited Campaigns</li>
                                <li className="mb-8">Training and Videos</li>
                            </ul>  
                        </div>
                    </div>
                </div>
                <div className="text-align-right mt-70">
                    <CustomButton className={'modal-button-cancel color-textFieldPlaceHolderColor buttonText color-brandingPrimaryColor bg-color-whiteColor'} btntext={"Cancel"} onClick={onClose}/>
                    <CustomButton type="submit" className={'modal-button color-brandingPrimaryColor bg-color-whiteColor ml-10'} btntext={"Save Changes"} />
                </div>
            </form>
        </div>
    )
}

export default PlanModal
