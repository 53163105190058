import React, { useContext } from 'react';
import {Redirect, Route} from 'react-router-dom';

import { AgencyContext } from '../context/Agency.context';
import { UserContext } from '../context/User.context';

function AuthGuard({component: Component, ...rest}){
    const user = useContext(UserContext)

    return (
        <Route
            {...rest}
            render={(props) => {
                if(!user.email) return <Component {...props} />
                // if(user.email && !user.verified) return <Redirect to='/verify' />
                else return  <Redirect to={'/'+user.activeAgency} />
            }}
        />
    )
}

function VerifyGuard({component: Component, ...rest}){
    const user = useContext(UserContext)
    return (
        <Route
            {...rest}
            render={(props) => {
                // if(user.email && !user.verified) return <Component {...props} />
                if(user.email) return <Component {...props} />
                // if(user.email && !user.walletlyReferences) return <Redirect to='/onboard' />
                if(!user.email) return  <Redirect to='/' />
                else return  <Redirect to={'/'+user.activeAgency} />
            }}
        />
    )
}

function UserOnBoardGuard({component: Component, ...rest}){
    const user = useContext(UserContext)

    return (
        <Route
            {...rest}
            render={(props) => {
                // if(user.email && !user.verified) return <Redirect to='/verify' />
                // if(user.email && !user.walletlyReferences) return <Component {...props} />
                if(!user.email) return  <Redirect to='/' />
                else return  <Redirect to={'/'+user.activeAgency} />
            }}
        />
    )
}

function CreateAgencyGuard({component: Component, ...rest}){
    const user   = useContext(UserContext)
    const agency = useContext(AgencyContext)

     return (
        <Route
            {...rest}
            render={(props) => {
                // if(user.email && !user.verified) return <Redirect to='/verify' />
                // if(user.email && !user.walletlyReferences) return <Redirect to='/onboard' />
                if(user.email && !user.activeAgency) return <Component {...props} />
                // if(user.email && agency && !agency.stripeCustomer) return <Redirect to='/selectplan' />
                if(user.email && agency && !agency.stripeCustomer) return <Redirect to={'/'+user.activeAgency} />
                else return  <Redirect to='/' />
            }}
        />
    )
}

function SubscriptionPlanGuard({component: Component, ...rest}){
    const user   = useContext(UserContext)
    const agency = useContext(AgencyContext)

    return (
       <Route
           {...rest}
           render={(props) => {
               if(!user.email) return  <Redirect to='/' />
            //    if(user.email && !user.verified) return <Redirect to='/verify' />
            //    if(user.email && !user.walletlyReferences) return <Redirect to='/onboard' />
               if(user.email && !user.activeAgency) return  <Redirect to='/agency' />
               if(agency.agencyName && (!agency.stripeCustomer || !agency.subscription)) return <Component {...props} />
               else return  <Redirect to='/' />
           }}
       />
   )
}

function CentralGuard({component: Component, ...rest}){
    const user   = useContext(UserContext)
    const agency = useContext(AgencyContext)

    return (
        <Route
            {...rest}
            render={(props) => {
                // if(user.email && !user.verified) return <Redirect to='/verify' />
                // if(user.email && !user.walletlyReferences) return <Redirect to='/onboard' />
                if(user.email && !user.activeAgency) return <Redirect to='/agency' />
                // if(agency && (!agency.stripeCustomer || !agency.subscription)) return  <Redirect to='/selectplan' />
                // if(agency && (!agency.stripeCustomer || !agency.subscription)) return  <Redirect to={'/'+user.activeAgency} />
                if(user.email) return <Component {...props} />
                else return  <Redirect to='/' />
            }}
        />
    )
}

function AgencyGuard({component: Component,path, ...rest}){
    const agency = useContext(AgencyContext)
    const user = useContext(UserContext)

    return (
        <Route
            path={path}
            render={(props) => {
                // if(user && !user.verified) return <Redirect to='/verify' />
                // if(user && !user.walletlyReferences) return <Redirect to='/onboard' />
                if(user && !user.activeAgency) return <Redirect to='/agency' />
                if(agency.error) return <Redirect to='/' />
                return <Component {...props} {...rest} />
            }}
        />
    )
}

export {
    AuthGuard,
    VerifyGuard,
    UserOnBoardGuard,
    CreateAgencyGuard,
    SubscriptionPlanGuard,
    CentralGuard,
    AgencyGuard
}