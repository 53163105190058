import env from '../config'

import * as ColorScheme from './ColorScheme'
import * as enums from './enums'
import Fields from './Fields'

import Agency from './Agency'
import User from './User'
import Brand from './Brand'
import Campaign from './Campaign'
import PassDesign from './PassDesign'

import CountryCode from './CountryCode'
import Currencies from './Currencies'

const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${env.GOOGLE_MAP_API}&v=3.exp&libraries=geometry,drawing,places`


export {
    ColorScheme,
    enums,
    Agency,
    User,
    Brand,
    Campaign,
    PassDesign,
    CountryCode,
    Currencies,
    GOOGLE_MAP_URL,
    Fields
}