const CampaignTypes = {
    PERCENT_COUPON     : 1,
    AMOUNT_COUPON      : 2,
    GIVEAWAY_COUPON    : 3,
    COMPETITION_COUPON : 4,
    LOYALTY_CARD       : 5,
    STAMP_CARD         : 6,
    MEMBERSHIP_CARD    : 7,
    EVENT_TICKET       : 8,
    WEBINAR_TICKET     : 9
}

const Activities = {
    EARNING: {
        SPENDING : 'spending',
        BIRTHDAY : 'birthday',
        VISIT    : 'visit',
        SIGNUP   : 'signup',
        CUSTOM   : 'custom'
    },
    REDEEMING: {
        AMOUNT         : 'amount',
        PRECENTAGE     : 'percentage',
        SHIPPING       : 'shipping',
        POS_AMOUNT     : 'pos_amount',
        POS_PERCENTAGE : 'pos_percentage'
    }

}

const EarningTypes = {
    INCREMENTAL : 'incremental',
    FIXED       : 'fixed'
}

const ApprovalTypes = {
    IMMEDIATELY    : 'immediately',
    MANUALLY       : 'manually',
    AFTER_DURATION : 'fixed_duration'
}

const RedeemingTypes = {
    INCREMENTAL : 'incremental',
    FIXED       : 'fixed'
}

const RedeemActivityApplies = {
    ENTIRE   : 'entire',
    SPECIFIC : 'specific'
}

const Campaign = {
    CampaignTypes,
    Activities,
    EarningTypes,
    ApprovalTypes,
    RedeemingTypes,
    RedeemActivityApplies
}

export default Campaign