import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ColorSchemeCode } from '../enums/ColorScheme';

const CustomFormControlLabel = makeStyles({
    root: {
        margin: 0
    },
    label: {
      fontSize   : '14px',                       //BodyB14R
      fontWeight : 400,                          //BodyB14R
      lineHeight : '16px',                       //BodyB14R
      color      : ColorSchemeCode.GeneralBlack
    },
})


export default function CustomRadio({value, label, styles, checked}) {
    const classes = CustomFormControlLabel(styles)
    return(
      <div id="CustomRadio">
        <FormControlLabel 
          classes = {classes}
          value   = {value}
          label   = {label}
          control = {<Radio checked={checked}/>}
        />
      </div>
    )
}