import React, { useContext, useEffect, useState } from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import SettingHeader from '../../components/setting/SettingHeader';
import ProfileSettingChild from '../../components/sidebar/sidebarSubChilds/ProfileSettingChild';
import Billing from './subpages/Billing';
import Plans from '../auth/Plans';
import ProfileSetting from './subpages/ProfileSetting';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import env from '../../config';
import localforage, * as localForage from 'localforage'

const stripePromise = loadStripe(env.STRIPE_PUBLISH_KEY)

export default function Profile(props) {
    const [state, setstate] = useState('')
    const [btn, setBtn] = useState()

    const setTitleFunc = (title) => {
        setstate(title)
    }
    
    // const setBtn = (text) => {
    //     setBtnText(text)
    // }

    const [user, setuser] = useState()
    
    useEffect(async() => {
        const data = await localforage.getItem('user')
        setuser(data)
    }, [])

    return (
        <div id="profile">
            <div className="row ml-0">
                <div className="col-12 pl-0">
                    <SettingHeader show={props.show} setShow={props.setShow} heading={state} btn={btn} />
                </div>
                <div className="component col-12 pb-0" style={{marginTop: user && (!user.verified) && '47px'}}>
                    <Switch> 
                    <Route exact path={props.match.url + '/profile'} render={()=><ProfileSetting setBtn={setBtn} setTitleFunc={setTitleFunc}/>}/>   
                   
                        <Redirect path={props.match.url+ '/*'} to={props.match.url+ '/profile'} />
                        <Redirect path={props.match.url} to={props.match.url+ '/profile'} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}


