import React, { useEffect, useState, useContext } from 'react'
import {useHistory, Link} from 'react-router-dom'; 

import CustomButton from '../../../components/CustomButton'
import CustomTextField from '../../../components/CustomTextField'
import CustomModal from '../../../components/CustomModal';

import { SvgIcons } from '../../../icons'

import { utils } from '../../../utils'
import { useFormik } from 'formik'
import { AgencyVld } from '../../../validation'
import { AgencyService, UserService } from '../../../services'

import { AgencyContext } from '../../../context/Agency.context';

import { CircularProgress } from '@material-ui/core';
import * as localForage from 'localforage'
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import env from '../../../config';


function Domain({setTitleFunc, setBtn}) {
    setTitleFunc('Domain')

    const agency = useContext(AgencyContext)
    
        const copyToClipboard = (value) => {
            navigator.clipboard.writeText(value)
            utils.showToaster({position: 'top-right', title: 'Copied', message: value, severity: 'success'})
        }

        const [state, setState] = useState({
            loader          : false,
            apiError        : '',
            showConfirmModal: false,
        })

        const history = useHistory();
    

        const initState = {
            domain    : agency.domain.includes('walletly.ai') ? '' : agency.domain,
        }

      const handleSubmit = async () => {
        setState({loader: true, apiError: ''})
       
        let payload = {
            _id            : agency._id,
            domain         : formik.values.domain,
        }

        let query = {referralDomain: formik.values.domain.includes('www.') ? formik.values.domain.replace('www.', '').toLowerCase() : formik.values.domain.toLowerCase()}
        // let query = {domain: formik.values.domain.includes('www.') ? formik.values.domain.replace('www.', '').toLowerCase() : formik.values.domain.toLowerCase()}
        const getbydomain = await AgencyService.Get({query})
        console.log('getbydomain',getbydomain)

        if(getbydomain.response && getbydomain.response.success == true)
        {
            return setState({loader: false, apiError: 'Domain already registered.'})
        }

        const checkdomain = await AgencyService.CheckDomain({toaster: true, query})
        console.log('checkdomain',checkdomain);
        if(checkdomain.response && checkdomain.response.success == true)
        {

            const generateSSL = await AgencyService.GenerateSSL({toaster: true, query})
            console.log('generateSSL',generateSSL);
            
            if(generateSSL.response && generateSSL.response.success == true)
            {
                const { error, response } = await AgencyService.Update({toaster: true, payload})
                if(error) return setState({loader: false, apiError: error.error})

                agency.updateAgency(response.data)
                              
                return setState({loader: false, apiError: ''})
            }
        }

        setState({loader: false, apiError: ''})

      }
    
      const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : AgencyVld.UpdateAgency,
        isInitialValid   : false
      })

      const buttonFunc = () => {
        return(
            <></>
        )
    }

    useEffect(() => {
        setBtn(buttonFunc)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const goToDomainFunc = () => {
        window.open(
            'https://'+formik.values.domain,
            '_blank'
          );
    }

    let agencyname = agency.agencyName.split(' ').join('')

    const link = 'https://'+ agencyname.toLowerCase()+ '.' + env.DEFAULT_DOMAIN

    return (
        <div className="w-60">
            <form onSubmit={formik.handleSubmit}>
            <div className="heading3 color-blackColor ">Connect your domain</div>
            <div className="paragraph color-textFieldPlaceHolderColor mt-4">Connect your domain with walletly business and share updated domain link with your brands.</div>
            <div className="mt-16">
                <CustomTextField
                    dualIcon
                    className   = "w-100"
                    placeholder = "www.domain.com"
                    label       = "Add Domain"
                    iconStart   = {<SvgIcons.Link/>}
                    iconEnd     = {<div onClick={()=>copyToClipboard(formik.values.domain)}><SvgIcons.Copy/></div>}
                    name        = "domain"
                    value       = {formik.values.domain}
                    onChange    = {formik.handleChange}
                    inputProps  = {{ onFocus: formik.handleBlur }}
                    error       = {formik.touched.domain && formik.errors.domain}
                    helperText  = {formik.touched.domain && formik.errors.domain ? formik.errors.domain : ''}
                />
            </div>
            {agency &&  agency.domain && agency.domain.includes('walletly.ai') && <div className='fs-14 mt-10'>Temporary Url : <a className='caption cp ' onClick={()=>{window.location.href = link}}>{link}</a></div> }
            <div className='fs-12 mt-5 color-error'>{state.apiError}</div>
            {!agency.domain || agency.domain.includes('walletly.ai') && <div className="mt-16">
                <CustomButton
                    btntext = {!state.loader && "Update Domain"}
                    height  = "36px"
                    // type    = "submit"
                    width   = '170px'
                    // onClick = {handleSubmit}
                    onClick = {()=>setState({...state, showConfirmModal : !state.showConfirmModal})}
                    disabled = {state.loader}
                    iconClass={'d-flex justify-content-center'}
                    icon     = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                />
            </div>}
            {!agency.domain || agency.domain.includes('walletly.ai') ? <div className="mt-32">
                <div className="paragraph color-textFieldTextActiveColor mb-24"><div className="dot mr-8 bg-color-navigationSelectedBg color-brandingPrimaryColor">1</div >Go to your domain setting.</div>
                <div className="paragraph color-textFieldTextActiveColor mb-24 d-flex space-between">
                    <div><div className="dot mr-8 bg-color-navigationSelectedBg color-brandingPrimaryColor">2</div >Copy & Paste this ARECORD.</div>
                    <div className="d-flex buttonText">{env.WALLETLY_IP_ADDRESS}<div onClick={()=>copyToClipboard(env.WALLETLY_IP_ADDRESS)} className="ml-20"><SvgIcons.Copy/></div></div>
                </div>
                {/* <div className="paragraph color-textFieldTextActiveColor mb-24 d-flex space-between">
                    <div><div className="dot mr-8 bg-color-navigationSelectedBg color-brandingPrimaryColor">3</div >Copy & Paste this CNAME.</div>
                    <div className="d-flex buttonText">{env.WALLETLY_API_DOMAIN}<div onClick={()=>copyToClipboard(env.WALLETLY_API_DOMAIN)} className="ml-20"><SvgIcons.Copy/></div></div>
                </div> */}
                <div className="paragraph color-textFieldTextActiveColor mb-24"><div className="dot mr-8 bg-color-navigationSelectedBg color-brandingPrimaryColor">3</div >Copy updated domain and paste it here  & update your domain.</div>
                <div className="paragraph color-textFieldTextActiveColor mb-24">Now you can share this domain with your brands!</div>
            </div>

                :

                <div className="mt-8">
                    <div className="d-flex">
                        <div className="domainConfirmCircle">
                            <SvgIcons.SelectedIcon/>
                        </div>
                        <div className="mt-6 ml-8 fw-5 fs-12">
                            Your domain is ready.
                        </div>
                    </div>
                    <div className="mt-16">
                        <CustomButton 
                            btntext         = "Open Agency"
                            backgroundColor = {ColorSchemeCode.cffffff}
                            color           = {ColorSchemeCode.themeColor}
                            height          = {36}
                            icon            = {<SvgIcons.GoToIcon/>}
                            border          = {'1px solid ' + ColorSchemeCode.themeColor}
                            hover           = {ColorSchemeCode.cffffff}
                            focus           = {ColorSchemeCode.cffffff}
                            onClick         = {goToDomainFunc}
                            position        = {'end'}
                        />
                    </div>
                </div>
            
            }
            </form>
            {state.showConfirmModal && <CustomModal component={<UpdateDomain handleSubmit={handleSubmit} onClose={()=>setState({...state, showConfirmModal : false})} formik={formik}/>} open={state.showConfirmModal} />}
        </div>
    )
        
}



export default Domain

const UpdateDomain = ({onClose, formik, handleSubmit}) => {

    console.log('formik ', formik);

    return(
        <div className="updateModal">
            <div className="headingH1 color-c000000 fs-18 fw-6">
                Update Domain Confirmation.
            </div>
            <div className="body2 color-textFieldPlaceHolderColor fs-14">
                Please make sure your adding the correct domain. 
            </div>    
            <div className="body2 color-c000000 fs-14 fw-5 ">
                IT CANNOT BE CHANGED IN FUTURE.
            </div>
            <div className="mt-24 d-flex justify-flex-end">
                <CustomButton
                    btntext         = {'Cancel'}
                    height          = {'36px'}
                    backgroundColor = {ColorSchemeCode.cffffff}
                    hover           = {ColorSchemeCode.cffffff}
                    color           = {ColorSchemeCode.c000000}
                    focus           = {ColorSchemeCode.cffffff}
                    onClick         = {onClose}
                    border          = {'0.5px solid #8C94A6'}
                />  
                <CustomButton
                    btntext   = {'Update'}
                    height    = {'36px'}
                    className = {'ml-18'}
                    onClick   = {handleSubmit}
                    disabled  = {formik.values.domain && false}
                />
            </div>
        </div>
    )
}