import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/user'

const Get = async () => await axios.get(env.API_URL+service, {headers: await MainService.authTokenHeader()})

const API = async (payload) => await axios.post(env.API_URL+service+'/api', payload, {headers: await MainService.authTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.authTokenHeader()})

const VerifyPassword = async({password}) => await axios.get(env.API_URL+service+'/verifyPassword?password='+password, {headers: await MainService.authTokenHeader()})

const GetByQuery = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.authTokenHeader()})


const UserService = {
    API           : Handlers.Services(API),
    Get           : Handlers.Services(Get),
    Update        : Handlers.Services(Update),
    VerifyPassword: Handlers.Services(VerifyPassword),
    GetByQuery    : Handlers.Services(GetByQuery)
}

export default UserService
