import React, {useEffect, useState} from 'react';
import Fade from 'react-reveal/Fade';


import {PngIcons, SvgIcons} from '../../icons';
import { ColorScheme } from '../../enums';
import { utils } from '../../utils'

import CustomButton from '../CustomButton';
import SignupHeader from '../SignupHeader';
import GoogleMap from '../googleMap/GoogleMap';



export default function ConnectAgency({startProgress, setStartProgress, formik, index,  ...props}) {

    const [show, setShow]=useState(false);

    const nextScreen = () => setStartProgress(true);

    useEffect(()=>setShow(true), [])

    const getPlace = (e) => {
        const Geocoder = new window.google.maps.Geocoder()
        const placeRequest = {location: {lat: e.latLng.lat(), lng: e.latLng.lng()}}

        Geocoder.geocode(placeRequest, (places, status) => {
            if(status !== window.google.maps.GeocoderStatus.OK) return
            const location = utils.summarisedLocation(places[0])
            formik.setValues({...formik.values, location})            
        })
    }

    return(
        <div id="connectBrand" className={startProgress && index !== 2 ? 'scale' : index === 2 ? 'scale map' : ''}>
            {index === 2 && 
            <Fade right opposite>
                <GoogleMap 
                    className = 'w-45'
                    width     = '100px'
                    height    = '65vh'
                    zoom      = {formik.values.location.lat ? 15 : 4}
                    draggable = {true}
                    position  = {{lat: formik.values.location.lat || -34.397, lng: formik.values.location.lng || 150.644 }}
                    dragEnd   = {getPlace}
                />
            </Fade>
            }

            <Fade bottom duration={500} opposite when={show}>
    
                <div className="container mt-32">       
               
                    <div className='row cardMain'>
                        {index !== 2 && index !== 4 && index !==5 &&
                            <div class='col text-center'>
                                <div className='toolbar'>
                                    <div className='circleTool'></div>
                                    <div className='circleTool'></div>
                                    <div className='circleTool'></div>

                                    <div className='agencyNameTool'>{formik.values.agencyName}</div>
                                </div>

                                <div className='d-flex flex-row dashboard'>
                                    <div className='side1'>
                                        <div className='innerSide'>
                                            <img src={PngIcons.WalletlyBusinessIcon} alt='' width='100%' className='mt-20 image'/>
                                            <div className='icons mt-20'>
                                                <SvgIcons.NavDashboardIcon/>
                                            </div>
                                            <div className='icons'>
                                                <SvgIcons.NavLoyatlyIcon/>
                                            </div>
                                            <div className='icons'>
                                                <SvgIcons.NavCampaignIcon/>
                                            </div>
                                            <div className='icons'>
                                                <SvgIcons.NavGrowthIcon/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='side2'>
                                        <div className='question color-c000000 mt-30 ml-8'>Dashboard</div>
                                        <div className='active mt-38 pb-8 pt-8'>
                                            <p className='ml-8 mb-0'>Home</p>
                                        </div>

                                        <div className='square mt-8 ml-8 active w-40'>

                                        </div>
                                        <div className='square mt-16 ml-8 active w-55'>
                                        </div>
                                    </div>
                                    <div className='side3'>
                                        <div className='dynamic mt-16 ml-24'>
                                            <div className='circleAgency'>
                                                {(formik.values.agencyLogo || formik.values.agencyLogoFile) && !formik.errors.agencyLogoFile &&
                                                    <img id='logo' src={formik.values.agencyLogo ? formik.values.agencyLogo : URL.createObjectURL(formik.values.agencyLogoFile)} width="100%" height='100%'/>
                                                }
                                            </div>
                                            <div className='agencyName ml-8 mt-8'> {formik.values.agencyName} </div>
                                        </div>
                                    </div>
                                </div>
                        
                                <div className='headingH2 color-c4F4F4F'>
                                    Set Up Your Business
                                </div>
                                <div className='onBordingFirstFont color-828282 mt-40'>
                                    Hi First name, let’s connect your brand with Walletly to create <br/> the best customer engagement program.   
                                </div>


                                <div>
                                <CustomButton 
                                    borderRadius    = {12}
                                    height          = {45}
                                    onClick         = {nextScreen}
                                    className       = 'headingH4 mt-24'
                                    color           = {ColorScheme.ColorSchemeCode.cffffff}
                                    backgroundColor = {ColorScheme.ColorSchemeCode.themeColor}
                                    btntext         = "Set Up Your Business"/>
                                </div>

                                <div className='cardsRow mt-64'>
                                    <div className='cards opacity-4'>
                                        <div className="coverimage"></div>
                                        <div className='circle mt-68'></div>
                                        <div className='input bg-color-cF2F2F2'></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>        
             
                </div>
            </Fade>
        
        </div>
    )

}