import React, { Component } from "react";
import {useHistory} from 'react-router-dom';
import PngIcons from "../icons/png.icon";

import localforage, * as localForage from 'localforage'
import { UserService } from "../services";

const UserContext = React.createContext();

class UserContextComponent extends Component {
  state = {loader: true}


  componentDidMount = async () => {
    const user = await localforage.getItem('user')
    if(!user) return this.setState({...this.state, loader: false})
    this.setState({loader: false, ...user})
  }
  

  

  updateUser = async(updates) => {
    this.setState({...this.state, ...updates});
    await localForage.setItem('user', {...updates})
    // window.location.reload();
  }


  // updateUser = (updates) => {
  //   const updatedUser = {...this.state, ...updates}
  //   this.setState(updatedUser)
  //   localForage.setItem('user', {...updatedUser})
  // }

  render() {

    console.log('state is ', this.state);

    return (
      this.state.loader ? 
      <div className="middle w-100" style={{height: '100vh'}}>
        <img src={PngIcons.walletlyLoader} width={128} height={128} />
      </div> 
      :
      <UserContext.Provider value={{...this.state, updateUser: this.updateUser}}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export { UserContext, UserContextComponent }