import * as localForage from 'localforage'
import { BusinessType } from '../enums/enums'

// const getActiveId = () => {
//     const param = window.location.pathname.split('/')[1]
//     if(param.length > 10) return param
// }

const getActiveIdLocalForage = async () => {
    const user = await localForage.getItem('user')
    if(user.activeAgency !== null) return user.activeAgency
}

const getTokenHeader = async () => {
    const token = await localForage.getItem('token')
    const activeId = await getActiveIdLocalForage()
    return { 'x-auth-token': 'Bearer ' + token, 'authorize-key': activeId ? BusinessType.AGENCY+'&'+activeId : '' }
}

const authTokenHeader = async () => {
    const token = await localForage.getItem('token')
    return { 'x-auth-token': 'Bearer ' + token }
}


const MainService = {
    getTokenHeader,
    authTokenHeader
}

export default MainService