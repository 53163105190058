import React from "react";
import CKEditor from "react-ckeditor-component";

let config = {
  toolbarGroups: [
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"]
    },
    { name: "forms", groups: ["forms"] },
    { name: "basicstyles", groups: ["basicstyles"
    ] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
    },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] }
  ],
  removeButtons:
    "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
  fontSize_sizes: "16/16px;24/24px;48/48px;",
  font_names:
    "Arial/Arial, Helvetica, sans-serif;" +
    "Times New Roman/Times New Roman, Times, serif;" +
    "Verdana",
  allowedContent: true
};


export default class CustomEditor extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.props.onChange.bind(this);
      this.state = {
        html: this.props.html
      };
    }
  
    render() {
      return (
        <CKEditor
          activeClass = "p10"
          config      = {config}
          placeholder = {this.props.placeholder}
          content     = {this.props.html}
          events      = {{
            change: (e)=>this.props.onChange(e, this.props.policy)
          }}
        />
      );
    }
  }