import React, { useContext, useState } from 'react';
import { Link, useHistory } from "react-router-dom";


import CircularProgress from '@material-ui/core/CircularProgress';
import { useFormik } from 'formik'

import * as localForage from 'localforage'

import { AuthVld } from '../../validation'
import { AgencyService, AuthService, UserService } from '../../services'
import { PermissionService } from '../../services'


import CustomTextField from '../../components/CustomTextField';
import CustomButton from '../../components/CustomButton';
import { User } from '../../enums';
import { BusinessType } from '../../enums/enums';
import { UserContext } from '../../context/User.context';
import { AgencyContext } from '../../context/Agency.context';
import { SvgIcons } from '../../icons';



function Login() {

  const userContext = useContext(UserContext)
  const agency      = useContext(AgencyContext)
  const history     = useHistory()

  const [ state, setState ] = React.useState({
    apiError : '',
    loader    : false
  })

  const initState = {
    email    : '',
    password : ''
  }

  const formik = useFormik({
    // validateOnChange : false,
    // validateOnBlur   : false,
    initialValues    : { ...initState },
    onSubmit         : handleSubmit,
    validationSchema : AuthVld.LoginVld,
    isInitialValid   : false
  })

  async function handleSubmit(){
    setState({...state, loader: true})

    console.log('formik.values',formik.values);

    const user = await AuthService.Login({payload: formik.values})
    
    if(user.error) {
      setState({...state, loader: false})
      return setState({...state, apiError: user.error.error})
    }
    
    
      if(!user.response.data.roles.includes(User.Roles.MASTER_ADMIN)){
        setState({...state, loader: false})
        return setState({...state, apiError: 'please signup for agency'})
      }
      
      await localForage.setItem('token', user.response.data.token)
      delete user.response.data.token
      await localForage.setItem('user', user.response.data)
      
      const query = {roles: user.response.data.roles, businessType: BusinessType.AGENCY}
      const permissions = await PermissionService.GetQueriedPermissions({query})
   
      if(permissions.response) await localForage.setItem('permissions', permissions.response.data)
      
      if(!user.response.data.activeAgency && !permissions.error){
          user.response.data.activeAgency = permissions.response.data[0].businessId
          const payload = { activeAgency: permissions.response.data[0].businessId }
          await localForage.setItem('user', user.response.data)
          UserService.Update({payload})
      }
          
      setState({...state, loader: false})
      userContext.updateUser(user.response.data)
      window.location.reload();
        
      if(!user.response.data.activeAgency) return history.push('/agency')

      agency.handleUserLogin() // this will redirect to main dashboard

      // return history.push('/'+user.response.data.activeAgency)
  }

  const [type, settype] = useState('password')

  const eyeClick = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      settype('password')
    } else {
      x.type = "password";
      settype('text')
    }
  }

  return (
    <>
      <div className="headingH2">{'Log In'}</div>  

      <div className="subtitle2 mt-12">
          One account for everything
          <div><Link to=" " className="learnMoreLink">Learn More</Link></div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='input col-12'> 
        
          <div className="mt-40 text-left col-12">
            <CustomTextField 
                label       = "Email address"
                className   = "inputTextField col-12"
                type        = "text"
                placeholder = "Email"
                name        = "email"
                value       = {formik.values.email}
                onChange    = {formik.handleChange}
                inputProps  = {{ onFocus: formik.handleBlur }}
                error       = {formik.touched.email && formik.errors.email}
                helperText  = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            />
          </div>
        
          <div className="mt-40 text-left col-12">
            <CustomTextField 
              label       = "Password"
              className   = "inputTextField col-12"
              type        = "password"
              placeholder = "Password"
              name        = "password"
              id          = "password"
              value       = {formik.values.password}
              onChange    = {formik.handleChange}
              inputProps  = {{ onFocus: formik.handleBlur }}
              error       = {formik.touched.password && formik.errors.password}
              helperText  = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
              icon        = {<div className={"cp"} onClick={eyeClick}>{type !== 'password' ? <SvgIcons.EyeIcon/> : <SvgIcons.CrossEyeIcon/>}</div>}
              position    = "end"
            />
          </div>

          <div className="col-12">
            <CustomButton 
              className = "fs-14 mt-32 col-12"
              btntext   = "Login"
              type      = "submit"
              disabled  = {state.loader || !formik.isValid}
              icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
            />
          </div>
          <div className='caption mt-20'>Don't have an account yet? <Link to="/signup" className="learnMoreLink"> Sign Up here.</Link></div>
          {state.apiError && <div className={'error'}>{state.apiError}</div>}
        </div>  
      </form>
    </>
  );
}


export default Login;
