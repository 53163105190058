import React, { useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import CustomTextField from '../../../components/CustomTextField'
import { SvgIcons } from '../../../icons'

function LanguageModal() {

    const data = [
        {
            language: 'English',
            code    : 'en-US'
        },
        {
            language: 'Spanish',
            code    : 'es-MX'
        },
        {
            language: 'Spanish',
            code    : 'ru-RU'
        },
        {
            language: 'Hindi',
            code    : 'hi-IN'
        },
        {
            language: 'Urdu',
            code    : 'ur-PK'
        },
        {
            language: 'Arabic',
            code    : 'ar-AE'
        },
        {
            language: 'Tamil',
            code    : 'ta-IN'
        },
    ]

    const [result, setResult] = useState(data)
    
    const handleSearch = (e) => {
        const result = data.filter(item =>
            item.language.toLowerCase().includes(e.target.value)
        );
        setResult(result)
    }  

    return (
        <div id="LanguageModal">
            <div className="heading3 color-blackColor mb-16 text-align-center" >Add Language</div>
            <div>
                <CustomTextField
                    className   = "w-100"
                    placeholder = "English, Spanish, Russian..."
                    // label       = "Add Domain"
                    icon={<SvgIcons.Search/>}
                    position={'start'}
                    onChange={(e)=>handleSearch(e)}
                />
            </div>
            <div className="language-modal-div mt-24">
                {
                    result.map((value,index)=>(
                        <>
                            <div className="d-flex modal-list-div">
                                <input type="checkbox" className="align-self-center"/>
                                <div className="ml-8">
                                    <div className="buttonText color-blackColor">{value.language}</div>
                                    <div className="paragraph color-textFieldPlaceHolderColor">Language code: {value.code}</div>
                                </div>
                            </div>
                            {index !== 6 && <div className="bar"/>}
                        </>
                    ))
                }
            </div>
            <div className="mt-24 text-align-center">
                <CustomButton
                    btntext = "Add Language"
                    height  = "36px"
                    width   = 'fit-content'
                />
            </div>
        </div>
    )
}

export default LanguageModal
