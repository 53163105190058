import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomSelect from '../../../components/CustomSelect'
import Graph from '../../../components/Graph'
import EmptyScreen from '../../../components/EmptyScreen'

import SvgIcons from '../../../icons/svg.icon'

import { AgencyContext } from '../../../context/Agency.context'
import { BrandService } from '../../../services'
import { Options } from '../../../utils'

import * as localForage from 'localforage'


function DashboardMain(props) {

    const agency = useContext(AgencyContext);
    const history = useHistory();

    const [state, setState] = useState({
        brands  : [],
        reports : [],
    })

    const [show, setShow] = useState({
        progress : false,
    })

    props.setTitleFunc("Dashboard")

    const button = () => {
        return(
            <></>
        )
    }

    const onLoad = async () => {
        setShow({...show, progress: true});
        
        props.setBtn(button)
        let agencyBrands = await localForage.getItem('agencyBrands');
        if(!agencyBrands){
            const query = {agencyId : agency._id, delete: false}
            const {response, error} = await BrandService.Get({query})
            if(error) return;
            localForage.setItem('agencyBrands', response.data)
            agencyBrands = response.data
        }
        setState({...state, brands : agencyBrands})
        setShow({...show, progress : false})
    }

    useEffect(onLoad, []) // eslint-disable-line react-hooks/exhaustive-deps

    const revenueData = {
        labels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
        datasets: [
            {
                label: 'Revenue',
                data:  [53,40,33,42,80,50,100,110,50,30,70,90],
                fill: true,
                backgroundColor: "hsla(150, 65%, 57%, 0.15)",
                borderColor: "#4AD991"
          },
        ],
      };

      const DomainConfigureFunc = () => {
        let url = history.location.pathname.split('/',2);
        window.location.href = window.location.origin + '/' + url[1]+'/setting/domain';
      }

    return (
        <div id="DashboardMain">
           <div className={` dashboardMain ${!agency.domain && 'domainNotConnected'}` } >
                <div className="d-flex justify-content-space-between  mr-32 ml-32 mt-24 mb-24">
                    <div className="cardOne">
                        <div className="color-blackColor fs-14 fw-4">Total Brands</div>
                        <div className="color-blackColor fs-32 fw-5">{state.brands.length}</div>
                    </div>
                    <div className="card mr-24">
                        <div className="color-blackColor fs-14 fw-4">Ulitmate Agency</div>
                        <div className="color-blackColor fs-32 fw-5">0</div>
                    </div>
                    <div className="card">
                        <div className="color-blackColor fs-14 fw-4">Single Loyalty Agency</div>
                        <div className="color-blackColor fs-32 fw-5">0</div>
                    </div>
                </div>
                <div className="d-flex justify-content-space-between cardMain mr-55 ml-32">
                    <div className="revenueGraph col-8 card middle">
                        {state.reports.length > 0  ? <div className="">
                            <div className="d-flex justify-content-space-between">
                            <div>
                            <div className="paragraph color-blackColor">
                                Revenue Generated
                            </div>
                            <div className="d-flex">
                                <div className="headerHeading color-blackColor">
                                    $ 50, 235
                                </div>
                                <div className="ml-10">
                                    <SvgIcons.GraphProgressIcon/>
                                </div>
                                <div className="customLabel ml-5 color-selectGreenBackgroundColor pt-6">
                                    20.3%
                                </div>
                            </div>
                            </div>
                            <div className="w-30">
                            <CustomSelect 
                                className = "w-100"
                                icon      = {<SvgIcons.Calendar/>}
                                name      = 'currency'
                                options   = {Options.getMonths()}
                            />
                            </div>
                            </div>
                            <Graph data={revenueData} type="line"/>
                        </div> 
                        :  <div className="color-c000000 fw-5 fs-20 subtitle1">No Revenue Generated</div>
                        }
                    </div>
                    <div className="col-4 card">
                        <div className="d-flex justify-content-space-between mb-24">
                            <div>
                                <div className="color-blackColor fs-18 fw-5">Top Brands</div>
                                <div className="color-textFieldPlaceHolderColor fs-12 fw-4">Your most revenue generated brands.</div>
                            </div>
                            <div className={`color-brandingPrimaryColor fs-12 fw-5 cp ${state.brands.length < 1 && 'disabled'}`}>
                                View all
                            </div>
                        </div>
                        <div className="sampleData">
                            {show.progress ? <CircularProgress className="middle" color="inherit" size={10}/> : 
                            <div>
                                {
                                state.brands.length > 0 ? state.brands.map((value,index)=>(
                                        value.brandType !== 'subBrand' && <div className="d-flex justify-content-space-between mb-32 ">
                                            <div className="d-flex">
                                                <img src={value.brandLogo} className="borderRadius-40 mr-8" alt={index} width="40px" height="40px" />
                                                <div>
                                                    <div className="fw-5 fs-14 color-blackColor">{value.brandName}</div>
                                                    <div className="color-textFieldPlaceHolderColor fs-12 fw-4">{`${value.location.city + ', ' + value.location.country}`}</div>
                                                </div>
                                            </div>
                                            <div>
                                                {/* <div className="fw-5 fs-14 color-blackColor p-4 bg-color-buttonTertiaryColor borderRadius-4">{value.price}</div> */}
                                            </div>
                                        </div>
                                    ))

                                :

                                <div className="absoluteMiddle color-c000000 fw-5 fs-20 subtitle1">
                                    No Brands yets
                                </div>
                                
                                }

                            </div>}
                        </div>
                    </div>
                    
                </div>
            </div>  
            {!agency.domain && <div className="absoluteMiddle w-100 z-9 noDomainScreen"><EmptyScreen otherTitle="Please Configure Your Domain" otherCaption={'You don’t have any Domain yet. Click Configure Domain to manually Configure your Domain.'} btnTitle="Configure Domain" btnClick={DomainConfigureFunc}/></div>}
        </div>
    )
}

export default DashboardMain
