import React, { useContext, useEffect, useState } from 'react'

import CustomTabs from '../../../components/CustomTab';
import CustomTextField from '../../../components/CustomTextField';
import CustomSelect from '../../../components/CustomSelect';
import CopyText from '../../../components/CopyText'

import {ColorSchemeCode} from '../../../enums/ColorScheme'
import CustomEditor from './CustomEditor';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { SvgIcons } from '../../../icons';

import { useFormik } from 'formik'
import CustomButton from '../../../components/CustomButton';
import { AgencyContext } from '../../../context/Agency.context';
import { Options, utils } from '../../../utils';
import GoogleMap from '../../../components/googleMap/GoogleMap';
import Autocomplete from '../../../components/googleMap/Autocomplete';
import { AgencyPolicyService, AgencyService } from '../../../services';
import { AgencyVld } from '../../../validation';
import { CircularProgress } from '@material-ui/core';
import * as localForage from 'localforage';

export default function General({setTitleFunc, setBtn}) {

    const agency = useContext(AgencyContext)

    const labels=[
        'Agency Information',
        'Agency Policies',
        'Agency API'
    ]

    const initState = {
        agencyName    : agency.agencyName,
        phone         : agency.phone,
        dialingCode   : agency.dialingCode,
        email         : agency.email,
        location      : agency.location,
        currency      : agency.currency,
        awsEmail      : agency?.awsEmail || ""
    }

    const [state, setState] = useState({
        loader   : false,
        apiError : ''
    })

    const handleSubmit = async () => {

        setState({loader: true, apiError: ''})

        const payload = {
            _id            : agency._id,
            agencyColor    : agency.agencyColor,
            agencyName     : formik.values.agencyName.toLowerCase(),
            logo           : agency.logo,
            location       : formik.values.location,
            currency       : formik.values.currency,
            email          : formik.values.email,
            phone          : formik.values.phone,
            dialingCode    : formik.values.dialingCode,
        }

        if(formik.values.awsEmail !== "" || (formik.values.awsEmail !== agency.awsEmail)){
            let { error, response } = await AgencyService.VerifySES({toaster: true, payload: {awsEmail : formik.values.awsEmail}});
            console.log('response ', response)
            if(error){
                console.log('errpr ', error)
                setState({apiError : 'Agency Email cannot be verified'})
            }
            else{
                payload.awsEmail = formik.values.awsEmail;
            }
        }

        const { error, response } = await AgencyService.Update({toaster: true, payload})
        if(error) return setState({loader: false, apiError: error.error})

        agency.updateAgency(response.data)
        return setState({loader: false, apiError: ''})

    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : AgencyVld.UpdateAgency,
        isInitialValid   : false
    })

    const components = [
        <AgencyInformation state={state} formik={formik} setBtn={setBtn} agency={agency} />,
        <AgencyPolicies setBtn={setBtn} />,
        <AgencyApi setBtn={setBtn} />
    ]

    setTitleFunc('General Information')

    return (
        <div id="General">
            <form onSubmit={formik.handleSubmit}>
                <CustomTabs
                    labels     = {labels}
                    components = {components}  
                />
            </form>
        </div>
    )
}

const AgencyInformation = ({setBtn, agency, formik, state}) => {

    const [timezone, setTimezone] = useState('')

    const buttonFunc = () => {
        return(
            <CustomButton
                btntext  = {!state.loader && "Update Changes"}
                height   = "36px"
                // width    = '180px'
                onClick  = {formik.handleSubmit}
                type     = "submit"
                disabled = {state.loader}
                iconClass={'d-flex justify-content-center'}
                icon     = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
            />
        )
    }
    
    const onLoad = () => {
        const currency = utils.getCurrency({location: agency.location})
        const selectedTimezone = utils.getTimezone({location: agency.location})
        setTimezone(selectedTimezone)
        formik.setValues({...formik.values, currency})

    }

    useEffect(() => {
        setBtn(buttonFunc)
    }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onLoad()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const onCurrencyChange = (e) => {
        const currency = utils.getCurrency({currencyCode: e.target.value})
        formik.setValues({...formik.values, currency})
    }

    const onTimezoneChange = (e) => {
        const selectedTimezone = utils.getTimezone({timezoneName: e.target.value})
        setTimezone(selectedTimezone.name)
        const location = formik.values.location
        location.offset = selectedTimezone.utcOffset
        formik.setValues({...formik.values, location: location})
    }

    const onAddressChange     = (event) => formik.setValues({...formik.values, location: {...formik.values.location, address: event.target.value}})
    const onCityChange        = (event) => formik.setValues({...formik.values, location: {...formik.values.location, city: event.target.value}})
    const onCountryChange     = (event) => formik.setValues({...formik.values, location: {...formik.values.location, country: event.target.value}})
    const locationSummary     = (location) => 
    {
        const currency = utils.getCurrency({location: location})
        const selectedTimezone = utils.getTimezone({location: location})
        setTimezone(selectedTimezone)
        formik.setValues({...formik.values, location, currency})
    }


    const getPlace = (e) => {
        const Geocoder = new window.google.maps.Geocoder()
        const placeRequest = {location: {lat: e.latLng.lat(), lng: e.latLng.lng()}}

        Geocoder.geocode(placeRequest, (places, status) => {
            if(status !== window.google.maps.GeocoderStatus.OK) return
            const location = utils.summarisedLocation(places[0])
            formik.setValues({...formik.values, location})            
        })
    }
    
    return(
        <div className="brandInformation mb-50 ">
            <div className="d-flex">
                <div className="heading3 mt-32 mb-16 color-blackColor">
                    Account Information
                </div>
                <div className = "brandId d-flex bg-color-cF6F6F9 ml-24 w-fit">
                    <div className = "subtitle2 fw-5 opacity-10 color-textfieldColor d-flex">
                            Agency Id :<span className = "subtitle2 ml-5 color-c828282 opacity-10"><CopyText content={agency._id} /></span> 
                    </div>
                </div>
            </div>
                <div>
                    <CustomTextField
                        className   = "w-35 mb-24"
                        placeholder = "e.g. abc Agency"
                        label       = "Agency Name"
                        name        = "agencyName"
                        value       = {formik.values.agencyName}
                        onChange    = {formik.handleChange}
                        inputProps  = {{ onFocus: formik.handleBlur }}
                        error       = {formik.touched.agencyName && formik.errors.agencyName}
                        helperText  = {formik.touched.agencyName && formik.errors.agencyName ? formik.errors.agencyName : ''}
                    />
                </div>
                <div className="d-flex mb-24 w-35">
                    <div className="col-4 pr-0 pl-0">
                        <CustomSelect 
                            className  = "w-100 pl-0"
                            options    = {Options.getContryCodes()}
                            name       = "dialingCode"
                            value      = {formik.values.dialingCode}
                            onChange   = {formik.handleChange}
                            error      = {formik.touched.dialingCode && formik.errors.dialingCode}
                            helperText = {formik.errors.dialingCode && formik.errors.dialingCode}
                        />
                    </div>
                    <div className="col-8 pr-0">
                        <CustomTextField 
                            className   = "col-12 mt-1"
                            type        = "tel"
                            placeholder = "Phone Number"
                            name        = "phone"
                            value      = {formik.values.phone}
                            onChange   = {formik.handleChange}
                            inputProps = {{ onFocus: formik.handleBlur }}
                            error      = {formik.touched.phone && formik.errors.phone}
                            helperText = {formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''}
                        />
                    </div>
                </div>
                <div>
                <CustomTextField
                    className   = "w-35 "
                    placeholder = "e.g. contact@abc.com"
                    label       = "Agency Email"
                    icon        = {<SvgIcons.Email/>}
                    position    = "start"
                    name        = "email"
                    value       = {formik.values.email}
                    onChange    = {formik.handleChange}
                    inputProps  = {{ onFocus: formik.handleBlur }}
                    error       = {formik.touched.email && formik.errors.email}
                    helperText  = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                />
                </div>
                <div className="d-flex">
                    <div className="col-12 col-md-6 pl-0 pr-0" >
                        <div className="bar w-70 mt-48 mb-48"></div>
                        <div className="heading3 color-blackColor">
                            Contact Information
                        </div>
                        <div className="col-md-12 col-12 pr-0 pl-0 mt-24">
                            <Autocomplete 
                                className       = "w-70"
                                variant         = "standard"
                                fontSize        = "25px"
                                label           = "Address"
                                name            = "address"
                                value           = {formik.values.location ? formik.values.location.address : ''}
                                onChange        = {onAddressChange}
                                InputProps      = {{ disableUnderline: false, style: { fontSize: 16,color: '#1D3B56'} }}
                                locationSummary = {locationSummary}
                                error           = {formik.errors.location && !formik.values.location ? formik.errors.location.address : false}
                                helperText      = {formik.errors.location && !formik.values.location ? formik.errors.location.address : ''}
                            /> 
                        </div>
                        <div className="d-flex mt-24 w-70 ">

                            <div className="col-md-6 col-12 pl-0 pr-0">
                                <CustomTextField 
                                    className    = "w-100"
                                    label        = "City"
                                    // borderRadius = {'12px'}
                                    placeholder  = "Your brand city"
                                    name         = "city"
                                    value        = {formik.values.location ? formik.values.location.city : ''}
                                    onChange     = {onCityChange}
                                    error        = {formik.errors.location && formik.values.location && !formik.values.location.city ? formik.errors.location.city : false}
                                    helperText   = {formik.errors.location && formik.values.location && !formik.values.location.city ? formik.errors.location.city : ''}
                                />
                            </div>
                        
                            <div className="col-md-6 col-12 pr-0">
                                <CustomTextField 
                                    className    = "w-100"
                                    label        = "Country"
                                    // borderRadius = {'12px'}
                                    placeholder  = "Your country"
                                    name         = "country"
                                    value        = {formik.values.location ? formik.values.location.country : ''}
                                    onChange     = {onCountryChange}
                                    error        = {formik.errors.location && formik.values.location && !formik.values.location.country ? formik.errors.location.country : false}
                                    helperText   = {formik.errors.location && formik.values.location && !formik.values.location.country ? formik.errors.location.country : ''}
                                />
                            </div>

                        </div>
                        <div className="bar w-70 mt-48 mb-48"></div>
                    </div>
                    <div className="col-12 col-md-5 pr-0 pl-0 offset-1 m-10">
                        <GoogleMap 
                            width     = {"100%"}
                            height    = {"100%"}
                            zoom      = {formik.values.location && formik.values.location.lat ? 15 : 4}
                            draggable = {true}
                            position  = {formik.values.location ? {lat: formik.values.location.lat, lng: formik.values.location.lng } : ''}
                            dragEnd   = {getPlace}
                        />
                    </div>
                </div>
                
                <div className="w-70 mb-48"></div>
                    <div className="heading3 color-blackColor">
                        Email Services
                    </div>
                
                <div className='mt-24'>
                    <CustomTextField
                    className   = "w-35 "
                    placeholder = "e.g. noreply@abc.com"
                    label       = "Agency Verified Email"
                    icon        = {<SvgIcons.Email/>}
                    position    = "start"
                    name        = "awsEmail"
                    value       = {formik.values.awsEmail}
                    onChange    = {formik.handleChange}
                    inputProps  = {{ onFocus: formik.handleBlur }}
                    error       = {formik.touched.awsEmail && formik.errors.awsEmail}
                    helperText  = {formik.touched.awsEmail && formik.errors.awsEmail ? formik.errors.awsEmail : ''}
                />
                </div>
                
                <div className='bar mt-48 w-35 mb-32'></div>
                <div className="heading3 color-blackColor ">
                    Locale Information
                </div>
                <div className="mt-16">
                    <CustomSelect 
                        className  = "w-35"
                        options    = {Options.getCurrencyCodes()}
                        label      = "Currency"
                        icon       = {<SvgIcons.Currency/>}
                        name       = 'currency'
                        value      = {formik.values.currency ? formik.values.currency.code : ''}
                        onChange   = {onCurrencyChange}
                    />
                </div>
                <div className="mt-24">
                    <CustomSelect 
                        className  = "w-35"
                        options    = {Options.getTimezones()}
                        label      = "Timezone Offset"
                        value      = {timezone}
                        onChange   = {onTimezoneChange}
                        icon       = {<SvgIcons.Timezone/>}
                    />
                </div>
        </div>
    )
}

const AgencyPolicies = ({setBtn}) => {
    
    const [policyData, setPolicyData] = useState({
        policies: [
            {name: 'GDPR Policy', text: ''},
            {name: 'Refund & cancellation', text: ''},
            {name: 'Custom Policy', text: ''},
            {name: 'Term & Condition', text: ''},
            {name: 'Privacy Policy', text: ''},
        ],
        loader: {loader: false, apiError: ''},
        user: '',
        update: '',
        policy: 0
    })

    // const [update, setUpdate] = useState('')
    // const [user, setUser] = useState('')

    // const [loader, setloader] = useState(false)

    useEffect(async() => { // eslint-disable-line react-hooks/exhaustive-deps
        const user = await localForage.getItem('user')
        const query = { agencyId: user.activeAgency }
        const { response, error } = await AgencyPolicyService.Get({query})
        if (response && response.message === 'agency policy found') {
            setPolicyData({...policyData, update: response.message, user: {user: user, _id: response.data[0]._id}, 
                policies: [
                    {name: 'GDPR Policy', text: response.data[0].gdprPolicy},
                    {name: 'Refund & cancellation', text: response.data[0].refundCancellation},
                    {name: 'Custom Policy', text: response.data[0].customPolicy},
                    {name: 'Term & Condition', text: response.data[0].termCondition},
                    {name: 'Privacy Policy', text: response.data[0].privacyPolicy},
                ]
            })
        }
        if (error && error.message === "agency policy not found") {
            setPolicyData({...policyData, user: {user: user}, update: error.message})
        }
        setBtn(buttonFunc)

        // eslint-disable-next-line
    }, [policyData.update]) 
    
    // const [Policy, setPolicy] = useState(0);

    const handleEditor = (e) => {
        var html = e.editor.getData();
        policyData.policies[policyData.policy].text = html
        setPolicyData(policyData)
        setBtn(buttonFunc)
    }

    // const [btnState, setBtnState] = useState({
    //     loader   : false,
    //     apiError : ''
    // })
    
    const handleSubmit = async() => {
        
        // setBtnState({loader: true, apiError: ''})
        setPolicyData({...policyData, loader: {loader: true, apiError: ''}})
        // console.log('(submitStart)__loader',loader);
        
        // console.log('Policies --->',policyData.policies);
        // console.log('policyData.update --->',policyData.update);
        
        // console.log('update ===> (handleSubmit) ---> ',update);
        if (policyData.update !== "agency policy found") { 
            const payload = {
                userId            : policyData.user.user._id,
                agencyId          : policyData.user.user.activeAgency,
                // gdprPolicy        : policyData.policies[0].text,
                // refundCancellation: policyData.policies[1].text,
                // customPolicy      : policyData.policies[2].text,
                // termCondition     : policyData.policies[3].text,
                // privacyPolicy     : policyData.policies[4].text
            }
            if(policyData.policies[0].text) payload.gdprPolicy         = policyData.policies[0].text
            if(policyData.policies[1].text) payload.refundCancellation = policyData.policies[1].text
            if(policyData.policies[2].text) payload.customPolicy       = policyData.policies[2].text
            if(policyData.policies[3].text) payload.termCondition      = policyData.policies[3].text
            if(policyData.policies[4].text) payload.privacyPolicy      = policyData.policies[4].text

            const { error, response } = await AgencyPolicyService.Create({payload})
            if(error) return setPolicyData({...policyData, loader: {loader: false, apiError: error.error}})
            
            // console.log('Create API Response --->', response);
            setPolicyData({...policyData, update: response.message, loader: {loader: false, apiError: ''}})
        } else {
            const payload = {
                _id               : policyData.user._id,
                userId            : policyData.user.user._id,
                agencyId          : policyData.user.user.activeAgency,
                // gdprPolicy        : policyData.policies[0].text,
                // refundCancellation: policyData.policies[1].text,
                // customPolicy      : policyData.policies[2].text,
                // termCondition     : policyData.policies[3].text,
                // privacyPolicy     : policyData.policies[4].text
            }

            if(policyData.policies[0].text) payload.gdprPolicy         = policyData.policies[0].text
            if(policyData.policies[1].text) payload.refundCancellation = policyData.policies[1].text
            if(policyData.policies[2].text) payload.customPolicy       = policyData.policies[2].text
            if(policyData.policies[3].text) payload.termCondition      = policyData.policies[3].text
            if(policyData.policies[4].text) payload.privacyPolicy      = policyData.policies[4].text
            
            const { error, response } = await AgencyPolicyService.Update({toaster: true ,payload})
            if(error) return setPolicyData({...policyData, loader: {loader: false, apiError: error.error}})
            
            // console.log('Update API Response --->', response);
            setPolicyData({...policyData, loader: {loader: false, apiError: ''}})
        }
        setPolicyData({...policyData, loader: true})
        // console.log('(submitEnd)__loader',loader);
    }

    const buttonFunc = () => {
        // console.log('(button)__loader',loader);
        return(
            <CustomButton
                btntext   = {!policyData.loader.loader && "Update Changes"}
                // btntext   = {"Update Changes"}
                width     = '180px'
                height    = "36px"
                onClick   = {handleSubmit}
                disabled  = {policyData.loader.loader}
                iconClass = {'d-flex justify-content-center'}
                icon      = {policyData.loader.loader && <CircularProgress size={20} color={'inherit'}/>}
            />
        )
    }

    return(
        <div>
            <div style={{display: 'flex',height: '100vh'}}>
                <div style={{width: '239px',borderRight: '1px solid #F0F0F0'}}>
                    <div className="heading3 w-100 color-blackColor mt-16 mb-16">
                        Policies
                    </div>
                <List>
                    {policyData.policies.map((value,index) => {
                        return (
                            <div className={policyData.policy === index ? "parentLi-a" : "parentLi"}>
                                <ListItem key={value} dense  onClick={()=>setPolicyData({...policyData, policy: index })} className={"listt pt-0"}>
                                    <ListItemText ><div className={policyData.policy === index ? "textColor-a text" : "textColor text"}>{value.name}</div></ListItemText>
                                </ListItem>
                            </div>
                        );
                    })}
                    </List>
                </div>
                <div style={{width: '100%'}}>
                    <CustomEditor 
                        onChange = {(e)=>handleEditor(e)}
                        html     = {policyData.policies[policyData.policy].text}
                        policy   = {policyData.policy}
                    />
                </div>
            </div>
        </div>
    )
}


const AgencyApi = (props) => {
    // const brand     = useContext(BrandContext)

    const agency = useContext(AgencyContext)

    const [state, setState] = useState({
        open       : false,
        deleteApi  : false,
        regenerate : false
    })

    return(
        <div id="Api">
            <div className="heading3 w-100 color-blackColor mt-16 mb-16">
                API
            </div>
        <div className="apiBox d-flex">
            <div className="col-12 col-md-7 pl-0 d-flex apiBoxComponents">
                <div className="d-flex col-md-5 col-12 pl-0 pr-0">
                    <div className="body2 pt-4 pb-4">
                        {props.component === 'delete' ? 'Delete API Key' : 'Get API Key'} 
                    </div>
                    {props.component !== 'delete' &&
                        <div className="planName caption2 fs-12 color-cffffff bg-color-themeColor ml-8">Pro</div>
                    }
                </div>
            
                <div className="col-md-7 col-12 middleBox">
                        <div className="col-12 col-md-12 pl-0 pr-0">
                            <CustomTextField 
                                label       = {"API Key"}
                                placeholder = {"Your api key here!"}
                                className   = "w-100"
                                value       = {agency.apiKey}
                                inputProps  = {{ readOnly: true }}
                            />
                        </div>
                </div>
             </div>
        
            <div className="col-md-5 col-12 pl-0">
            
                <div className="col-md-12 col-12 body2 fs-14 color-tableActionDropdownColor detail">
                
                {
                props.component !== 'delete' ?
                
                    'Beware, if you Refresh API Key all connected API methods will be disabled. \n Here is the link to Swagger where you can try our API. Help article is available here.'
                    
                    :

                    'Beware, if you Delete Token all connected API methods will be disabled.'

                }   
                </div> 
                         
            </div>

        </div>
        </div>
    )
}
