import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/brand'

const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const Get = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetUserBrands = async ({query}) => await axios.get(env.API_URL+service+'/user?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetOtherBrands = async ({query}) => await axios.get(env.API_URL+service+'/agency?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const CreateSubscription = async ({payload}) => await axios.post(env.API_URL+service+'/subscription', payload, {headers: await MainService.getTokenHeader()})

const UpdateSubscription = async ({payload}) => await axios.put(env.API_URL+service+'/subscription', payload, {headers: await MainService.getTokenHeader()})

const RenewSubscription = async ({payload}) => await axios.post(env.API_URL+service+'/subscription/renew', payload, {headers: await MainService.getTokenHeader()})

const BrandService = {
    Create             : Handlers.Services(Create),
    Get                : Handlers.Services(Get),
    GetUserBrands      : Handlers.Services(GetUserBrands),
    GetOtherBrands     : Handlers.Services(GetOtherBrands),
    Update             : Handlers.Services(Update),
    CreateSubscription : Handlers.Services(CreateSubscription),
    UpdateSubscription : Handlers.Services(UpdateSubscription),
    RenewSubscription  : Handlers.Services(RenewSubscription)
}

export default BrandService
