import {React,useContext,useEffect, useState} from 'react';
import { useHistory } from 'react-router';

import { useFormik } from 'formik'
import * as localForage from 'localforage'

import { utils } from '../../utils';
import { AgencyVld } from '../../validation';
import { AgencyService, PermissionService, UserService } from '../../services';

import Dashboard from '../dashboard/Dashboard';

import ConnectAgency from '../../components/createAgency/ConnectAgency';
import AgencyNameScreen from '../../components/createAgency/AgencyNameScreen';
import AgencyLogoScreen from '../../components/createAgency/AgencyLogoScreen';
import LocationScreen from '../../components/createAgency/LocationScreen';
import SignupHeader from '../../components/SignupHeader';
import CurrencyScreen from '../../components/createAgency/CurrencyScreen';
import { AgencyUseType, BusinessType } from '../../enums/enums';
import { UserContext } from '../../context/User.context';
import { AgencyContext } from '../../context/Agency.context';


export default function CreateAgency(){

    const userContext   = useContext(UserContext)
    const agencyContext = useContext(AgencyContext)
    const history       = useHistory()

    const [state, setState] = useState({
        index         : 0,
        width         : 0,
        startProgress : false,
        apiError      : '',
        loader        : false
    })

    const initState = {
        agencyName       : '',
        agencyLogo       : '',
        agencyCover      : '',
        agencyColor      : '',
        agencyLogoFile   : '',
        agencyCoverFile  : '',
        location         : '',
        currency         : '',
        agencyUse        : AgencyUseType.OWN,
    }

    const handleSubmit = async () => {

        setState({...state, apiError: '', loader: true})

        const user = await localForage.getItem('user')
        const desiredPath = `${user._id}/${formik.values.agencyName}`
        
        const image = await utils.uploadImage({file: formik.values.agencyLogoFile, desiredPath})
        if(image.error) return setState({...state, loader: false, apiError: image.error.message})

        let cover
        if(formik.values.agencyCoverFile && !formik.errors.agencyCoverFile)
        cover = await utils.uploadImage({file: formik.values.agencyCoverFile, desiredPath})
        if(cover && cover.error) return setState({...state, loader: false, apiError: cover.error.message})
        
        const payload = {
            agencyName          : formik.values.agencyName.toLowerCase(),
            agencyColor         : formik.values.agencyColor,
            agencyUse           : formik.values.agencyUse,
            location            : formik.values.location,
            currency            : formik.values.currency,
            logo                : image.response.data,
            email               : user.email,
            phone               : user.phone,
            dialingCode         : user.dialingCode,
            loader              : "https://live-walletly.s3.ap-southeast-2.amazonaws.com/wallelyloader/defaultLoader.gif"
        }

        if(cover && cover.response)
        payload.agencyCover = cover.response.data

        const { error, response } = await AgencyService.Create({payload})
        if(error) return setState({...state, apiError: error.error, loader: false})
        setState({...state, apiError: '', loader: false})

        agencyContext.updateAgency(response.data)
        update(response.data._id, user._id)

        if(!user.activeAgency){
            await localForage.setItem('user', {...user, activeAgency: response.data._id})
            userContext.updateUser({...user, activeAgency: response.data._id})
        }

        const query = {roles: user.roles, businessType: BusinessType.AGENCY}
        const permission = await PermissionService.GetQueriedPermissions({query})
        if(permission.response) await localForage.setItem('permissions', permission.response.data)


        // history.push('/selectplan')
        history.push({pathname: '/'+response.data._id, state: {activeAgency: response.data}})
    }

    const update = async(activeAgency, _id) => {
        const payload = { activeAgency: activeAgency, _id: _id }
        await UserService.Update({payload})
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : AgencyVld.CreateAgency,
        isInitialValid   : false
    })

    const setActivePage = (activePage, type) => {
        if(type === 'next')
        {             
            //for online and both service type then it includes the shoppify
               setState({
                   ...state,
                   width : state.width+25,   //as all the components will be shown including shopify and location as user choses (online or both) so per question progress bar increase 11.11%
                   index : activePage+1
               })
        }
        else
        {
           setState({
               ...state,
               width : state.width-25,   //as all the components will be shown including shopify and location as user choses (online or both) so per question progress bar increase 11.11%
               index : activePage-1
           })
        }
       

    }

    const setStartProgress = (progressStatus) => setState({...state, startProgress : progressStatus, width: state.width+25})


    //all the components for onboarding steps 
    const components = [
        {
            component: <AgencyNameScreen setActivePage={setActivePage} formik={formik} index={state.index}/>
        },
        {
            component: <AgencyLogoScreen setActivePage={setActivePage} formik={formik} index={state.index}/>
        },
        {
            component: <LocationScreen setActivePage={setActivePage} formik={formik} loader={state.loader} apiError={state.apiError} index={state.index}/>
        },
        {
            component: <CurrencyScreen setActivePage={setActivePage} formik={formik} loader={state.loader} apiError={state.apiError} index={state.index}/>
        },
        
    ]
    

    return(
        <div className="OnBoardingPage">
            {console.log('formik',formik)}
            <SignupHeader className='header'/>

            <form onSubmit={formik.handleSubmit}>
                <div className="progressBar" >
                    {/* setting height of progress bar */}
                    <div className="bar" style={{width: state.width+'%', height:'100%'}}></div>
                </div>

                <ConnectAgency 
                    setStartProgress = {setStartProgress}
                    startProgress    = {state.startProgress}
                    formik           = {formik}
                    index            = {state.index}
                />
                {state.startProgress &&
                    <div>
                        {/* specific component is rendered with sidebar */}
                        {components[state.index].component}  
                    </div>
                }
            </form>

        
        </div>
    )

}