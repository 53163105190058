import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import localforage, * as localForage from 'localforage'
import SvgIcons from '../../icons/svg.icon';

export default function SettingHeader(props) {
    
    const handleClickToogle = () => props.setShow(!props.show);
    const history = useHistory()
    const [user, setuser] = useState()
    
    useEffect(async() => {
        const data = await localforage.getItem('user')
        setuser(data)
    }, [])

    return (
        <div id="settingHeader" className={"row ml-0 mr-0 position-fixed w-100 " + props.className}>
            <div className="col-6 headerItems">
                <div className="headerHeading color-blackColor" onClick={handleClickToogle}>
                    <span className="cp display-none"><SvgIcons.HeaderCollapseIcon /></span>
                    <span className="">
                            {props.heading}
                    </span>
                </div>
            </div>
            {props.btn && <div className="position-fixed" style={{right: '24px', top: '12px'}}>
                {props.btn}
            </div>}
            <div className="bar col-12 col-md-12"></div>
            {
            user && (!user.verified) && 
            <div className="banner fs-14 middle w-100 color-cffffff cp" onClick={()=>history.push('/verify')}><SvgIcons.Email color="#ffffff"/><div className="ml-8"> Please verify your email to access all features.</div></div>
            }
        </div>
    )
}
