const Environment = {
    DEVELOPMENT     : 'development',
    STAGING         : 'staging',
    PRODUCTION      : 'production'
}

const Server = {
    DEV_URL     : '',
    PROD_URL    : ''
}

const ApiVersions = {
    V3 : 'v3',
    V4 : 'v4'
}

const ResponseStatus = {
    SUCCESS         : 200,
    BAD_REQUEST     : 400,
    UNAUTHORIZED    : 401,
    FORBIDDEN       : 403,
    NOT_FOUND       : 404,
    INTERNAL_ERROR  : 500,
}

const Months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const Images = {
    LOGO_FILE_SIZE        : 5 * 1024 * 1024,
    LOGO_SUPPORTED_FORMAT : ['image/jpg', 'image/jpeg', 'image/png'],
    ICON_FILE_SIZE        : 2 * 1024 * 1024
}

const TOASTER_DELAY = 4 * 1000


const AgencyUseType = {
   PARTNER : 'partner',
   OWN     : 'own'
}

const Operands = {
    GREATER   : 'greater',
    LESSER    : 'lesser',
    EQUAL     : 'equal',
    NOT_EQUAL : 'not_equal'
}


//selectdropdown values of components 

const Moreoptions = [
    {
        name : 'More',
        value: 'more',
    },
    {
        name : 'Delete',
        value: 'delete',
    }
]

const PointExpiry = [
    {
        name    : 'After a certain period of inactivity',
        value   : 'After a certain period of inactivity'
    }
]

const PurchaseTypes = [
    {
        name : 'Total Purchase',
        value: 'Total Purchase'
    }
]

const PassVariables = [
    {
        name : 'Brand Name',
        value: 'Brand Name',
    }, 
    {
        name : 'Days Left',
        value: 'Days Left',
    }, 
    {
        name : 'Customer Lable',
        value: 'Customer Lable',
    }, 
    {
        name : 'First Name',
        value: 'First Name',
    }, 
    {
        name : 'Discount Table',
        value: 'Discount Table',
    }, 
    {
        name : 'Discount Field',
        value: 'Discount Field',
    }
]

const BusinessType = {
    BRAND  : 'brand',
    AGENCY : 'agency'
}



export {
    Environment,
    Server,
    ApiVersions,
    ResponseStatus,
    Months,
    Images,
    TOASTER_DELAY,
    Operands,
    Moreoptions,
    PointExpiry,
    PurchaseTypes,
    PassVariables,
    AgencyUseType,
    BusinessType
}