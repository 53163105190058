import React, { useContext, useEffect } from 'react'
import CustomTextField from '../../../components/CustomTextField'
import { SvgIcons } from '../../../icons'
import { utils } from '../../../utils'
import { AgencyContext } from '../../../context/Agency.context'
import env from '../../../config'

function Invite({setTitleFunc, setBtn}) {
    setTitleFunc('Invite')

    const agency = useContext(AgencyContext)

    const link = agency.domain ? `https://${agency.domain}/invite` : `https://${agency.agencyName.toLowerCase()}.${env.WALLETLY_DOMAIN}/invite`


    const buttonFunc = () => {
        return(
            <></>
        )
    }

    useEffect(() => {
        setBtn(buttonFunc)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    

      const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value)
        utils.showToaster({position: 'top-right', title: 'Copied', message: value, severity: 'success'})
    }

    return (
        <div className="w-60">
            <div className="heading3 color-blackColor ">Invite Brands</div>
            <div className="paragraph color-textFieldPlaceHolderColor mt-4">Connect your brands by sharing invite link</div>
            <div className="mt-16">
                <CustomTextField
                    dualIcon
                    className   = "w-100"
                    label       = "Invite Link"
                    iconStart   = {<SvgIcons.Link/>}
                    iconEnd     = {<div onClick={()=>copyToClipboard(link)}><SvgIcons.Copy/></div>}
                    value       = {link}
                    inputProps  = {{ readOnly: true }}
                />
            </div>
        </div>
    )
}

export default Invite
