import React, { useContext, useEffect, useState } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress';

import env from '../../../config';

import CustomButton from '../../../components/CustomButton'
import { AgencyContext } from '../../../context/Agency.context';
import { AgencyService } from '../../../services';

function PaymentServices({setTitleFunc}) {
    setTitleFunc("Payment Services")

    const agency = useContext(AgencyContext)
    const [processing, setProcessing] = useState(false)

    const STRIPE_CONNECT_URL  = `https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=${env.STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${env.STRIPE_REDIRECT_URI}`

    const handleRemoveStripe = async () => {
        setProcessing(true)
        const payload = {
            _id            : agency._id,
            paymentService : {...agency.paymentService, delete: true}
        }
        const {error, response} = await AgencyService.Update({toaster: true, payload})
        setProcessing(false)
        response && agency.updateAgency(response.data)
    }

    return (
        <div id="CustomPlan">
            <div className="heading3 color-blackColor ">Connect to Payment Service</div>
            <div className="paragraph color-textFieldPlaceHolderColor mt-4">To enable custom plans, you must first connect to any payment service to accept payments.</div>
            <div className="stripe-div mt-32">
                <div ><img className="stripe-img" src="/images/StripeLogo.png" alt="Stripe Logo"/></div>
                <div className="heading4 mt-16 color-blackColor">Stripe</div>
                <div className="paragraph mt-4 mb-24 color-textFieldPlaceHolderColor">Stripe is a suite of payment APIs that powers commerce for online businesses of all sizes</div>
                <div className="">
                    {/* <CustomButton
                        btntext = "Connect"
                        height  = "36px"
                        width   = 'fit-content'
                    /> */}
                    {(!agency.paymentService || agency.paymentService && agency.paymentService.delete) 
                    ? <a href={STRIPE_CONNECT_URL}>
                        <CustomButton
                            btntext = {"Connect"}
                            height  = "36px"
                            width   = 'fit-content'
                        />
                    </a> 
                    : <div className="d-flex align-items-center justify-content-between">
                        <div className="color-themeColor">Connected</div>
                        {processing
                            ? <CircularProgress size={20} color={'inherit'}/> 
                            : <div onClick={handleRemoveStripe} className="error text-center fs-12 m-0 cp">Remove</div>}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default PaymentServices