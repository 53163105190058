import * as yup from 'yup';
import { enums } from '../enums'
import { AgencyUseType } from '../enums/enums';

const REQ_ERROR = 'This field is required'

const location = yup.object({
    lat         : yup.number().required(REQ_ERROR),
    lng         : yup.number().required(REQ_ERROR),
    address     : yup.string().required(REQ_ERROR),
    city        : yup.string().required(REQ_ERROR),
    country     : yup.string().required(REQ_ERROR),
    countryCode : yup.string(),
    offset      : yup.string(),
    state       : yup.string()
})

const currency = yup.object({
    code   : yup.string().required(REQ_ERROR),
    symbol : yup.string().required(REQ_ERROR)
})

const manychat = yup.object({
    apiKey : yup.string().required(REQ_ERROR)
})

const CreateAgency = yup.object({
    agencyName     : yup.string().required(REQ_ERROR),
    agencyColor    : yup.string().required(REQ_ERROR),
    location       : location.required(),
    currency       : currency.required(),
    agencyUse      : yup.string().required().oneOf(Object.values(AgencyUseType)),
    agencyLogo     : yup.string(),
    agencyCover    : yup.string(),
    agencyCoverFile: yup.mixed()
                        .test('coverSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true) // true will neglect the validation case
                        .test('coverType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true),
    agencyLogoFile   : yup.mixed()
                        .test('imageSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                        .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('brandLogo',{
                            is   : value => !value,
                            then : yup.mixed().required(REQ_ERROR)
                        })
})

const UpdateAgency = yup.object({
    _id            : yup.string(),
    phone          : yup.string().nullable(),
    dialingCode    : yup.string(),
    agencyName     : yup.string(),
    agencyUse      : yup.string().oneOf(Object.values(AgencyUseType)),
    agencyColor    : yup.string(),
    location       : location,
    currency       : currency,
    businessService: yup.string(),
    onlineService  : yup.string(),
    logo           : yup.string(),
    favicon        : yup.string(),
    loader         : yup.string(),
    webclip        : yup.string(),
    agencyCover    : yup.string(),
    awsEmail       : yup.string(),
    agencyCoverFile: yup.mixed()
                        .test('coverSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true) // true will neglect the validation case
                        .test('coverType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('brandCover',{
                            is   : value => !value,
                            then : yup.mixed()
                        }),
    agencyLogoFile  : yup.mixed()
                        .test('imageSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                        .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('brandLogo',{
                            is   : value => !value,
                            then : yup.mixed()
                        }),
    domain          : yup.string().matches(/^(?!\-)(xn--)?(?:[a-zA-Z\u3040-\uFFEF\d\-]{0,62}[a-zA-Z\u3040-\uFFEF\d]\.){1,126}(?!\d+)[a-zA-Z\u3040-\uFFEF\d\-]{1,63}$/,'Please enter valid Domain'),
})

const Integrations = yup.object({
    manychat : manychat
})

const Billing = yup.object({
    email       : yup.string().email().required(),
    companyName : yup.string().required()
})


const Plan = yup.object({
    
})

const stripeCustomerData = yup.object({
    agencyName : yup.string().required(),
    email      : yup.string().email().required(),
    coupon     : yup.string(),
    location   : location
})

export {
    CreateAgency,
    UpdateAgency,
    Integrations,
    Billing,
    Plan,
    stripeCustomerData
}