import React, { useContext, useState, useEffect } from 'react'
import CustomButton from '../../../components/CustomButton';
import CustomTabs from '../../../components/CustomTab';
import CustomTextField from '../../../components/CustomTextField';
import { PngIcons, SvgIcons } from '../../../icons';
import { useFormik } from 'formik'
import { ClickOutside, Options } from '../../../utils';
import { AgencyContext } from '../../../context/Agency.context';
import { CircularProgress } from '@material-ui/core';
import { enums } from '../../../enums';
import { AgencyService } from '../../../services';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import { CardElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/Loader';

export default function Billing({setTitleFunc, setBtn, ...props}) {
    
    setTitleFunc('Billing')
    

    const buttonFunc = () => {
        return(
            <CustomButton
                btntext  = {"Update Changes"}
                height   = "36px"
                width    = '180px'
                type     = "submit"
            />
        )
    }

    useEffect(() => {
        setBtn(buttonFunc)
    }, [])

    const agency = useContext(AgencyContext)
    
    const labels=[
        'Billing Setting',
        'Billing History',
    ]

    const components = [
        <BillingSetting  agency={agency} {...props}/>,
        <BillingHistory />,
    ]

    return (
        <div>
            <CustomTabs
                labels     = {labels}
                components = {components}  
            />
        </div>
    )
}

const BillingSetting = ({agency, ...props}) => {

    const history = useHistory();

    const initState = {
        companyName  : agency.agencyName,
        email        : agency.email,
        location     : agency.location.address,
        agencyCity   : agency.location.city,
        agencyCountry: agency.location.country
    }


    const handleSubmit = () => {
        // console.log('Submit');
        // console.log(formik.values)
    }
    
    
    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        // validationSchema : AuthVld.LoginVld,
        isInitialValid   : false
    })

    const StripeCardElementStyle = {
        base: {
            fontSize        : '15px',
            fontFamily      : 'Poppins',
            fontWeight      : '400',
            color           : '#828282',
            '::placeholder' : {
                color : '#aab7c4',
            },
        },
        invalid: {
            color : '#9e2146',
        }
    }

    const [editCardElement, setEditCardElement] = useState(false)

    const expiryDate = new Date(agency.subscription && agency.subscription.currentPeriodEnd * 1000)

    const SubscriptionCancelStatus = async () => {
        const payload = {
            _id    : agency._id,
            cancel : !agency.subscription.canceledAtPeriodEnd
        }
        const {error, response} = await AgencyService.UpdateSubscription({toaster: true, payload})
        response && agency.updateAgency(response.data)
    }

    return(
        <div id="BillingHistory">
            <div className="d-flex scroll">
                <div className="col-8">
                    <div className="heading3 color-blackColor mb-16 mt-32" >Card Information</div>
                    <div className="stripeCardLabel ">Your Card</div>
                        <div className="w-60">
                            { agency.stripeCustomer && agency.stripeCustomer.paymentMethodId &&
                                <CardDetail 
                                    agency      = {agency}
                                    editCard    = {editCardElement}
                                    setEditCard = {setEditCardElement}
                                /> 
                            }
                            { (!agency.stripeCustomer || agency.stripeCustomer && !agency.stripeCustomer.paymentMethodId || editCardElement) && 
                                <CardElement
                                    className = 'stripeCardElement w-100'
                                    options   = {{style: StripeCardElementStyle}}
                                    onChange  = {(e) => console.log(e.target)}
                                />
                            }
                        </div>
                    <div className="bar mt-48 mb-48 w-80"/>
                    <div className="heading3 color-blackColor mb-16" >Biling Information</div>
                    <div className="mb-24">
                        <CustomTextField
                            className   = "w-60"
                            placeholder = "e.g. abc Agency"
                            label       = "Company Name"
                            name        = "companyName"
                            value       = {formik.values.companyName}
                            onChange    = {formik.handleChange}
                            inputProps  = {{ onFocus: formik.handleBlur }}
                            error       = {formik.touched.companyName && formik.errors.companyName}
                            helperText  = {formik.touched.companyName && formik.errors.companyName ? formik.errors.companyName : ''}
                        />
                    </div>
                    <div className="mb-24">
                        <CustomTextField
                            className   = "w-60"
                            placeholder = "e.g. contact@abc.com"
                            label       = "Email Address"
                            icon        = {<SvgIcons.Email/>}
                            position    = "start"
                            name        = "email"
                            value       = {formik.values.email}
                            onChange    = {formik.handleChange}
                            inputProps  = {{ onFocus: formik.handleBlur }}
                            error       = {formik.touched.email && formik.errors.email}
                            helperText  = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                        />
                    </div>
                    <div className="mb-24">
                        <CustomTextField
                            className   = "w-60"
                            placeholder = "e.g 1901 Thornridge Cir. Shiloh, Hawaii 81063"
                            label       = "Location"
                            icon        = {<SvgIcons.Location/>}
                            position    = "start"
                            name        = "location"
                            value       = {formik.values.location}
                            onChange    = {formik.handleChange}
                            inputProps  = {{ onFocus: formik.handleBlur }}
                            error       = {formik.touched.location && formik.errors.location}
                            helperText  = {formik.touched.location && formik.errors.location ? formik.errors.location : ''}
                        />
                    </div>
                    <div className="d-flex pl-0 pr-0 w-60 mb-24 space-between">
                        <div className="col-6 pr-0 pl-0">
                            <CustomTextField
                                className   = "w-100"
                                placeholder = "City"
                                label       = "Agency City"
                                name        = "agencyCity"
                                value       = {formik.values.agencyCity}
                                onChange    = {formik.handleChange}
                                inputProps  = {{ onFocus: formik.handleBlur }}
                                error       = {formik.touched.agencyCity && formik.errors.agencyCity}
                                helperText  = {formik.touched.agencyCity && formik.errors.agencyCity ? formik.errors.agencyCity : ''}
                            />
                        </div>
                        <div className="col-6 pr-0 ">
                            <CustomTextField
                                className = "w-100"
                                label     = "Agency Country"
                                name        = "agencyCountry"
                                options     = {Options.getContryCodes()}
                                value       = {formik.values.agencyCountry}
                                onChange    = {formik.handleChange}
                                inputProps  = {{ onFocus: formik.handleBlur }}
                                error       = {formik.touched.agencyCountry && formik.errors.agencyCountry}
                                helperText  = {formik.touched.agencyCountry && formik.errors.agencyCountry ? formik.errors.agencyCountry : ''}
                            />
                        </div>
                    </div>
                    
                </div>
                <div className="col-4">
                    <div className="p-24 card mt-32 mb-24">
                        <div className="fw-5 fs-14 color-textFieldPlaceHolderColor">Next Billing Date</div>
                        <div className="fw-4 fs-24 color-blackColor mt-24 ">{expiryDate.toDateString()}</div>
                        <div className="fw-4 fs-24 color-blackColor mt-24 mb-48 uppercase">{agency.subscription.status}</div>
                        {
                            agency.subscription.status === 'active' &&
                            agency.subscription.canceledAtPeriodEnd ? 
                            <div>
                                <div className=" fw-4 fs-14 color-selectGreenBackgroundColor cp" onClick={()=> SubscriptionCancelStatus()}>Return to {agency.subscription.planName}</div>    
                                <div className=" fw-4 fs-14 color-themeColor cursor-wait">Pending Cancelation</div>    
                            </div>
                            :
                            <div className=" fw-4 fs-14 color-redColorButton cp" onClick={SubscriptionCancelStatus}>Cancel {agency.subscription.planName} Subscription</div>
                        }
                        {
                            agency.subscription.status === 'canceled' &&
                            <div className=" fw-4 fs-14 color-selectGreenBackgroundColor cp">Renew Subscription</div>
                        }
                    </div>
                    
                    <div>
                        <CustomButton hover="#F6F9FE !important" height="48px" className={'bg-color-whiteColor color-brandingPrimaryColor button w-100'} btntext={"Upgrade Plan"} icon={<SvgIcons.Star/>} onClick={()=> history.push(props.match.path + '/plans')}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const BillingHistory = () => {

    const [state, setState] = useState()
    const [loader, setLoader] = useState(false)
    const agency = useContext(AgencyContext)

    useEffect(async() => {
        setLoader(true)
        const query = { agencyId : agency._id }
        const invoice = await AgencyService.GetInvoice({query}) 
        setState(invoice)
        setLoader(false)
    }, [])

    return(
        <div id="BillingHistory">
            {
                loader ?
                <Loader width={180} height={180} />
                :
                state && 
                state.error ?
                <div className="empty-screen">
                    <div className="color-blackColor fw-6 fs-18" >No History</div>
                    <div className="fw-4 fs-14 color-textFieldPlaceHolderColor">Your billing history will appear here.</div>
                </div>
                :

            <div className="mt-24">
                <div className="row mb-12">
                    <div className="col fw-5 fs-12 color-textFieldLabelColor">Date</div>
                    <div className="col fw-5 fs-12 color-textFieldLabelColor">Amount</div>
                    <div className="col fw-5 fs-12 color-textFieldLabelColor">Status</div>
                    <div className="col fw-5 fs-12 color-textFieldLabelColor">PDF</div>
                </div>
                <div className="bar "/>
                <div className="scroll">
                    {
                        state && state.response && state.response.data && state.response.data.map((value,index)=>(
                            <>
                            {index !== 0 && <div className="bar " />}
                            <div className="row mt-12 mb-12">
                                <div className="col fw-4 fs-12 color-textFieldTextActiveColor">{new Date(value.invoiceDate*1000).getDate()+"/"+(new Date(value.invoiceDate*1000).getMonth()+1)+"/"+new Date(value.invoiceDate*1000).getFullYear()}</div>
                                <div className="col fw-4 fs-12 color-textFieldTextActiveColor">{value.amountPaid.toString().slice(0, -2)+" "+value.currency.toUpperCase()}</div>
                                <div className={value.status === 'paid' ? "col fw-5 fs-12 color-green2":"col fw-5 fs-12 color-redColorButton"}>{value.status.toUpperCase()}</div>
                                {/* <div className="col fw-4 fs-12 underline color-themeColor cp"></div> */}
                                <a className="col fw-4 fs-12" href={value.pdf} >Invoice</a>
                            </div>
                            </>
                        ))
                    }
                </div>
            </div>
        
            }
        </div>
    )
}


const CardDetail = ({agency: {stripeCustomer, ...agency}, editCard, setEditCard}) => {
    const [showCardSettings, setShowCardSettings] = useState(false)
    const [state, setState] = useState({
        loader: false
    })

    const deleteCard = async () => {
        if(state.loader) return
        setState({...state, loader: true})
        const payload = {
            _id        : agency._id,
            deleteCard : true
        }
        const { error, response } = await AgencyService.Update({toaster: true, payload})
        setState({...state, loader: false})
        // if(response) return agency.updateAgnecy(response.data)
    }

    return (
        <div className="d-flex align-items-center position-relative">

            <div className="mr-16">
                <img src={PngIcons.PaymentCard[stripeCustomer.card.brand] || PngIcons.PaymentCard.default} width="60px" alt="card"/>
            </div>
            
            <div>
                <div className="d-flex">
                    <div className="color-textfieldColor fs-16 fw-4 text-capitalize">{stripeCustomer.card.brand} card</div>
                    <div>
                        <span className="creditCardNumber ml-8"></span>
                        <span className="creditCardNumber"></span>
                        <span className="creditCardNumber"></span>
                        <span className="creditCardNumber mr-4"></span>
                        {stripeCustomer.card.last4}
                    </div>
                </div>
                <div>Expires {enums.Months[stripeCustomer.card.exp_month]} {stripeCustomer.card.exp_year}</div>
            </div>
            <div className="ml-auto d-flex">
                <div className="mr-16">
                    <ClickOutside onClick={() => setShowCardSettings(false)}>
                        <div className="cp" onClick={() => {setShowCardSettings(!showCardSettings); setEditCard(false)}}>
                            <SvgIcons.ViewMoreIcon color={ColorSchemeCode.tableActionDropdownColor} />
                        </div>
                        { showCardSettings &&
                            <div className="cardOption dropdown-box">
                                <div className="cp" onClick={deleteCard}>
                                    {   state.loader ?
                                        <CircularProgress className={"mr-8 align-middle"} size={20} color={'inherit'}/> :
                                        <span className="mr-8 align-text-bottom"><SvgIcons.DeleteIcon color={ColorSchemeCode.textfieldColor}/></span>
                                    }
                                    Delete
                                </div>
                            </div>
                        }
                    </ClickOutside>
                </div>

                <div className="cp" onClick={()=>{setEditCard(!editCard); setShowCardSettings(false)}}><SvgIcons.EditIcon color={ColorSchemeCode.tableActionDropdownColor}/></div>
            </div>
        
        </div>
    )
}