import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import CustomTextField from '../../../components/CustomTextField';
import { useFormik } from 'formik'
import { UserService } from '../../../services';
import Loader from '../../../components/Loader';

function Demo({setTitleFunc, setBtn}) {

    setTitleFunc("Languages")

    const handleSave = () => {
        console.log('save');
        console.log(formik.values);
        console.log(state);
    }

    const buttonFunc = () => {
        return(
            <CustomButton
                btntext = {"+ Add Languages"}
                height  = "36px"
                width   = 'fit-content'
                onClick = {handleSave}
            />
        )
    }

    useEffect(() => {
        setBtn(buttonFunc)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const initState = {
        content    : '',
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSave,
        // validationSchema : AuthVld.LoginVld,
        isInitialValid   : false
    })

    const lang = [
        {
            english: 'Hi Haider, Welcome to ',
            french: '',
        },
        {
            english: 'How can I help you today?',
            french: ''
        },
        {
            english: 'Request a demo',
            french: ''
        },
        {
            english: 'Please provide your email.',
            french: ''
        },
        {
            english: 'Thank you, our manager will contact you.',
            french: ''
        },
    ]
    const [state, setstate] = useState(lang)
    const [loader, setLoader] = useState(true)

    const onChange = (e,index) =>{
        state[index].french = e.target.value
        setstate([...state])
    }

    useEffect(async() => { // eslint-disable-line react-hooks/exhaustive-deps
        
        const data = await UserService.API({
            text: 
            [
                'Hi Haider, Welcome to ',
                'How can I help you today?',
                'Request a demo',
                'Please provide your email.',
                'Thank you, our manager will contact you.'
            ],
            from: 'en',
            to  : 'es'
        })

        // console.log(typeof data.response.data);
        // console.log(data.response.data);
        // console.log(JSON.parse(data.response.data));

        data.response.data.forEach((element,index) => {
            state[index].french = element
        });

        setstate([...state])
        setLoader(false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // const example = {
    //     name: 'Rafi',
    //     age: '24'
    // }

    // const egObj = JSON.stringify(example)
    // console.log('egObj',egObj);
    // const egStr = JSON.parse(egObj)
    // console.log('egStr',egStr);

    return (
        <div>
            {loader && <Loader width={180} height={180} />}
            {
                state.map((value,index)=>(
                    <div className="d-flex mb-5">
                        <div style={{border: '1px solid grey',width: '130px',borderRadius: '10px',paddingLeft: '15px', justifyContent: 'center',display: 'flex', flexDirection: 'column'}}>Welcome</div>
                        <div className="ml-30 mr-30" style={{justifyContent: 'center',display: 'flex', flexDirection: 'column'}}>Message</div>
                        <div className="w-40" style={{justifyContent: 'center',display: 'flex', flexDirection: 'column'}}>{value.english}</div>
                        <CustomTextField
                            className   = "w-30"
                            placeholder = "Enter content..."
                            name        = "content"
                            value       = {state[index].french}
                            onChange    = {(e)=>onChange(e,index)}
                        />
                    </div>
                
                ))
            }
        </div>
    )
}

export default Demo
