import React, {useEffect, useState} from 'react';

import Fade from 'react-reveal/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomButton from '../CustomButton';
import CustomTextField from '../CustomTextField'; 

import { ColorScheme } from '../../enums';
import { AgencyService } from '../../services';
import { FormControl, FormHelperText, RadioGroup } from '@material-ui/core';
import CustomRadio from '../CustomRadio';
import { AgencyUseType } from '../../enums/enums';
import CustomSelect from '../CustomSelect';

export default function AgencyNameScreen({formik, setActivePage, index}) {

    const [show, setShow]                     = useState(false)
    const [agencyNameError, setAgencyNameError] = useState('')
    const [loader, setLoader]                 = useState(false)

    useEffect(()=> setShow(true), [])

    const nextScreen = async () =>{ 
        setAgencyNameError('')
        setLoader(true)
        const query = { agencyName: formik.values.agencyName }
        const { error, response } = await AgencyService.GetAgencyPublic({query})
        setLoader(false)
        if(response) return setAgencyNameError('Name already exist')

        setShow(false)
        setTimeout(()=>{setActivePage(index,'next')},500)
    }

    const handleKeyPress = (e) => {
        setAgencyNameError('')
        e.keyCode === 13 && nextScreen()
    }

    return(
        <div id="brandNameScreen" className="mobileCol">

          <Fade delay={230} bottom opposite when={show}>
            
            <div className="row mt-108">       
              
                    <div className="col-md-5 col-12 offset-md-1">

                        <div className="headingH2 color-questionColor"> Let's start with your Business </div>
                        <div className="question mt-40">What is your business name?</div>
                            <CustomTextField 
                                className   = "customInputField mt-24"
                                placeholder = "Agency Name"
                                variant     = "standard"
                                padding     = "20px"
                                fontSize    = "25px"
                                InputProps  = {{ disableUnderline: false, style: { fontSize: 16, color: 'color-themeColor'} }}
                                name        = "agencyName"
                                value       = {formik.values.agencyName}
                                onChange    = {formik.handleChange}
                                onKeyUp     = {handleKeyPress}
                                inputProps  = {{ onFocus: formik.handleBlur }}
                                error       = {formik.touched.agencyName && formik.errors.agencyName}
                                helperText  = {formik.touched.agencyName && formik.errors.agencyName ? formik.errors.agencyName : ''}
                                autoFocus   = {true}
                            />

                                        {/* <div className="headingH4 color-questionColor mt-20 mb-4 pl-0">
                                            Partner Program
                                        </div>
                                        <div className="question caption">
                                            Please select if you are using Partner Program
                                        </div>
                                        <CustomSelect
                                            className  = "w-100"
                                            options    = {<>
                                                            <option value={AgencyUseType.OWN}>None</option>
                                                            <option value={AgencyUseType.PARTNER}>CloudWaitress</option>
                                                        </>}
                                            name       = "agencyUse"
                                            value      = {formik.values.agencyUse}
                                            onChange   = {formik.handleChange}
                                            onFocus    = {formik.handleBlur}
                                            error      = {formik.touched.agencyUse && formik.errors.agencyUse}
                                            helperText = {formik.errors.agencyUse && formik.errors.agencyUse}
                                        /> */}
                    
                            <CustomButton 
                                borderRadius    = {4}
                                height          = {36}
                                onClick         = {nextScreen}
                                className       = 'headingH4 mt-40 btn-next'
                                color           = {ColorScheme.ColorSchemeCode.cffffff}
                                backgroundColor = {ColorScheme.ColorSchemeCode.themeColor}
                                btntext         = "Next"
                                disabled        = {!formik.values.agencyName || formik.errors.agencyName || loader}
                                icon            = {loader && <CircularProgress size={20} color={'inherit'}/>}
                            />
                            <div className="error">{agencyNameError}</div>                            
                    </div>
            </div>
          </Fade>
        </div>
    )

}
