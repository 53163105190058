import React, { useContext } from 'react';
import { Link, useHistory } from "react-router-dom";


import CircularProgress from '@material-ui/core/CircularProgress';
import { useFormik } from 'formik'

import * as localForage from 'localforage'

import { AuthVld } from '../../validation'
import { AuthService, UserService } from '../../services'
import { PermissionService } from '../../services'

import CustomTextField from '../../components/CustomTextField';
import CustomButton from '../../components/CustomButton';
import { BusinessType } from '../../enums/enums';
import { UserContext } from '../../context/User.context';
import { Brand, User } from '../../enums';



function ForgetPassword() {

  // const history     = useHistory()
  // const userContext = useContext(UserContext)

  const [ state, setState ] = React.useState({
    apiError : '',
    loader    : false
  })

  const initState = {
    email    : '',
  }

  const formik = useFormik({
    // validateOnChange : false,
    // validateOnBlur   : false,
    initialValues    : { ...initState },
    onSubmit         : handleSubmit,
    validationSchema : AuthVld.ForgetPassword,
    isInitialValid   : false
  })

  async function handleSubmit(){
    setState({...state, loader: true})
    const payload = { email: formik.values.email }
    await AuthService.ForgetPassword({toaster: true, payload})
    setState({...state, loader: false})
  }
  

  return (
    <>
      <div className="headingH2">{'Forget Password'}</div>  

      <form onSubmit={formik.handleSubmit}>
        <div className='input col-12'> 
        
          <div className="mt-40 text-left col-12">
            <CustomTextField 
                label       = "Email address"
                className   = "inputTextField col-12"
                type        = "text"
                placeholder = "Email"
                name        = "email"
                value       = {formik.values.email}
                onChange    = {formik.handleChange}
                inputProps  = {{ onFocus: formik.handleBlur }}
                error       = {formik.touched.email && formik.errors.email}
                helperText  = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                autoFocus   = {true}
            />
          </div>
        
         

          <div className="col-12">
            <CustomButton 
              className = "fs-14 mt-32 col-12"
              btntext   = "Submit"
              type      = "submit"
              disabled  = {state.loader || !formik.isValid}
              icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
            />
          </div>
          {state.apiError && <div className={'error'}>{state.apiError}</div>}

        </div>  
      </form>
    </>
  );
}


export default ForgetPassword;
