import localforage from "localforage";
import React, { Component } from "react";
import Loader from "../components/Loader";
import Notfound from "../components/Notfound";
import { enums } from "../enums";
import PngIcons from "../icons/png.icon";

import { AgencyService } from "../services";

const AgencyContext = React.createContext();

class AgencyContextComponent extends Component {
  state = {loader: true}

  componentDidMount = () => {
      // if(!this.props.location.state) 
      return this.getAgency()
      // this.setState({loader: false, ...this.props.location.state})

  }

  getAgency = async () => {
      const user = await localforage.getItem('user')
      if(!user) return this.setState({...this.state, loader: false})

      const query = { _id: user.activeAgency }
      const { error, response } = await AgencyService.Get({query})
      
      if(error && !error.error.includes('jwt expired')) 
      return this.setState({loader: false, error: error.error})

      if(response)
      return  this.setState({loader: false, ...response.data[0]})
  }

  updateAgency = (updates) => this.setState({...this.state, ...updates})

  handleUserLogin = () => {
    this.setState({...this.state, loader: true})
    this.getAgency()
  }

  render() {
    return (
      <>
      {this.state.loader === true && <Loader width={300} height={300} />}
      {/* {this.state.error && <div className="middle"><Notfound /></div>} */}
      {/* && !this.state.error */}
      {
        this.state.loader != true &&
        <AgencyContext.Provider value={{...this.state, updateAgency: this.updateAgency, handleUserLogin: this.handleUserLogin}}>
         {this.props.children}
        </AgencyContext.Provider>
      }
      </>
    )
  }
}

export { AgencyContext, AgencyContextComponent }