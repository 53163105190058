import React, { useContext, useEffect } from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

import ReactTooltip from 'react-tooltip';
import { BrandContext } from '../../context/Brand.context';
import ClickOutside from '../../utils/ClickOutside';
import BrandsDropDown from '../../components/BrandsDropDown';

import SvgIcons from '../../icons/svg.icon';
import { utils } from '../../utils';
import { UserContext } from '../../context/User.context';
import { PngIcons } from '../../icons';
import localforage, * as localForage from 'localforage'

export default function PartialSideBar({setSubMenu, setItemsSub, setMainItem, show, setShow, items}) {

    const history = useHistory()
    const params  = useParams()
    const userContext    = useContext(UserContext)

    const [showLogoutMenu, setShowLogoutMenu] = React.useState(false);
    const [user, setUser] = React.useState(userContext);

    const handleClick = (element) => {
     
        if(element.subMenu === true)
        {
            setItemsSub(element.children);
        }
    }

    useEffect(async() => {
        const userLocal = await localforage.getItem('user')
        setUser(userLocal)
    }, [])

    return (
        <div className="pSide">
         <ClickOutside onClick={() => { setShowLogoutMenu(false)}}>
            <div className="logo">
                    <img src={PngIcons.WalletlyBusinessIcon} width='auto' height="40px" className="mt-8 borderRadius-40" />
            </div>
                <nav className="navPartial d-flex flex-column ">
                <ReactTooltip place="right"/>
                {
                    items.map((element, idx)=>(
                        element.link &&
                        <Link className={element.className} to={'/'+params._id+element.link} onClick={() => handleClick(element)}>
                            <div className={(history.location.pathname.includes(element.link)) ? 'active icons middle': 'icons middle'} data-tip={element.name}>
                                {element.logo}
                            </div>
                        </Link>
                    ))
                }
                </nav>
                
                <div className="userProfileIcon text-center cp col-12 text-uppercase" onClick={()=>setShowLogoutMenu(!showLogoutMenu)}>
                    {   
                    // user.avatar ?
                    //     <img src={"https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="} width={36} height={36} className="borderRadius-40" alt="avatar"></img> :
                        utils.getAvatar({firstName: user.firstName, lastName: user.lastName, className: 'm-auto', width: '40px', heigth: '40px'})
                    }
                </div>
                {
                    showLogoutMenu &&
                        <div className="card borderRadius-8 logoutMenu" >
                                <div className="body2 singleItemLogoutMenu borders">
                                    <Link to={'/'+params._id+'/profile'} onClick={()=>{setShowLogoutMenu(!showLogoutMenu)}} className="body2 text-dec-none hover-none cp">Profile Settings</Link>
                                </div>
                                <div className="body2 singleItemLogoutMenu color-tableActionDropdownColor cp" onClick={()=> utils.Logout()}>
                                    Logout
                                </div>
                        </div>
                }
                    
                </ClickOutside>
                
        </div>
    )
}
