import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service = '/v4/design'

const UpdateDesign = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const GetQueriedPassDesign = async ({query}) => await axios.get(env.API_URL+service+'/query?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const CreatePreBuiltPassDesign = async({payload}) => await axios.post(env.API_URL+service+'/prebuilt', payload, {headers: await MainService.getTokenHeader()})

const CampaignService = {
    UpdateDesign             : Handlers.Services(UpdateDesign),
    GetQueriedPassDesign     : Handlers.Services(GetQueriedPassDesign),
    CreatePreBuiltPassDesign : Handlers.Services(CreatePreBuiltPassDesign),
}

export default CampaignService
