import React from 'react'
import { PngIcons , SvgIcons} from '../icons'

function Loader({width, baseWidth, height, baseHeight}) {
    return (
      <div className="position-relative loaderOuterPage" style={{height: baseHeight , width: baseWidth || '100%'}}>
      {/* <img src={agency && agency.loader} width={width} height={height} /> */}
        <div className="absoluteMiddle">
            <SvgIcons.WalletlyLoaderIcon  width={width} height={height} />
        </div>
    </div> 
    )
}

export default Loader
