import React from 'react';
import {Link} from "react-router-dom";

import Login from './Login';
import Signup from './SignUp';

import SignupHeader from '../../components/SignupHeader';
import ForgetPassword from './ForgetPassword';
import ResetPassword from './ResetPassword';


function Auth(props) {
  return (
    <>
    <SignupHeader/>
    <div id='Auth'>
      <div className="text-center"> 
        <div className="container mb-100">
          <div className='row' id="cardEnclosed">

            <div className="col-12 card">
              
            {
              props.location.pathname.includes('forget')
              ?
              <ForgetPassword/>
              :
              props.location.pathname.includes('reset')
              ?
              <ResetPassword/>
              :
              props.location.pathname.includes('signup') ? <Signup /> : <Login /> 
            }

              <div className='signUpFooter mt-48 col-12'>
                <span className=" caption fs-10">
                  ® {new Date().getFullYear()} Walletly Business, All rights reserved. <Link className="termsandprivacy">Terms of Service </Link>and <Link className="termsandprivacy">Privacy Policy</Link> 
                </span>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    </>
  );
}


export default Auth;
