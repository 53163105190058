// CSS (do not change the orders)
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/global.scss'

import React from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { 
  AuthGuard, 
  CentralGuard, 
  UserOnBoardGuard, 
  VerifyGuard, 
  CreateAgencyGuard, 
  SubscriptionPlanGuard 
} from './utils/RouteGuards';

// Pages
import Main from './pages/Main';
import Auth from './pages/auth/Auth';
import EmailVerify from './pages/auth/EmailVerify';

import UserOnboarding from './pages/auth/UserOnboarding';
import { withToaster } from './context/Toaster.context';
import CreateAgency from './pages/auth/CreatAgency';
import Plans from './pages/auth/Plans';
import StripeCallback from './pages/public/StripeCallback';

import { UserContextComponent } from './context/User.context';
import { AgencyContextComponent } from './context/Agency.context'

function App(props) {

  return (
    <UserContextComponent {...props}>
        <AgencyContextComponent {...props}>
          <BrowserRouter>
            <Switch>
              <AuthGuard exact path="/" component={Auth} />

              <AuthGuard path = "/forget" component = {Auth} />
              <AuthGuard path = "/reset"  component = {Auth} />
              <AuthGuard path = "/signup" component = {Auth} />
              
              <VerifyGuard path="/verify" component={EmailVerify} />
              
              <UserOnBoardGuard path="/onboard"  component={UserOnboarding} />
              
              <CreateAgencyGuard path="/agency"  component={CreateAgency}/>
              {/* <SubscriptionPlanGuard path="/selectplan"  component={Plans}/> */}

              {/* Public */}
              {/* <Route path="/stripe/callback" component={StripeCallback} /> */}

              <CentralGuard path="/:_id" component={Main} />
            </Switch>
          </BrowserRouter>
        </AgencyContextComponent>
      </UserContextComponent>
  );
}

export default withToaster(App);
