import React from 'react';
import {Link} from 'react-router-dom';

import SvgIcons from  '../icons/svg.icon';

import CustomButton from '../components/CustomButton';
import { ColorScheme } from '../enums'
import PngIcons from '../icons/png.icon';
import { utils } from '../utils';
import { ColorSchemeCode } from '../enums/ColorScheme';


export default function SignupHeader() {

    var pathname = window.location.pathname;

    return (
        
            <div className="row" id="SignupHeader">  
            
                <div className="logoBox col-2 col-md-6 text-left">   
                    
                <img src={PngIcons.WalletlyBusinessIcon} alt='' width="60px"/>
                
                </div>

                {pathname === '/signup' ? 
                    <div className="col-md-6 col-12 text-right">
                    <div className="textButton row text-right">

                        <div className="col-md-5 offset-md-4 col-8 contents">
                            <div className='row'>
                                <div className="caption2 col-md-12 col-12" id="firstText">
                                        Already have walletly account?        
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12 col-12">  
                                    <Link to="/forget" className="caption2" id="secondText">I forgot my User ID or Password</Link> 
                                </div>
                            </div>
                        </div>

                        <div className="button col-md-3 col-4 text-dec-none">
                        
                        <Link to="/" className="text-dec-none">
                            <CustomButton 
                                className="fs-14 fw-5 pt-12 pb-12 pl-36 pr-36" 
                                height='52px' 
                                btntext="Login"
                            />
                        </Link>
                        </div>
                       

                    </div> 
                    </div>

                    :   
                    
                    pathname === '/' ?
                    <div className="col-md-6 col-12 text-right">
                        <div className="textButton row text-right">

                            <div className="col-md-5 offset-md-4 col-8 contents">
                                <div className='row'>
                                    <div className="caption2 col-md-12 col-12" id="firstText">
                                            Don't have a walletly account?        
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12 col-12">  
                                        <Link to="/forget" className="caption2" >I forgot my User ID or Password</Link> 
                                    </div>
                                </div>
                            </div>

                            <div className="button col-md-3 col-4">
                            
                            <Link to="/signup" className="text-dec-none">
                            <CustomButton 
                                className="fs-14 fw-5"
                                height='52px' 
                                btntext="Get Started"
                            />
                            </Link>
                            </div>

                        </div> 
                        </div>
                        :

                        <span className="buttonBox col-6 pt-10">
                            <CustomButton 
                                className       = "fs-14 fw-5"
                                height          = '36px'
                                btntext         = "Logout"
                                onClick         = {utils.Logout}
                                backgroundColor = {ColorSchemeCode.cE0E0E0}
                                hover           = {ColorSchemeCode.cE0E0E0}
                                color           = {ColorSchemeCode.c000000}
                            />
                        </span>


                    
                }
        </div>
        

        
    )
}

