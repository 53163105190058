import { enums } from '../enums'
import { utils } from '../utils'

const Services = (fn) => {
    return async ({toaster, ...props} = {}) => {
        try {
            const res = await fn(props)
            console.log('API - RESPONSE', res)
            toaster && utils.showToaster({title: 'Success', message: res.data.message, severity: 'success'})
            return { response : res.data }
        } catch (err) {
            console.log('API- ERROR', err)
            // expire error : jwt expired
            if(err.response && err.response.status === enums.ResponseStatus.UNAUTHORIZED) {
                utils.showToaster({position: 'top-right', title: 'Error', message: 'session expired', severity: 'error'})
                setTimeout(utils.Logout, enums.TOASTER_DELAY)
                return { error: err.response ? err.response.data : err}
            }

            toaster && utils.showToaster({title: 'Error', message: err.response ? err.response.data.error : err.message, severity: 'error'})
            return { error: err.response ? err.response.data : err}
        }
    }
}

export {
    Services
}