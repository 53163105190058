import React, { useState } from 'react';
import {Redirect, Switch} from "react-router-dom";

import SidebarNav from '../components/sidebar/SidebarNav';
import Setting from '../pages/setting/Setting';
import Dashboard from '../pages/dashboard/Dashboard';
// import Programs from '../pages/programs/Programs';
import { AgencyGuard } from '../utils/RouteGuards';
import { UserContextComponent } from '../context/User.context';
import { AgencyContextComponent } from '../context/Agency.context';
import Profile from './userprofile/Profile';
import Audience from './audience/Audience';


function Main(props) {

  const [show, setShow]               = useState(true);
  const [mobileCheck, setMobileCheck] = useState(false);

  return (
    <UserContextComponent {...props} >
      <AgencyContextComponent {...props} >
        <div id="Main" className="d-flex flex-row">
          <SidebarNav show={show} setShow={setShow} mobileCheck={mobileCheck} setMobileCheck={setMobileCheck}/>
          <div className={(mobileCheck) ? 'overlay' : 'content'}>
            <Switch>
                <AgencyGuard path={props.match.url+ '/dashboard'}  show={show} setShow={setShow} component={Dashboard}       />
                <AgencyGuard path={props.match.url+ '/setting'}    show={show} setShow={setShow} component={Setting}         />
                <AgencyGuard path={props.match.url+ '/audience'}   show={show} setShow={setShow} component={Audience}         />
                <AgencyGuard path = {props.match.url+ '/profile'}       show = {show} setShow = {setShow} component = {Profile}      />
                {/* <BrandGuard path={props.match.url+ '/profile'}    show={show} setShow={setShow} component={Profile}         /> */}
                <Redirect   path={props.match.url+ '/*'} to={props.match.url+ '/dashboard'} />
                <Redirect   path={props.match.url} to={props.match.url+ '/dashboard'} />
            </Switch>
          </div>
        </div>
      </AgencyContextComponent>
    </UserContextComponent>
    )
}

export default Main;
